const DeleteEntityModal = ({ closeModal, onModalSubmit, entityType, currentEntity, currentEntityString, modalSubmitLoading, cancelFunction }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        onModalSubmit(currentEntity.id);
        
        closeModal();
    }
    
    return (
        <div className="base-modal-container" onClick={(e) => {
            if (e.target.className === "base-modal-container") closeModal();
        }}>
            <div className="base-modal">
                <p>Are you sure you want to delete {entityType}: {currentEntityString}</p>
                {!modalSubmitLoading && <button className="btn inline-btn" onClick={ handleSubmit }>Yes</button>}
                {!modalSubmitLoading && <button className="btn inline-btn-end" onClick={() => cancelFunction()}>No</button>}
                {modalSubmitLoading && <button disabled className="btn inline-btn-end" onClick={() => {}}>Deleting...</button>}
            </div>
        </div>
    );
}
 
export default DeleteEntityModal;