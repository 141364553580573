import { useState } from "react";
import "../../BaseModal.css"
import AddEditLaborEngineeringMachiningModal from "../../AddEditLaborEngineeringMachiningModal/AddEditLaborEngineeringMachiningModal";

import NonPaginatedTable from "../../../NonPaginatedTable/NonPaginatedTable";
import { GetHeadersForSmallTables } from "../../../../static-values/TableHeaders";
import DeleteEntityModal from "../../DeleteEntityModal/DeleteEntityModal";
import { calculateSum } from "../../../../utils/utils";

const NewAssemblyManHoursModal = ({ closeModal, onModalSubmit, defaultValue, backFunction = () => {}, manHourType, subTypeOptions, valuesToIdMapping, cancelFunction = () => {} }) => {
    const [formState, setFormState] = useState(defaultValue);

    const [manHourToDelete, setManHourToDelete] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteModalLoading, setDeleteModalLoading] = useState(false);

    const [manHourToEdit, setManHourToEdit] = useState(null);
    const [addEditManHourModalOpen, setAddEditManHourModalOpen] = useState(false);
    
    const handleManHourDeleteRow = (manHourId) => {
        const manHour = formState.filter((currManHour, idx) => currManHour.id === manHourId)[0];
        setManHourToDelete(manHour);
        setDeleteModalOpen(true);
    }

    const handleManHourEditRow = (manHourId) => {
        const position = formState.map(entry => entry.id).indexOf(manHourId);
        setManHourToEdit(position);
        setAddEditManHourModalOpen(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onModalSubmit(formState);
        closeModal();
    }

    const handleManHourAddEdit = (manHourEntry) => {
        if (manHourToEdit === null) {
            const hours = parseFloat(manHourEntry.hours);
            const rate = parseFloat(manHourEntry.rate);
            const id = parseInt(valuesToIdMapping[manHourEntry.typeString]);
            const newManHourEntry = {
                "id": id,
                "type": id,
                "typeString": manHourEntry.typeString,
                "hours": hours,
                "rate": rate,
                "total": hours * rate
            }

            const possibleExistingManHour = formState.filter((currManHour, idx) => currManHour.id === newManHourEntry.id)

            if (possibleExistingManHour.length > 0) {
                alert(`Cannot add an entry for ${manHourEntry.typeString} as it already exists`);
                return;
            }
            else {
                formState.push(newManHourEntry);
                setFormState(formState);
            }
        }
        else {
            const hours = parseFloat(manHourEntry.hours);
            const rate = parseFloat(manHourEntry.rate);

            const updatedManHourEntry = {
                "id": parseInt(valuesToIdMapping[manHourEntry.typeString]),
                "type": parseInt(valuesToIdMapping[manHourEntry.typeString]),
                "typeString": manHourEntry.typeString,
                "hours": hours,
                "rate": rate,
                "total": hours * rate
            }
            formState[manHourToEdit] = updatedManHourEntry;
            setFormState(formState);
        }
    }

    const handleManHourDelete = (manHourId) => {
        setDeleteModalLoading(true);
        const newFormState = formState.filter((currManHour, idx) => currManHour.id !== manHourId);
        setFormState(newFormState);
        setManHourToDelete(null);
        setDeleteModalLoading(false);
    }

    return (
        <div className="base-modal-container">
            <div className="base-modal">
                <h1>Add {manHourType}</h1>
                <div>
                    <NonPaginatedTable
                        data={formState}
                        columns={GetHeadersForSmallTables(manHourType, calculateSum(formState, 'hours'), calculateSum(formState, 'total'))}
                        deleteRowFunction={handleManHourDeleteRow}
                        editRowFunction={handleManHourEditRow}
                        initialState={{
                            sortBy: [{ id: "type", desc: false }],
                            hiddenColumns: ["type"]
                        }}
                        actions={['ModalEdit', 'ModalDelete']}
                    />
                    <button className="btn" onClick={() => setAddEditManHourModalOpen(true)}>Add New {manHourType} Entry</button>
                </div>
                <button className="btn inline-btn" onClick={() => { cancelFunction() }}>Cancel</button>
                <button className="btn inline-btn" onClick={() => { backFunction(formState) }}>Back</button>
                <button className="btn" onClick={handleSubmit}>Next</button>
                {addEditManHourModalOpen &&
                    <AddEditLaborEngineeringMachiningModal
                        closeModal={() => {
                            setAddEditManHourModalOpen(false);
                            setManHourToEdit(null);
                        }}
                        onModalSubmit={handleManHourAddEdit}
                        defaultValue={manHourToEdit !== null && formState[manHourToEdit]}
                        type={manHourType}
                        subTypeOptions={subTypeOptions}
                        newEntry={manHourToEdit === null ? true : false}
                    />
                }
                {deleteModalOpen &&
                    <DeleteEntityModal
                        closeModal={() => {
                            setDeleteModalOpen(false);
                            setManHourToDelete(null);
                        }}
                        onModalSubmit={handleManHourDelete}
                        entityType={manHourType}
                        currentEntity={manHourToDelete !== null && manHourToDelete}
                        currentEntityString={manHourToDelete.typeString}
                        modalSubmitLoading={deleteModalLoading}
                    />
                }
            </div>

        </div>
    );
}

export default NewAssemblyManHoursModal;
