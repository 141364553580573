import { calculateSum } from "../../../../utils/utils";
import AddEditAssembly from "../../AddEditAssembly/AddEditAssembly";
import { useState } from "react";
import useGet from "../../../../utils/hooks/useGet";
import Table from "../../../Table/Table";
import { GetSubAssemblyTableHeadersForSubAssembly } from "../../../../static-values/TableHeaders";
import DeleteEntityModal from "../../DeleteEntityModal/DeleteEntityModal";

const NewAssemblySubAssembliesModal = ({ closeModal, onModalSubmit, defaultValue, backFunction = () => {}, subAssemblies, isPendingSubAssemblies, errorSubAssemblies, cancelFunction = () => {} }) => {
    const [formState, setFormState] = useState(defaultValue);

    const [subAssemblyToDelete, setSubAssemblyToDelete] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteModalLoading, setDeleteModalLoading] = useState(false);

    const [subAssemblyToEdit, setSubAssemblyToEdit] = useState(null);
    const [addEditSubAssemblyModalOpen, setAddEditSubAssemblyModalOpen] = useState(false);
    const [subAssemblyEditModalLoading, setSubAssemblyEditModalLoading] = useState(false);

    const handleSubAssemblyDeleteRow = (subAssemblyId) => {
        const subAssembly = formState.filter((currSubAssembly, idx) => currSubAssembly.id === subAssemblyId)[0];
        setSubAssemblyToDelete(subAssembly);
        setDeleteModalOpen(true);
    }

    const handleSubAssemblyEditRow = (subAssemblyId) => {
        const position = formState.map(entry => entry.id).indexOf(subAssemblyId);
        setSubAssemblyToEdit(position);
        setAddEditSubAssemblyModalOpen(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onModalSubmit(formState);
        closeModal();
    }

    const handleSubAssemblyAddEdit = (subAssemblyEntry) => {
        setSubAssemblyEditModalLoading(true);
        const subAssemblyId = parseInt(subAssemblyEntry.id)

        if (subAssemblyToEdit === null) {
            const possibleExistingSubAssembly = formState.map(entry => entry.id).indexOf(subAssemblyId);
            if (possibleExistingSubAssembly !== -1) {
                alert(`Cannot add sub-assembly ${subAssemblies[possibleExistingSubAssembly].assemblyNumber} as it already exists`);
                setSubAssemblyEditModalLoading(false);
                return;
            }
            var subAssemblyToAdd = subAssemblies.filter((currSubAssembly, idx) => currSubAssembly.id === subAssemblyId)[0]
            subAssemblyToAdd.quantity = parseInt(subAssemblyEntry.quantity);
            subAssemblyToAdd.total = subAssemblyToAdd.price * subAssemblyToAdd.quantity;
            var newSubAssemblys = [...formState, subAssemblyToAdd];
            setFormState(newSubAssemblys);
            setSubAssemblyEditModalLoading(false);
        }
        else {
            var updatedSubAssembly = subAssemblies[subAssemblyToEdit];
            updatedSubAssembly.quantity = parseInt(subAssemblyEntry.quantity);
            updatedSubAssembly.total = updatedSubAssembly.unitPrice * updatedSubAssembly.quantity;

            formState[subAssemblyToEdit] = updatedSubAssembly;
            setFormState(formState);
            setSubAssemblyEditModalLoading(false);
        }
    }

    const handleSubAssemblyDelete = (subAssemblyId) => {
        setDeleteModalLoading(true);
        const newFormState = formState.filter((currSubAssembly, idx) => currSubAssembly.id !== subAssemblyId);
        setFormState(newFormState);
        setSubAssemblyToDelete(null);
        setDeleteModalLoading(false);
    }

    return (
        <div className="base-modal-container">
            <div className="base-modal">
                <h1>Add Sub-Assemblies</h1>
                <div>
                    {formState.length > 0 &&
                        <div>
                            <Table
                                data={formState}
                                columns={GetSubAssemblyTableHeadersForSubAssembly(calculateSum(formState, "total"))}
                                deleteRowFunction={handleSubAssemblyDeleteRow}
                                editRowFunction={handleSubAssemblyEditRow}
                                initialState={{
                                    sortBy: [{ id: "partName", desc: false }],
                                    hiddenColumns: ["unitPrice", "total"]
                                }}
                                actions={['ModalEdit', 'ModalDelete']}
                                hasFooters={false}
                            />
                        </div>
                    }
                    {subAssemblies !== null && subAssemblies.length > 0 &&
                        <button className="btn" onClick={() => setAddEditSubAssemblyModalOpen(true)}>Add New Sub-Assembly</button>
                    }
                    {subAssemblies === null || subAssemblies.length === 0 &&
                        <div className="error">
                            Unable to Load Existing Assemblies. Cannot add sub assemblies at this time
                        </div>
                    }

                </div>
                <button className="btn inline-btn" onClick={() => { cancelFunction() }}>Cancel</button>
                <button className="btn inline-btn" onClick={() => { backFunction(formState) }}>Back</button>
                <button className="btn" onClick={handleSubmit}>Next</button>
                {addEditSubAssemblyModalOpen &&
                    <AddEditAssembly
                        closeModal={() => {
                            setAddEditSubAssemblyModalOpen(false);
                            setSubAssemblyToEdit(null);
                        }}
                        onModalSubmit={handleSubAssemblyAddEdit}
                        defaultValue={subAssemblyToEdit !== null && formState[subAssemblyToEdit]}
                        modalSubmitLoading={subAssemblyEditModalLoading}
                        assemblies={subAssemblies}
                        isPending={isPendingSubAssemblies}
                        error={errorSubAssemblies}
                        cancelFunction={() => {
                            setAddEditSubAssemblyModalOpen(false);
                            setSubAssemblyToEdit(false);
                        }}
                    />
                }
                {deleteModalOpen &&
                    <DeleteEntityModal
                        closeModal={() => {
                            setDeleteModalOpen(false);
                            setSubAssemblyToDelete(null);
                        }}
                        onModalSubmit={handleSubAssemblyDelete}
                        entityType="Sub-Assembly"
                        currentEntity={subAssemblyToDelete !== null && subAssemblyToDelete}
                        currentEntityString={subAssemblyToDelete.assemblyNumber}
                        modalSubmitLoading={deleteModalLoading}
                    />
                }
            </div>

        </div >
    );
}

export default NewAssemblySubAssembliesModal;