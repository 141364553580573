import { useState } from "react";
import NonPaginatedTable from "../../../NonPaginatedTable/NonPaginatedTable";
import { CustomTagTableHeaders } from "../../../../static-values/TableHeaders";
import AddEditCustomTagModal from "../../AddEditCustomTagModal/AddEditCustomTagModal";
import DeleteEntityModal from "../../DeleteEntityModal/DeleteEntityModal";
import useGet from "../../../../utils/hooks/useGet";
import { convertCustomTagListToDictList } from "../../../../utils/utils";

const NewAssemblyCustomTagsModal = ({ closeModal, onModalSubmit, defaultValue, backFunction = () => {}, cancelFunction = () => {} }) => {
    const { data: existingCustomTags, setData: setExistingCustomTags, isPending: isPendingExistingCustomTags, error: errorExistingCustomTags} = useGet(`/assembly/custom-tags-keys`);

    const [formState, setFormState] = useState(defaultValue);

    const [customTagToDelete, setCustomTagToDelete] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteModalLoading, setDeleteModalLoading] = useState(false);

    const [customTagToEdit, setCustomTagToEdit] = useState(null);
    const [addEditCustomTagModalOpen, setAddEditCustomTagModalOpen] = useState(false);
    const [customTagEditModalLoading, setCustomTagEditModalLoading] = useState(false);
    
    const handleCustomTagDeleteRow = (customTagId) => {
        const customTag = formState.filter((currCustomTag, idx) => currCustomTag.id === customTagId)[0];
        setCustomTagToDelete(customTag);
        setDeleteModalOpen(true);
    }

    const handleCustomTagEditRow = (customTagId) => {
        const position = formState.map(entry => entry.id).indexOf(customTagId);
        setCustomTagToEdit(position);
        setAddEditCustomTagModalOpen(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onModalSubmit(formState);
        closeModal();
    }

    const handleAddEditCustomTag = (customTagEntry) => {
        if (customTagToEdit === null) {
            const newCustomTag = {
                "id": formState.length + 1,
                "key": customTagEntry.key,
                "value": customTagEntry.value
            }

            const possibleExistingCustomTag = formState.filter((currCustomTag, idx) => currCustomTag.key === newCustomTag.key)
            if (possibleExistingCustomTag.length > 0) {
                alert(`Cannot add an entry for ${customTagEntry.key} as it already exists`);
                return
            }
            else {
                setFormState([...formState, newCustomTag]);
            }
        }
        else {
            var editedCustomTag = formState[customTagToEdit];
            editedCustomTag.key = customTagEntry.key;
            editedCustomTag.value = customTagEntry.value;
            formState[customTagToEdit] = editedCustomTag;
            setFormState([...formState]);
        }
    }

    const handleCustomTagDelete = (customTagId) => {
        setDeleteModalLoading(true);
        const newFormState = formState.filter((currCustomTag, idx) => currCustomTag.id !== customTagId);
        setFormState(newFormState);
        setCustomTagToDelete(null);
        setDeleteModalLoading(false);
    }

    return (
        <div className="base-modal-container">
            <div className="base-modal">
                <h1>Add Specifications</h1>
                <div>
                    <NonPaginatedTable
                        data={formState}
                        columns={CustomTagTableHeaders}
                        deleteRowFunction={handleCustomTagDeleteRow}
                        editRowFunction={handleCustomTagEditRow}
                        initialState={{}}
                        actions={['ModalEdit', 'ModalDelete']}
                        hasFooters={false}
                        renderHeaders={false}
                    />
                    <button className="btn" onClick={() => setAddEditCustomTagModalOpen(true)}>Add New Spec</button>
                </div>
                <button className="btn inline-btn" onClick={() => { cancelFunction() }}>Cancel</button>
                <button className="btn inline-btn" onClick={() => { backFunction(formState) }}>Back</button>
                <button className="btn" onClick={handleSubmit}>Next</button>
                {addEditCustomTagModalOpen &&
                    <AddEditCustomTagModal
                        closeModal={() => {
                            setAddEditCustomTagModalOpen(false);
                            setCustomTagToEdit(null);
                        }}
                        onModalSubmit={handleAddEditCustomTag}
                        defaultValue={customTagToEdit !== null && formState[customTagToEdit]}
                        modalSubmitLoading={customTagEditModalLoading}
                        existingKeys={convertCustomTagListToDictList(existingCustomTags)}
                    />
                }
                {deleteModalOpen &&
                    <DeleteEntityModal
                        closeModal={() => {
                            setDeleteModalOpen(false);
                            setCustomTagToDelete(null);
                        }}
                        onModalSubmit={handleCustomTagDelete}
                        entityType="Custom Tag"
                        currentEntity={customTagToDelete !== null && customTagToDelete}
                        currentEntityString={customTagToDelete.key}
                        modalSubmitLoading={deleteModalLoading}
                    />
                }
            </div>
        </div>
    );
}

export default NewAssemblyCustomTagsModal;