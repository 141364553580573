import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import ColumnFilter from '../ColumnFilters/ColumnFilter';
import { BsFillTrashFill, BsFillPencilFill, BsFillEyeFill, BsDownload, BsBoxArrowUpRight, BsFillClipboard2Fill } from "react-icons/bs";
import "./Table.css";
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
const Table = ({ 
    data, 
    columns, 
    deleteRowFunction, 
    editRowFunction, 
    initialState, 
    actionGoesOnRow = 1, 
    actions = ['ModalEdit', 'ModalDelete'], 
    detailPagePrefix = null, 
    hasFooters = false, 
    createQuoteFromAssemblyFunction = (id) => { },
    copyQuoteFunction = (id) => {}
}) => {
    const defaultColumn = useMemo(
        () => ({
            Filter: ColumnFilter
        }),
        []
    )
    const navigate = useNavigate();

    useEffect(() => { }, [data]);

    const tableInstance = useTable({ columns, data, defaultColumn, initialState: initialState },
        useFilters,
        useSortBy,
        usePagination
    );
    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        setFilter,
        page,
        pageCount,
        pageOptions,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setAllFilters,
        state: { pageIndex, pageSize },
        prepareRow } = tableInstance;
    let headerRowCount = 0;
    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => {
                        headerRowCount += 1;
                        return (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                        </span>
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                                {headerRowCount === actionGoesOnRow && actions.length !== 0 && <th>Actions</th>}
                                {headerRowCount !== actionGoesOnRow && actions.length !== 0 && <th></th>}
                            </tr>
                        )
                    })}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}</td>
                                })}
                                {
                                    actions.length !== 0 && <td key={`actions-${row.id}`}>
                                        <span className="actions">
                                            {actions.includes('DownloadFile') &&
                                                <Link target="_blank" rel="noopener noreferrer" to={row.original.presignedUrl}>
                                                    <BsDownload />
                                                </Link>
                                            }
                                            {actions.includes('ModalDelete') &&
                                                <>
                                                    <span style={{ marginRight: "5px" }}
                                                        data-tooltip-id='delete'
                                                        data-tooltip-content="Delete"
                                                        data-tooltip-place='top'
                                                    >
                                                        <BsFillTrashFill
                                                            color="red"
                                                            fill="red"
                                                            onClick={() => deleteRowFunction(row.original.id)}
                                                        />
                                                    </span>
                                                    <Tooltip id='delete' />
                                                </>
                                            }
                                            {actions.includes('ModalEdit') &&
                                                <>
                                                    <span style={{ marginRight: "5px" }}
                                                        data-tooltip-id='edit'
                                                        data-tooltip-content="Edit"
                                                        data-tooltip-place='top'
                                                    >
                                                        <BsFillPencilFill
                                                            onClick={() => (editRowFunction(row.original.id))}
                                                        />
                                                    </span>
                                                    <Tooltip id='edit' />
                                                </>
                                            }
                                            {actions.includes('LinkToDetailPage') &&
                                                <>
                                                    <span style={{ marginRight: "5px" }}
                                                        data-tooltip-id='linkToDetail'
                                                        data-tooltip-content="View Details"
                                                        data-tooltip-place='top'
                                                    >
                                                        <BsFillEyeFill
                                                            onClick={() => (navigate(`${detailPagePrefix}/${row.original.id}`))}
                                                        />
                                                    </span>
                                                    <Tooltip id='linkToDetail' />
                                                </>
                                            }
                                            {actions.includes('CreateQuoteFromAssembly') &&
                                                <>
                                                    <span
                                                        data-tooltip-id='createQuoteFromAssembly'
                                                        data-tooltip-content="Create Quote"
                                                        data-tooltip-place='top'
                                                    >
                                                        <BsBoxArrowUpRight
                                                            onClick={() => createQuoteFromAssemblyFunction(row.original.id)}
                                                        />
                                                    </span>
                                                    <Tooltip id='createQuoteFromAssembly' />
                                                </>
                                            }
                                            {actions.includes('CopyQuote') &&
                                                <>
                                                    <span
                                                        data-tooltip-id='copyQuote'
                                                        data-tooltip-content="Create a Copy"
                                                        data-tooltip-place='top'
                                                    >
                                                        <BsFillClipboard2Fill
                                                            onClick={() => copyQuoteFunction(row.original.id)}
                                                        />
                                                    </span>
                                                    <Tooltip id='copyQuote' />
                                                </>
                                            }
                                        </span>
                                    </td>
                                }

                            </tr>
                        )
                    })
                    }
                </tbody>
                {hasFooters && <tfoot>
                    {footerGroups.map(footerGroup => (
                        <tr {...footerGroup.getFooterGroupProps()}>
                            {footerGroup.headers.map(column => (
                                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                            ))}
                            {actions.length !== 0 && <td></td>}
                        </tr>
                    ))}
                </tfoot>}

            </table>
            <form className="inline">
                <div className="form-row">
                    <div className="form-group input-group col-md-2">
                        <ul className="pagination">
                            <li
                                className={!canPreviousPage ? "page-item disabled" : "page-item "}
                                style={{ cursor: "pointer" }}
                            >
                                <a
                                    data-tooltip-id='firstPage'
                                    data-tooltip-content="First Page"
                                    data-tooltip-place='top'
                                    className="page-link" onClick={() => gotoPage(0)}
                                >
                                    {"<<"}
                                </a>
                                <Tooltip id='firstPage' />
                            </li>
                            <li
                                className={!canPreviousPage ? "page-item disabled" : "page-item "}
                                style={{ cursor: "pointer" }}
                            >
                                <a
                                    className="page-link" onClick={() => previousPage()}
                                    data-tooltip-id='prevPage'
                                    data-tooltip-content="Previous Page"
                                    data-tooltip-place='top'
                                >
                                    {"<"}
                                </a>
                                <Tooltip id='prevPage' />
                            </li>
                            <li
                                className={!canNextPage ? "page-item disabled" : "page-item "}
                                style={{ cursor: "pointer" }}
                            >
                                <a
                                    className="page-link" onClick={() => nextPage()}
                                    data-tooltip-id='nextPage'
                                    data-tooltip-content="Next Page"
                                    data-tooltip-place='top'
                                >
                                    {">"}
                                </a>
                                <Tooltip id='nextPage' />
                            </li>
                            <li
                                className={!canNextPage ? "page-item disabled" : "page-item "}
                                style={{ cursor: "pointer" }}
                            >
                                <a
                                    className="page-link"
                                    data-tooltip-id='lastPage'
                                    data-tooltip-content="Last Page"
                                    data-tooltip-place='top'
                                    onClick={() => gotoPage(pageCount - 1)}
                                >
                                    {">>"}
                                </a>
                                <Tooltip id='lastPage' />
                            </li>
                        </ul>
                    </div>
                    <span>
                        <select
                            className="custom-select"
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                        Page{" "}<strong>{pageIndex + 1} of {pageOptions.length}</strong>{" "}| Go to page:{" "} <input
                            className="form-control"
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            style={{ width: "100px" }}
                        />
                        <button type='button' className='btn' style={{ marginLeft: "10px" }} onClick={() => setAllFilters([])}>Reset Filters</button>
                    </span>
                </div>
            </form>
        </>
    );
}

export default Table;