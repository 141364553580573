import useGet from "../../utils/hooks/useGet";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

import "./Assemblies.css";
import apiClient from "../../services/apiClient";
import { testAuth } from "../../services/apiClient";
import Table from "../Table/Table";
import { AssemblyTableHeaders } from "../../static-values/TableHeaders";
import AddNewQuoteModal from "../Modals/AddNewQuoteModal/AddNewQuoteModal";
import DeleteEntityModal from "../Modals/DeleteEntityModal/DeleteEntityModal";
import ConfirmationModal from "../Modals/ConfirmationModal/ConfirmationModal";
import { getLocalStorage } from "../../utils/sessionsUtils";

const Assemblies = () => {
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const testAuthResult = await testAuth(apiClient);
            if (!testAuthResult) {
                navigate('/login');
            }
        })();
    }, [navigate])

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [assemblyToDelete, setAssemblyToDelete] = useState(null);
    const [deleteModalLoading, setDeleteModalLoading] = useState(false);

    const [createQuoteFromAssemblyModalOpen, setCreateQuoteFromAssemblyModalOpen] = useState(false);
    const [newQuoteModalLoading, setNewQuoteModalLoading] = useState(false);
    const [newQuoteInitialAssemblyId, setNewQuoteInitialAssemblyId] = useState(null);
    const [newQuoteId, setNewQuoteId] = useState(null);

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const { data: assemblies, setData: setAssemblies, isPending, error } = useGet("/assembly");
    const { data: clients, setData, isPending: isPendingClients, error: errorClients } = useGet("/client");

    const handleSubmitConfirmationModal = (id) => {
        navigate(`/quote/${newQuoteId}`);
    }

    const handleNewQuoteSubmit = (newQuote) => {
        setNewQuoteModalLoading(true);

        const newQuoteBody = {
            "clientId": parseInt(newQuote.clientId),
            "projectName": newQuote.projectName,
            "customerPONumber": newQuote.customerPONumber,
            "initialAssemblyId": newQuote.initialAssemblyId,
            "initialAssemblyQuantity": parseInt(newQuote.initialAssemblyQuantity),
            "salesPersonUserId": getLocalStorage("userId"),
            "attention" : newQuote.attention
        };

        apiClient.post("/quote", newQuoteBody)
            .then(response => {
                setNewQuoteId(response.data.id);
                setConfirmationModalOpen(true);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to create new quote from assembly. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setNewQuoteModalLoading(false);
            });
    }

    const handleDeleteRow = (targetAssemblyId) => {
        const assembly = assemblies.filter((currAssembly, idx) => currAssembly.id === targetAssemblyId)[0];
        setAssemblyToDelete(assembly);
        setDeleteModalOpen(true);
    }

    const handleSendDeleteRequest = (targetAssemblyId) => {
        setDeleteModalLoading(true);

        apiClient.delete(`assembly/${targetAssemblyId}`)
            .then(response => {
                setAssemblyToDelete(null);
                setAssemblies(assemblies.filter((currAssembly, idx) => currAssembly.id !== targetAssemblyId));
                setDeleteModalLoading(false);
                setDeleteModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                else if (err.response.status === 400) {
                    alert(err.response.data);
                }
                else {
                    alert(`Failed to delete assembly. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                }
                setDeleteModalLoading(false);
            });
    }

    return (
        <div className="assemblies">
            <div className="table-title">
                <h2>Assemblies</h2>
            </div>
            {isPending && <p>Loading data</p>}
            {!isPending && error !== null &&
                <div className="error">
                    {error}
                </div>
            }
            {!isPending && (error === null) &&
                <Table data={assemblies}
                    columns={AssemblyTableHeaders}
                    deleteRowFunction={handleDeleteRow}
                    editRowFunction={() => { }}
                    createQuoteFromAssemblyFunction={(id) => {
                        setNewQuoteInitialAssemblyId(id);
                        setCreateQuoteFromAssemblyModalOpen(true);
                    }}
                    initialState={{ sortBy: [{ id: "assemblyNumber", desc: false }] }}
                    actions={['LinkToDetailPage', 'ModalDelete', 'CreateQuoteFromAssembly']}
                    detailPagePrefix="/assembly"
                />}
            {!isPending && (error === null) && <button className="btn add-new-btn" onClick={() => { navigate('/assembly/new') }}>Add New Assembly</button>}
            {!isPending && (error === null) && deleteModalOpen &&
                <DeleteEntityModal
                    closeModal={() => {
                        setAssemblyToDelete(null);
                    }}
                    onModalSubmit={handleSendDeleteRequest}
                    entityType="Assembly"
                    currentEntity={assemblyToDelete !== null && assemblyToDelete}
                    currentEntityString={assemblyToDelete !== null && assemblyToDelete.assemblyNumber}
                    modalSubmitLoading={deleteModalLoading}
                    cancelFunction={() => {
                        setDeleteModalOpen(false);
                    }}
                />
            }
            {!isPending && (error === null) && createQuoteFromAssemblyModalOpen &&
                <AddNewQuoteModal
                    closeModal={() => {
                    }}
                    onModalSubmit={handleNewQuoteSubmit}
                    defaultValue={null}
                    modalSubmitLoading={newQuoteModalLoading}
                    clientList={clients}
                    isPendingClients={isPendingClients}
                    errorClient={errorClients}
                    assemblies={assemblies}
                    isPendingAssemblies={isPending}
                    errorAssemblies={error}
                    initialAssemblyId={newQuoteInitialAssemblyId}
                    cancelFunction={() => {
                        setCreateQuoteFromAssemblyModalOpen(false);
                    }}
                    startingQuoteFromAssembly={true}
                />
            }
            {!isPending && (error === null) && confirmationModalOpen &&
                <ConfirmationModal
                    onModalSubmit={handleSubmitConfirmationModal}
                    closeModal={() => {
                        setCreateQuoteFromAssemblyModalOpen(false);
                        setNewQuoteModalLoading(false);
                        setConfirmationModalOpen(false);
                        setNewQuoteId(null);
                    }}
                    displayText="Would you like to view the new quote now?"
                />
            }
        </div>
    );
}

export default Assemblies;