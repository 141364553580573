import './Login.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setLocalStorage } from '../../utils/sessionsUtils';

import apiClient from '../../services/apiClient';

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsPending(true);
        
        apiClient.post("/user/login", {
            username: username,
            password: password
        }).then(res => {
            setLocalStorage('idToken', res.data.idToken);
            setLocalStorage('userId', res.data.cognitoUserId);
            setLocalStorage('refreshToken', res.data.refreshToken);
            setLocalStorage('userFullName', `${res.data.firstName} ${res.data.lastName}`)
            setIsPending(false);
            setError(null);
            navigate('/');
        })
        .catch(err => {
            if (err.name === 'AbortError') {
                console.log("Fetch aborted");
            }
            setError(err.message);
            setIsPending(false);
        })
    };

    return (
        <div className="login">
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                {(error !== null) && <div className="error">
                    <p>{error}</p>
                </div>}
                <label>Email:</label>
                <input
                    type="text" required value={username} onChange={(e) => setUsername(e.target.value)}
                />
                <label>Password:</label>
                <input
                    type="password" required value={password} onChange={(e) => setPassword(e.target.value)}
                />
                {!isPending && <button>Login</button>}
                {isPending && <button disabled>Logging in...</button>}
            </form>
        </div>
    );
}

export default Login;