import { useState } from "react";
import { validateEmail, validatePhoneNumber } from "../../../utils/validateFields";

const AddEditContactModal = ({ closeModal, onModalSubmit, defaultValue, modalSubmitLoading, cancelFunction}) => {
    const [formState, setFormState] = useState(defaultValue || 
        {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            isPrimary: 0
        }
    );

    const [errors, setErrors] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState(false);

    const requiredFields = ['firstName', 'lastName', 'email', 'phoneNumber', 'isPrimary'];
    const validateForm = () => {
        if (formState.firstName && formState.lastName && formState.email && formState.phoneNumber && validatePhoneNumber(formState.phoneNumber)) {
            setErrors("");
            setPhoneNumberError(false);
            return true;
        }

        let errorFields = []
        for(const [key, value] of Object.entries(formState))
        {
            if (!value && requiredFields.includes(key)) errorFields.push(key);
        }

        if (!validatePhoneNumber(formState.phoneNumber)) setPhoneNumberError(true);

        setErrors(errorFields.join(', '));
        return false;
    }

    const handleFormUpdate = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onModalSubmit(formState);
        }
        
    }
    
    return (
        <div className="base-modal-container" onClick={(e) => {
            if (e.target.className === "base-modal-container") closeModal();
        }}>
            <div className="base-modal">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input name="firstName" value={formState.firstName} onChange={handleFormUpdate}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input name="lastName" value={formState.lastName} onChange={handleFormUpdate}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input name="email" value={formState.email} onChange={handleFormUpdate}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input name="phoneNumber" value={formState.phoneNumber} onChange={handleFormUpdate}/>
                    </div>
                    {phoneNumberError && <div className="error">Please verify this is a valid phone number</div>}
                    <div className="form-group">
                        <label htmlFor="isPrimary">
                            Is Primary Contact?
                        </label>
                        <select name="isPrimary" value={formState.isPrimary} onChange={handleFormUpdate}>
                            <option key={0} value={false}>No</option>
                            <option key={1} value={true}>Yes</option>
                        </select>
                    </div>
                    {errors && <div className="error">{`Please include: ${errors}`}</div>}
                    {!modalSubmitLoading && 
                        <div>
                            <button className="btn inline-btn" type="button" onClick={() => cancelFunction()}>Cancel</button>
                            <button className="btn" type="submit">Submit</button>
                        </div>
                    }
                    {modalSubmitLoading && <button disabled className="btn" type="submit">Submitting...</button>}
                </form>
            </div>
        </div>
    );
}
 
export default AddEditContactModal;