import useGet from "../../../utils/hooks/useGet";
import { useState } from "react";
import SearchableDropdown from "../../SearchableDropdown/SearchableDropdown";

const AddEditPartModal = ({ closeModal, onModalSubmit, defaultValue, modalSubmitLoading, cancelFunction, vendorsList, isPendingVendors, errorVendors }) => {
    const [formState, setFormState] = useState(defaultValue ||
    {
        "partName": "",
        "partDescription": "",
        "material": "",
        "materialType": "",
        "makeBuy": 0,
        "price": 0,
        "vendorId": 0,
        "length": 0,
        "machine": ""
    }
    );

    const [errors, setErrors] = useState("");
    
    const getVendorFromId = (targetId) => {
        console.log(targetId);

        return vendorsList.filter(vendor => vendor.id === targetId)[0];
    }
    
    const [vendorValue, setVendorValue] = useState(formState.vendorId === 0 ? "Select a vendor..." : getVendorFromId(formState.vendorId).name);

    const requiredFields = ['firstName', 'lastName', 'email', 'phoneNumber', 'isPrimary'];

    const validateForm = () => {
        if (formState.partName && formState.partDescription && formState.material && formState.price >= 0 && formState.material && formState.price && formState.vendorId && formState.vendorId !== "Loading vendors..." && formState.vendorId !== "Unable to load vendors" && formState.vendorId !== 0) {
            setErrors("");
            return true;
        }

        let errorFields = []
        for (const [key, value] of Object.entries(formState)) {
            if (!value && requiredFields.includes(key)) errorFields.push(key);
        }
        setErrors(errorFields.join(', '));
        return false;
    }

    const handleFormUpdate = (e) => {
        if (typeof (e) === "number") {
            var currentVendor = getVendorFromId(e);
            setVendorValue(currentVendor.name);
            setFormState({
                ...formState,
                ["vendorId"]: currentVendor.id
            })
        }
        else {
            setFormState({
                ...formState,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onModalSubmit(formState);
            closeModal();
        }

    }

    return (
        <div className="base-modal-container" onClick={(e) => {
            if (e.target.className === "base-modal-container") closeModal();
        }}>
            <div className="base-modal">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="partName">Part Number</label>
                        <input name="partName" value={formState.partName} onChange={handleFormUpdate} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="partDescription">Part Description</label>
                        <textarea name="partDescription" value={formState.partDescription} onChange={handleFormUpdate} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="material">Material</label>
                        <input name="material" value={formState.material} onChange={handleFormUpdate} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="materialType">Material Type</label>
                        <input name="materialType" value={formState.materialType} onChange={handleFormUpdate} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="price">Price</label>
                        <input name="price" value={formState.price} onChange={handleFormUpdate} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="makeBuy">Make or Buy?</label>
                        <select name="makeBuy" value={formState.makeBuy} onChange={handleFormUpdate}>
                            <option key={0} value={0}>Make</option>
                            <option key={1} value={1}>Buy</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="length">Length (in)</label>
                        <input name="length" value={formState.length} onChange={handleFormUpdate} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="machine">Machine</label>
                        <input name="machine" value={formState.machine} onChange={handleFormUpdate} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="vendorId">Vendor</label>
                        {isPendingVendors && !errorVendors &&
                            <select name="vendorId" value={formState.vendorId} onChange={handleFormUpdate}>
                                <option key={1} value="Loading vendors...">Loading vendors...</option>
                            </select>
                        }
                        {!isPendingVendors && !errorVendors &&
                            <SearchableDropdown
                                options={vendorsList.sort((a, b) => (a.name).localeCompare(b.name))}
                                label="name"
                                id="id"
                                selectedVal={vendorValue}
                                handleChange={(val) => typeof (val) === 'number' ? handleFormUpdate(val) : setVendorValue(val)}
                            />
                        }
                        {!isPendingVendors && errorVendors &&
                            <select name="vendorId" value={formState.vendorId} onChange={handleFormUpdate}>
                                <option key={1} value="Unable to load vendors">Unable to load vendors</option>
                            </select>
                        }
                    </div>
                    {errors && <div className="error">{`Please include: ${errors}`}</div>}
                    {!modalSubmitLoading &&
                        <div>
                            <button className="btn inline-btn" type="button" onClick={() => cancelFunction()}>Cancel</button>
                            <button className="btn" type="submit">Submit</button>
                        </div>
                    }
                    {modalSubmitLoading && <button disabled className="btn" type="submit">Submitting...</button>}
                </form>
            </div>
        </div>
    );
}

export default AddEditPartModal;