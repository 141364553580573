import { WonLostIdToValueMappings } from "../../static-values/Mappings";

const ColumnDropDownFilterWonLost = ({ column }) => {
    const {filterValue, setFilter } = column;
    const handleFilterUpdate = (value) => {
        if (value === -1) setFilter(undefined);
        else setFilter(value);
    }
    return ( 
        <span>
            Search: {''}
            <select value={filterValue || -1} onChange={(e) => handleFilterUpdate(e.target.value)}>
                {
                    Object.keys(WonLostIdToValueMappings).map((key, idx) => {
                        return <option key={key} value={key}>{WonLostIdToValueMappings[key]}</option>
                    })
                }
                <option key={-1} value={-1}>----</option>
            </select>
        </span>
     );
}
 
export default ColumnDropDownFilterWonLost;