import "./AssemblyDetail.css"

import { useParams } from 'react-router-dom';
import useGet from "../../utils/hooks/useGet";
import { useState } from "react";
import { CustomTagTableHeaders, GetHeadersForSmallTables, GetPartTableHeadersForAssembly, GetSubAssemblyTableHeadersForSubAssembly } from "../../static-values/TableHeaders";
import DeleteEntityModal from "../Modals/DeleteEntityModal/DeleteEntityModal";
import Table from "../Table/Table";
import NonPaginatedTable from "../NonPaginatedTable/NonPaginatedTable";
import { EngineeringIdToValueMappings, EngineeringValueToIdMappings, LaborIdToValueMappings, LaborValueToIdMappings, MachineShopIdToValueMappings, MachineShopValueToIdMappings } from "../../static-values/Mappings";
import AddEditLaborEngineeringMachiningModal from "../Modals/AddEditLaborEngineeringMachiningModal/AddEditLaborEngineeringMachiningModal";
import apiClient from "../../services/apiClient";
import AddEditPartForAssembly from "../Modals/AddEditPartForAssembly/AddEditPartForAssembly";
import AddEditAssembly from "../Modals/AddEditAssembly/AddEditAssembly";
import { addDropDownDisplayNameToParts, formatMoney, formatStringPercentageToFloat } from "../../utils/utils";
import AddEditCustomTagModal from "../Modals/AddEditCustomTagModal/AddEditCustomTagModal";
import { calculateSum } from "../../utils/utils";
import { convertCustomTagListToDictList } from "../../utils/utils";

const AssemblyDetail = () => {
    const { assemblyId } = useParams();

    const formatData = (assembly) => {
        assembly.labor = assembly.labor.map(labor => {
            labor.typeString = LaborIdToValueMappings[labor.type];
            labor.total = labor.hours * labor.rate
            return labor;
        });

        assembly.engineering = assembly.engineering.map(engineering => {
            engineering.typeString = EngineeringIdToValueMappings[engineering.type];
            engineering.total = engineering.hours * engineering.rate
            return engineering;
        });

        assembly.machining = assembly.machining.map(machining => {
            machining.typeString = MachineShopIdToValueMappings[machining.type];
            machining.total = machining.hours * machining.rate
            return machining;
        });

        assembly.contingencyPercentageString = `${(assembly.contingencyPercentage * 100).toFixed(2)}%`
        assembly.profitMarginPercentageString = `${(assembly.profitMarginPercentage * 100).toFixed(2)}%`
        return assembly;
    }

    const formatDataForParts = (parts) => {
        return addDropDownDisplayNameToParts(parts);
    }

    const { data: assemblyDetails, setData: setAssemblyDetails, isPending, error } = useGet(`/assembly/${assemblyId}`, formatData);
    const { data: parts, setData: setParts, isPendingParts, errorParts } = useGet(`/part`, formatDataForParts);
    const { data: assemblies, setData: setAssemblies, isPendingSubAssemblies, errorSubAssemblies } = useGet(`/assembly`);
    const { data: existingCustomTags, setData: setExistingCustomTags, isPending: isPendingExistingCustomTags, error: errorExistingCustomTags} = useGet(`/assembly/custom-tags-keys`);

    const [pendingChanges, setPendingChanges] = useState(false);
    const [sendingUpdate, setSendingUpdate] = useState(false);
    const [deleteModalLoading, setDeleteModalLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const [addEditCustomTagModalOpen, setAddEditCustomTagModalOpen] = useState(false);
    const [customTagToEdit, setCustomTagToEdit] = useState(null);
    const [deleteCustomTagModalOpen, setDeleteCustomTagModalOpen] = useState(false);
    const [customTagToDelete, setCustomTagToDelete] = useState(null);
    const [customTagEditModalLoading, setCustomTagEditModalLoading] = useState(false);

    const [newLaborModalOpen, setNewLaborModalOpen] = useState(false);
    const [editLaborModalOpen, setEditLaborModalOpen] = useState(false);
    const [laborToEdit, setLaborToEdit] = useState(null);
    const [deleteLaborModalOpen, setDeleteLaborModalOpen] = useState(false);
    const [laborToDelete, setLaborToDelete] = useState(null);
    const [laborEditModalLoading, setLaborEditModalLoading] = useState(false);

    const [newEngineeringModalOpen, setNewEngineeringModalOpen] = useState(false);
    const [editEngineeringModalOpen, setEditEngineeringModalOpen] = useState(false);
    const [engineeringToEdit, setEngineeringToEdit] = useState(null);
    const [deleteEngineeringModalOpen, setDeleteEngineeringModalOpen] = useState(false);
    const [engineeringToDelete, setEngineeringToDelete] = useState(null);
    const [engineeringEditModalLoading, setEngineeringEditModalLoading] = useState(false);

    const [newMachiningModalOpen, setNewMachiningModalOpen] = useState(false);
    const [editMachiningModalOpen, setEditMachiningModalOpen] = useState(false);
    const [machiningToEdit, setMachiningToEdit] = useState(null);
    const [deleteMachiningModalOpen, setDeleteMachiningModalOpen] = useState(false);
    const [machiningToDelete, setMachiningToDelete] = useState(null);
    const [machiningEditModalLoading, setMachiningEditModalLoading] = useState(false);

    const [addEditPartModalOpen, setAddEditPartModalOpen] = useState(false);
    const [partToEdit, setPartToEdit] = useState(null);
    const [deletePartModalOpen, setDeletePartModalOpen] = useState(false);
    const [partToDelete, setPartToDelete] = useState(null);
    const [partEditModalLoading, setPartEditModalLoading] = useState(false);

    const [addEditSubAssemblyModalOpen, setAddEditSubAssemblyModalOpen] = useState(false);
    const [subAssemblyToEdit, setSubAssemblyToEdit] = useState(null);
    const [deleteSubAssemblyModalOpen, setDeleteSubAssemblyModalOpen] = useState(false);
    const [subAssemblyToDelete, setSubAssemblyToDelete] = useState(null);
    const [subAssemblyEditModalLoading, setSubAssemblyEditModalLoading] = useState(false);

    const validateForm = (updatedAssemblyInfo) => {
        var currentValidationErrors = {};
        if (updatedAssemblyInfo.contingencyPercentage > 1 || updatedAssemblyInfo.contingencyPercentage < 0) {
            currentValidationErrors = {
                ...currentValidationErrors,
                ['contingencyPercentage']: "Contingency percentage must be less than 100% and greater or equal to 0"
            }
        }

        if (updatedAssemblyInfo.profitMarginPercentage > 1 || updatedAssemblyInfo.profitMarginPercentage < 0) {
            currentValidationErrors = {
                ...currentValidationErrors,
                ['profitMarginPercentage']: "Profit Margin percentage must be less than 100% and greater or equal to 0"
            }
        }

        if (currentValidationErrors.length > 0) {
            setValidationErrors(currentValidationErrors);
            return false;
        }

        return true;
    }

    const recalculateSubTotalAndTotal = (assemblyDetails) => {
        const engineeringPrice = assemblyDetails.engineering.map(entry => entry.total).reduce((a, b) => a + b, 0);
        const laborPrice = assemblyDetails.labor.map(entry => entry.total).reduce((a, b) => a + b, 0);
        const machiningPrice = assemblyDetails.machining.map(entry => entry.total).reduce((a, b) => a + b, 0);
        const partPrice = assemblyDetails.parts.map(entry => entry.price * entry.quantity).reduce((a, b) => a + b, 0);

        assemblyDetails.subTotal = engineeringPrice + laborPrice + partPrice + machiningPrice;

        var subAssemblyTotal = 0;

        assemblyDetails.subAssemblies.forEach(subAssembly => {
            subAssemblyTotal += (subAssembly.unitPrice * subAssembly.quantity);
        })

        assemblyDetails.total = (((assemblyDetails.subTotal / (1 - assemblyDetails.contingencyPercentage)) / (1 - assemblyDetails.profitMarginPercentage))) + subAssemblyTotal;
        assemblyDetails.subAssemblyTotal = subAssemblyTotal;
        return assemblyDetails;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSendingUpdate(true);

        const updatedAssemblyInfo = {
            "assemblyNumber": assemblyDetails.assemblyNumber,
            "assemblyDescription": assemblyDetails.assemblyDescription,
            "contingencyPercentage": formatStringPercentageToFloat(assemblyDetails.contingencyPercentageString),
            "profitMarginPercentage": formatStringPercentageToFloat(assemblyDetails.profitMarginPercentageString)
        };

        if (validateForm(updatedAssemblyInfo)) {
            apiClient.patch(`/assembly/${assemblyDetails.id}`, updatedAssemblyInfo)
                .then(response => {
                    var updatedAssemblyDetailsResponse = formatData(response.data);
                    updatedAssemblyDetailsResponse = recalculateSubTotalAndTotal(updatedAssemblyDetailsResponse);
                    setAssemblyDetails(updatedAssemblyDetailsResponse);
                    setSendingUpdate(false);
                    setPendingChanges(false);
                    setValidationErrors({});
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to edit assembly details. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setSendingUpdate(false);
                    setPendingChanges(false);
                    setValidationErrors({});
                });
        }
    }

    const handleLaborEdit = (laborId) => {
        const position = assemblyDetails.labor.map(entry => entry.id).indexOf(laborId);
        setLaborToEdit(position);
        setEditLaborModalOpen(true);
    }

    const handleLaborDeleteRow = (laborId) => {
        const labor = assemblyDetails.labor.filter((currLabor, idx) => currLabor.id === laborId)[0];
        setLaborToDelete(labor);
        setDeleteLaborModalOpen(true);
    }

    const handleEngineeringEdit = (engineeringId) => {
        const position = assemblyDetails.engineering.map(entry => entry.id).indexOf(engineeringId);
        setEngineeringToEdit(position);
        setEditEngineeringModalOpen(true);
    }

    const handleEngineeringDeleteRow = (engineeringId) => {
        const engineering = assemblyDetails.engineering.filter((currEngineering, idx) => currEngineering.id === engineeringId)[0];
        setEngineeringToDelete(engineering);
        setDeleteEngineeringModalOpen(true);
    }

    const handleMachiningEdit = (machiningId) => {
        const position = assemblyDetails.machining.map(entry => entry.id).indexOf(machiningId);
        setMachiningToEdit(position);
        setEditMachiningModalOpen(true);
    }

    const handleMachiningDeleteRow = (machiningId) => {
        const machining = assemblyDetails.machining.filter((currMachining, idx) => currMachining.id === machiningId)[0];
        setMachiningToDelete(machining);
        setDeleteMachiningModalOpen(true);
    }

    const handlePartEdit = (partId) => {
        const position = assemblyDetails.parts.map(entry => entry.id).indexOf(partId);
        setPartToEdit(position);
        setAddEditPartModalOpen(true);
    }

    const handlePartDeleteRow = (partId) => {
        const part = assemblyDetails.parts.filter((currPart, idx) => currPart.id === partId)[0];
        setPartToDelete(part);
        setDeletePartModalOpen(true);
    }

    const handleCustomTagEdit = (customTagId) => {
        const position = assemblyDetails.customTags.map(entry => entry.id).indexOf(customTagId);
        setCustomTagToEdit(position);
        setAddEditCustomTagModalOpen(true);
    }

    const handleSubAssemblyEdit = (subAssemblyId) => {
        const position = assemblyDetails.subAssemblies.map(entry => entry.id).indexOf(subAssemblyId);
        setSubAssemblyToEdit(position);
        setAddEditSubAssemblyModalOpen(true);
    }

    const handleSubAssemblyDeleteRow = (subAssemblyId) => {
        const subAssembly = assemblyDetails.subAssemblies.filter((currSubAssembly, idx) => currSubAssembly.id === subAssemblyId)[0];
        setSubAssemblyToDelete(subAssembly);
        setDeleteSubAssemblyModalOpen(true);
    }

    const handleFormUpdate = (e) => {
        setPendingChanges(true);
        if (!e.target.name.includes('-')) {
            setAssemblyDetails({
                ...assemblyDetails,
                [e.target.name]: e.target.value
            })
        }
        else {
            const targetInfo = e.target.name.split('-');
            const parentField = targetInfo[0];
            const childField = targetInfo[1];
            const idxToChange = parseInt(targetInfo[2]);

            let array = assemblyDetails[parentField];
            array[idxToChange][childField] = e.target.value;

            setAssemblyDetails({
                ...assemblyDetails,
                [parentField]: array
            });
        }
    }

    const handleChangingLaborEngineeringMachining = (updateRequest) => {
        switch (updateRequest.modalType) {
            case "Labor":
                setLaborEditModalLoading(true);
                if (laborToEdit === null) {
                    const newLaborBody = {
                        "type": parseInt(LaborValueToIdMappings[updateRequest.typeString]),
                        "hours": parseFloat(updateRequest.hours),
                        "rate": parseFloat(updateRequest.rate)
                    }

                    apiClient.post(`/assembly/${assemblyDetails.id}/labor`, newLaborBody)
                        .then(response => {
                            response.data.typeString = updateRequest.typeString;
                            var newLabor = [...assemblyDetails.labor, response.data];
                            var newAssemblyDetails = {
                                ...assemblyDetails,
                                ["labor"]: newLabor
                            }
                            newAssemblyDetails = recalculateSubTotalAndTotal(newAssemblyDetails);
                            newAssemblyDetails = formatData(newAssemblyDetails);
                            setAssemblyDetails(newAssemblyDetails);
                            setLaborEditModalLoading(false);
                            setNewLaborModalOpen(false);
                        })
                        .catch(err => {
                            if (err.name === 'AbortError') {
                                console.log("Fetch aborted");
                            }
                            else if (err.response.status === 400) {
                                alert(err.response.data);
                            }
                            else {
                                alert(`Failed to add new labor entry. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                            }
                            setLaborEditModalLoading(false);
                        });
                }
                else {
                    const updateLaborBody = {
                        "hours": parseFloat(updateRequest.hours),
                        "rate": parseFloat(updateRequest.rate)
                    }
                    apiClient.patch(`/assembly/${assemblyDetails.id}/labor/${updateRequest.id}`, updateLaborBody)
                        .then(response => {
                            var editedLabor = assemblyDetails.labor[laborToEdit];
                            editedLabor.hours = parseFloat(updateRequest.hours);
                            editedLabor.rate = parseFloat(updateRequest.rate);
                            assemblyDetails.labor[laborToEdit] = editedLabor
                            var newAssemblyDetails = recalculateSubTotalAndTotal(assemblyDetails);
                            newAssemblyDetails = formatData(newAssemblyDetails);
                            setAssemblyDetails(newAssemblyDetails);
                            setLaborEditModalLoading(false);
                            setEditLaborModalOpen(false);
                        })
                        .catch(err => {
                            if (err.name === 'AbortError') {
                                console.log("Fetch aborted");
                            }
                            alert(`Failed to edit labor entry. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                            setDeleteModalLoading(false);
                        });
                }
                break;
            case "Engineering":
                setEngineeringEditModalLoading(true);
                if (engineeringToEdit === null) {
                    const newEngineeringBody = {
                        "type": parseInt(EngineeringValueToIdMappings[updateRequest.typeString]),
                        "hours": parseFloat(updateRequest.hours),
                        "rate": parseFloat(updateRequest.rate)
                    }

                    apiClient.post(`/assembly/${assemblyDetails.id}/engineering`, newEngineeringBody)
                        .then(response => {
                            response.data.typeString = updateRequest.typeString;
                            assemblyDetails.engineering = [...assemblyDetails.engineering, response.data];
                            var newAssemblyDetails = recalculateSubTotalAndTotal(assemblyDetails);
                            newAssemblyDetails = formatData(newAssemblyDetails);
                            setAssemblyDetails(newAssemblyDetails);
                            setEngineeringEditModalLoading(false);
                            setNewEngineeringModalOpen(false);
                        })
                        .catch(err => {
                            if (err.name === 'AbortError') {
                                console.log("Fetch aborted");
                            }
                            else if (err.response.status === 400) {
                                alert(err.response.data);
                            }
                            else {
                                alert(`Failed to add new engineering entry. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                            }
                            setEngineeringEditModalLoading(false);
                        });

                }
                else {
                    const updateEngineeringBody = {
                        "hours": parseFloat(updateRequest.hours),
                        "rate": parseFloat(updateRequest.rate)
                    }
                    apiClient.patch(`/assembly/${assemblyDetails.id}/engineering/${updateRequest.id}`, updateEngineeringBody)
                        .then(response => {
                            var editedEngineering = assemblyDetails.engineering[engineeringToEdit];
                            editedEngineering.hours = parseFloat(updateRequest.hours);
                            editedEngineering.rate = parseFloat(updateRequest.rate);
                            assemblyDetails.engineering[engineeringToEdit] = editedEngineering
                            var newAssemblyDetails = recalculateSubTotalAndTotal(assemblyDetails);
                            newAssemblyDetails = formatData(newAssemblyDetails);
                            setAssemblyDetails(newAssemblyDetails);
                            setEngineeringEditModalLoading(false);
                            setEditEngineeringModalOpen(false);
                        })
                        .catch(err => {
                            if (err.name === 'AbortError') {
                                console.log("Fetch aborted");
                            }
                            alert(`Failed to edit engineering entry. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                            setDeleteModalLoading(false);
                        });
                }
                break;
            case "Machine Shop":
                setMachiningEditModalLoading(true);
                if (machiningToEdit === null) {
                    const newMachiningBody = {
                        "type": parseInt(MachineShopValueToIdMappings[updateRequest.typeString]),
                        "hours": parseFloat(updateRequest.hours),
                        "rate": parseFloat(updateRequest.rate)
                    }

                    apiClient.post(`/assembly/${assemblyDetails.id}/machining`, newMachiningBody)
                        .then(response => {
                            response.data.typeString = updateRequest.typeString;
                            assemblyDetails.machining = [...assemblyDetails.machining, response.data];
                            var newAssemblyDetails = recalculateSubTotalAndTotal(assemblyDetails);
                            newAssemblyDetails = formatData(newAssemblyDetails);
                            setAssemblyDetails(newAssemblyDetails);
                            setMachiningEditModalLoading(false);
                            setNewMachiningModalOpen(false);
                        })
                        .catch(err => {
                            if (err.name === 'AbortError') {
                                console.log("Fetch aborted");
                            }
                            else if (err.response.status === 400) {
                                alert(err.response.data);
                            }
                            else {
                                alert(`Failed to add new machining entry. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                            }
                            setMachiningEditModalLoading(false);
                        });
                }
                else {
                    const updateMachiningBody = {
                        "hours": parseFloat(updateRequest.hours),
                        "rate": parseFloat(updateRequest.rate)
                    }
                    apiClient.patch(`/assembly/${assemblyDetails.id}/machining/${updateRequest.id}`, updateMachiningBody)
                        .then(response => {
                            var editedMachining = assemblyDetails.machining[machiningToEdit];
                            editedMachining.hours = parseFloat(updateRequest.hours);
                            editedMachining.rate = parseFloat(updateRequest.rate);
                            assemblyDetails.machining[machiningToEdit] = editedMachining
                            var newAssemblyDetails = recalculateSubTotalAndTotal(assemblyDetails);
                            newAssemblyDetails = formatData(newAssemblyDetails);
                            setAssemblyDetails(newAssemblyDetails);
                            setMachiningEditModalLoading(false);
                            setEditMachiningModalOpen(false);
                        })
                        .catch(err => {
                            if (err.name === 'AbortError') {
                                console.log("Fetch aborted");
                            }
                            alert(`Failed to edit machine shop entry. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                            setDeleteModalLoading(false);
                        });
                }
                break;
            default:
                break;
        }
    }


    const handleSendDeleteLaborRequest = (targetLaborId) => {
        setDeleteModalLoading(true);

        apiClient.delete(`/assembly/${assemblyDetails.id}/labor/${targetLaborId}`)
            .then(response => {
                setLaborToDelete(null);
                var newLaborEntries = assemblyDetails.labor.filter((currLabor, idx) => currLabor.id !== targetLaborId)
                var newAssemblyDetails = {
                    ...assemblyDetails,
                    ["labor"]: newLaborEntries
                }
                newAssemblyDetails = recalculateSubTotalAndTotal(newAssemblyDetails);
                newAssemblyDetails = formatData(newAssemblyDetails);
                setAssemblyDetails(newAssemblyDetails);
                setDeleteModalLoading(false);
                setDeleteLaborModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete labor entry. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleSendDeleteEngineeringRequest = (targetEngineeringId) => {
        setDeleteModalLoading(true);

        apiClient.delete(`/assembly/${assemblyDetails.id}/engineering/${targetEngineeringId}`)
            .then(response => {
                setEngineeringToDelete(null);
                var newEngineeringEntries = assemblyDetails.engineering.filter((currEngineering, idx) => currEngineering.id !== targetEngineeringId)
                assemblyDetails.engineering = newEngineeringEntries;
                var newAssemblyDetails = recalculateSubTotalAndTotal(assemblyDetails);
                newAssemblyDetails = formatData(newAssemblyDetails);
                setAssemblyDetails(newAssemblyDetails);
                setDeleteModalLoading(false);
                setDeleteEngineeringModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete engineering entry. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleSendDeleteMachiningRequest = (targetMachiningId) => {
        setDeleteModalLoading(true);

        apiClient.delete(`/assembly/${assemblyDetails.id}/machining/${targetMachiningId}`)
            .then(response => {
                setMachiningToDelete(null);
                var newMachiningEntries = assemblyDetails.machining.filter((currMachining, idx) => currMachining.id !== targetMachiningId)
                assemblyDetails.machining = newMachiningEntries;
                var newAssemblyDetails = recalculateSubTotalAndTotal(assemblyDetails);
                newAssemblyDetails = formatData(newAssemblyDetails);
                setAssemblyDetails(newAssemblyDetails);
                setDeleteModalLoading(false);
                setDeleteMachiningModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete machining entry. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleAddEditPart = (partRequest) => {
        setPartEditModalLoading(true);

        if (partToEdit === null) {
            const newPartAssociationBody = {
                "partId": partRequest.id,
                "quantity": partRequest.quantity
            }

            apiClient.post(`/assembly/part/${assemblyDetails.id}`, newPartAssociationBody)
                .then(response => {
                    assemblyDetails.parts = [...assemblyDetails.parts, response.data]
                    recalculateSubTotalAndTotal(assemblyDetails);
                    setAssemblyDetails(assemblyDetails);
                    setPartEditModalLoading(false);
                    setAddEditPartModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    else if (err.response.status === 400) {
                        alert(err.response.data);
                    }
                    else {
                        alert(`Failed to add new part. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    }
                    setPartEditModalLoading(false);
                });
        }
        else {
            const updatedPartAssociationBody = {
                "partId": partRequest.id,
                "quantity": partRequest.quantity
            }

            apiClient.patch(`/assembly/part/${assemblyDetails.id}`, updatedPartAssociationBody)
                .then(response => {
                    var editedPart = assemblyDetails.parts[partToEdit];
                    editedPart.quantity = parseFloat(partRequest.quantity);
                    editedPart.total = parseFloat(response.data.total);
                    assemblyDetails.parts[partToEdit] = editedPart;
                    recalculateSubTotalAndTotal(assemblyDetails);
                    setAssemblyDetails(assemblyDetails);
                    setPartEditModalLoading(false);
                    setAddEditPartModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to update existing part. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setPartEditModalLoading(false);
                });
        }
    }

    const handleSendDeletePartRequest = (targetPartId) => {
        setDeleteModalLoading(true);

        apiClient.delete(`/assembly/part/${assemblyDetails.id}/${targetPartId}`)
            .then(response => {
                setPartToDelete(null);
                var newPartEntries = assemblyDetails.parts.filter((currPart, idx) => currPart.id !== targetPartId)
                var newAssemblyDetails = {
                    ...assemblyDetails,
                    ["parts"]: newPartEntries
                };
                newAssemblyDetails = recalculateSubTotalAndTotal(newAssemblyDetails);
                newAssemblyDetails = formatData(newAssemblyDetails);
                setAssemblyDetails(newAssemblyDetails);
                setDeleteModalLoading(false);
                setDeletePartModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete part. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleAddEditSubAssembly = (subAssemblyRequest) => {
        setSubAssemblyEditModalLoading(true);

        if (subAssemblyToEdit === null) {
            const newSubAssemblyAssociationBody = {
                "subAssemblyId": subAssemblyRequest.id,
                "quantity": subAssemblyRequest.quantity
            }

            apiClient.post(`/assembly/subassembly/${assemblyDetails.id}`, newSubAssemblyAssociationBody)
                .then(response => {
                    var newSubAssemblyEntries = [
                        ...assemblyDetails.subAssemblies,
                        response.data
                    ]
                    var newAssemblyDetails = {
                        ...assemblyDetails,
                        ["subAssemblies"]: newSubAssemblyEntries
                    }
                    newAssemblyDetails = recalculateSubTotalAndTotal(newAssemblyDetails);
                    newAssemblyDetails = formatData(newAssemblyDetails);
                    setAssemblyDetails(newAssemblyDetails);
                    setSubAssemblyEditModalLoading(false);
                    setAddEditSubAssemblyModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    else if (err.response.status === 400) {
                        alert(err.response.data);
                    }
                    else {
                        alert(`Failed to add new Sub-Assembly. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    }
                    setSubAssemblyEditModalLoading(false);
                });
        }
        else {
            const updatedSubAssemblyAssociationBody = {
                "subAssemblyId": subAssemblyRequest.id,
                "quantity": subAssemblyRequest.quantity
            }

            apiClient.patch(`/assembly/subassembly/${assemblyDetails.id}`, updatedSubAssemblyAssociationBody)
                .then(response => {
                    var editedSubAssembly = assemblyDetails.subAssemblies[subAssemblyToEdit];
                    editedSubAssembly.quantity = parseFloat(subAssemblyRequest.quantity);
                    editedSubAssembly.total = parseFloat(subAssemblyRequest.quantity * editedSubAssembly.unitPrice);
                    assemblyDetails.subAssemblies[subAssemblyToEdit] = editedSubAssembly;
                    var newAssemblyDetails = recalculateSubTotalAndTotal(assemblyDetails);
                    newAssemblyDetails = formatData(newAssemblyDetails);
                    setAssemblyDetails(newAssemblyDetails);
                    setSubAssemblyEditModalLoading(false);
                    setAddEditSubAssemblyModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to update existing Sub-Assembly. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setDeleteModalLoading(false);
                });
        }
    }

    const handleSendDeleteSubAssemblyRequest = (targetSubAssemblyId) => {
        setDeleteModalLoading(true);

        apiClient.delete(`/assembly/subassembly/${assemblyDetails.id}/${targetSubAssemblyId}`)
            .then(response => {
                setSubAssemblyToDelete(null);
                var newSubAssemblyEntries = assemblyDetails.subAssemblies.filter((currSubAssembly, idx) => currSubAssembly.id !== targetSubAssemblyId)
                assemblyDetails.subAssemblies = newSubAssemblyEntries;
                var newAssemblyDetails = recalculateSubTotalAndTotal(assemblyDetails);
                newAssemblyDetails = formatData(newAssemblyDetails);
                setAssemblyDetails(newAssemblyDetails);
                setDeleteModalLoading(false);
                setDeleteSubAssemblyModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete sub-assembly. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleAddEditCustomTag = (customTag) => {
        setCustomTagEditModalLoading(true);
        if (customTagToEdit === null) {
            const newTag = {
                [customTag.key]: customTag.value
            }

            apiClient.post(`/assembly/${assemblyDetails.id}/custom-tags`, newTag)
                .then(response => {
                    assemblyDetails.customTags = [...assemblyDetails.customTags, response.data];
                    setAssemblyDetails({
                        ...assemblyDetails,
                        ["customTags"]: [...assemblyDetails.customTags]
                    });
                    setCustomTagEditModalLoading(false);
                    setAddEditCustomTagModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    else if (err.response.status === 400) {
                        alert(err.response.data);
                    }
                    else {
                        alert(`Failed to add new tag entry. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    }
                    setCustomTagEditModalLoading(false);
                });
        }
        else {
            const updatedTag = {
                [customTag.key]: customTag.value
            }

            apiClient.patch(`/assembly/${assemblyDetails.id}/custom-tags/${customTag.id}`, updatedTag)
                .then(response => {
                    var editedCustomTag = assemblyDetails.customTags[customTagToEdit];
                    editedCustomTag.key = customTag.key;
                    editedCustomTag.value = customTag.value;
                    assemblyDetails.customTags[customTagToEdit] = editedCustomTag;
                    setAssemblyDetails({
                        ...assemblyDetails,
                        ["customTags"]: [...assemblyDetails.customTags]
                    });
                    setCustomTagEditModalLoading(false);
                    setAddEditCustomTagModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    else if (err.response.status === 400) {
                        alert(err.response.data);
                    }
                    else {
                        alert(`Failed to add new tag entry. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    }
                    setCustomTagEditModalLoading(false);
                });
        }
    }

    const handleDeleteCustomTag = (customTagId) => {
        const customTag = assemblyDetails.customTags.filter((currCustomTag, idx) => currCustomTag.id === customTagId)[0];
        setCustomTagToDelete(customTag);
        setDeleteCustomTagModalOpen(true);
    }

    const handleSendDeleteCustomTagRequest = (targetCustomTagId) => {
        setDeleteModalLoading(true);
        apiClient.delete(`/assembly/${assemblyDetails.id}/custom-tags/${targetCustomTagId}`)
            .then(response => {
                setCustomTagToDelete(null);
                var newCustomTagEntries = assemblyDetails.customTags.filter((currCustomTag, idx) => currCustomTag.id !== targetCustomTagId);
                assemblyDetails.customTags = newCustomTagEntries;
                setAssemblyDetails({
                    ...assemblyDetails,
                    ["customTags"]: [...assemblyDetails.customTags]
                });
                setDeleteModalLoading(false);
                setDeleteCustomTagModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete tag. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    return (
        <div className="assembly-detail">
            {isPending && <p>Loading information...</p>}
            {!isPending && error !== null &&
                <div className="error">
                    {error}
                </div>
            }
            {!isPending && error === null &&
                <div className="assembly-information">
                    <h1>Assembly Information</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="flex-parent-element">
                            <div className="form-group flex-child-element">
                                <label htmlFor="assemblyNumber">Assembly Number</label>
                                <input name="assemblyNumber"
                                    value={assemblyDetails.assemblyNumber}
                                    onChange={handleFormUpdate}
                                />
                                <label htmlFor="assemblyDescription">Assembly Description</label>
                                <textarea name="assemblyDescription"
                                    value={assemblyDetails.assemblyDescription}
                                    onChange={handleFormUpdate}
                                />
                            </div>
                            <div style={{ marginBottom: "15px" }} className="flex-child-element">
                                <label htmlFor="customTags">Specifications</label>
                                <NonPaginatedTable
                                    data={assemblyDetails.customTags}
                                    columns={CustomTagTableHeaders}
                                    deleteRowFunction={handleDeleteCustomTag}
                                    editRowFunction={handleCustomTagEdit}
                                    initialState={{}}
                                    actions={['ModalEdit', 'ModalDelete']}
                                    hasFooters={false}
                                    renderHeaders={false}
                                />
                                <button className="btn" onClick={() => setAddEditCustomTagModalOpen(true)} type="button">Add New Spec</button>
                            </div>
                            <div className="form-group flex-child-element flex-child-element-center">
                                <div>
                                    <label htmlFor="subTotal" style={{ fontWeight: "bold" }}>Sub Total:{"\t"}</label>
                                    <input
                                        name="subTotal"
                                        value={formatMoney(assemblyDetails.subTotal)}
                                        readOnly
                                        className="readonly-input"
                                    />
                                </div>
                                <div style={{ paddingTop: "8px" }}>
                                    <label htmlFor="contingencyPercentageString">Contingency Percentage: </label>
                                    <input
                                        name="contingencyPercentageString"
                                        value={assemblyDetails.contingencyPercentageString}
                                        onChange={handleFormUpdate}
                                        style={{ width: "65px", textAlign: "center" }}
                                    />
                                </div>
                                {!isPending && validationErrors.hasOwnProperty('contingencyPercentage') &&
                                    <div className="error">
                                        {validationErrors['contingencyPercentage']}
                                    </div>
                                }
                                <div style={{ paddingTop: "8px" }}>
                                    <label htmlFor="profitMarginPercentageString">Profit Margin Percentage: </label>
                                    <input
                                        name="profitMarginPercentageString"
                                        value={assemblyDetails.profitMarginPercentageString}
                                        onChange={handleFormUpdate}
                                        style={{ width: "65px", textAlign: "center" }}
                                    />
                                </div>
                                {!isPending && validationErrors.hasOwnProperty('profitMarginPercentage') &&
                                    <div className="error">
                                        {validationErrors['profitMarginPercentage']}
                                    </div>
                                }
                                <div style={{ paddingTop: "8px" }}>
                                    <label htmlFor="total" style={{ fontWeight: "bold" }}>Total:{"\t"}</label>
                                    <input
                                        name="total"
                                        value={formatMoney(assemblyDetails.total)}
                                        readOnly 
                                        className="readonly-input"
                                    />
                                </div>
                            </div>
                        </div>
                        {pendingChanges && !sendingUpdate &&
                            <div>
                                <button className="btn" type="submit">Submit Changes</button>
                            </div>
                        }
                        {pendingChanges && sendingUpdate && <button className="btn" type="submit" disabled>Submitting Changes...</button>}
                    </form>
                    <h2 style={{ paddingTop: "25px" }}>Man Hours</h2>
                    <div className="flex-parent-element">
                        {!isPending && (error === null) &&
                            <div className="flex-child-element">
                                <h3>Labor</h3>
                                <NonPaginatedTable
                                    data={assemblyDetails.labor}
                                    columns={GetHeadersForSmallTables('Labor', calculateSum(assemblyDetails.labor, 'hours'), calculateSum(assemblyDetails.labor, 'total'))}
                                    deleteRowFunction={handleLaborDeleteRow}
                                    editRowFunction={handleLaborEdit}
                                    initialState={{
                                        sortBy: [{ id: "type", desc: false }],
                                        hiddenColumns: ["type"]
                                    }}
                                    actions={['ModalEdit', 'ModalDelete']}
                                />
                                <button className="btn" onClick={() => setNewLaborModalOpen(true)}>Add New Labor Entry</button>
                            </div>
                        }
                        {!isPending && (error === null) &&
                            <div className="flex-child-element">
                                <h3>Engineering</h3>
                                <NonPaginatedTable
                                    data={assemblyDetails.engineering}
                                    columns={GetHeadersForSmallTables('Engineering', calculateSum(assemblyDetails.engineering, 'hours'), calculateSum(assemblyDetails.engineering, 'total'))}
                                    deleteRowFunction={handleEngineeringDeleteRow}
                                    editRowFunction={handleEngineeringEdit}
                                    initialState={{
                                        sortBy: [{ id: "type", desc: false }],
                                        hiddenColumns: ["type"]
                                    }}
                                    actions={['ModalEdit', 'ModalDelete']}
                                />
                                <button className="btn" onClick={() => setNewEngineeringModalOpen(true)}>Add New Engineering Entry</button>
                            </div>
                        }
                    </div>
                    {!isPending && (error === null) &&
                        <div>
                            <h3>Machining</h3>
                            <NonPaginatedTable
                                data={assemblyDetails.machining}
                                columns={GetHeadersForSmallTables('Machine Shop', calculateSum(assemblyDetails.machining, 'hours'), calculateSum(assemblyDetails.machining, 'total'))}
                                deleteRowFunction={handleMachiningDeleteRow}
                                editRowFunction={handleMachiningEdit}
                                initialState={{
                                    sortBy: [{ id: "type", desc: false }],
                                    hiddenColumns: ["type"]
                                }}
                                actions={['ModalEdit', 'ModalDelete']}
                            />
                            <button className="btn" onClick={() => setNewMachiningModalOpen(true)}>Add New Machining Entry</button>
                        </div>
                    }
                    <h2 style={{ paddingTop: "25px" }}>Parts and Sub-Assemblies</h2>
                    <div className="flex-parent-element">
                        {!isPending && (error === null) && assemblyDetails.parts.length > 0 &&
                            <div className="flex-child-element">
                                <h3>Parts</h3>
                                <Table
                                    data={assemblyDetails.parts}
                                    columns={GetPartTableHeadersForAssembly(calculateSum(assemblyDetails.parts, "total"))}
                                    deleteRowFunction={handlePartDeleteRow}
                                    editRowFunction={handlePartEdit}
                                    initialState={{ sortBy: [{ id: "partName", desc: false }] }}
                                    actions={['ModalEdit', 'ModalDelete']}
                                    hasFooters={true}
                                />
                                <button className="btn" onClick={() => setAddEditPartModalOpen(true)}>Add New Part</button>
                            </div>
                        }
                        {!isPending && (error === null) && assemblyDetails.parts.length === 0 &&
                            <div className="flex-child-element">
                                <h3>Parts</h3>
                                <button className="btn" onClick={() => setAddEditPartModalOpen(true)}>Add New Part</button>
                            </div>
                        }
                        {!isPending && (error === null) && assemblyDetails.subAssemblies.length > 0 &&
                            <div className="flex-child-element">
                                <h3>Sub-Assemblies</h3>
                                <Table
                                    data={assemblyDetails.subAssemblies}
                                    columns={GetSubAssemblyTableHeadersForSubAssembly(assemblyDetails.subAssemblyTotal)}
                                    deleteRowFunction={handleSubAssemblyDeleteRow}
                                    editRowFunction={handleSubAssemblyEdit}
                                    initialState={{ sortBy: [{ id: "assemblyNumber", desc: false }] }}
                                    actions={['ModalEdit', 'ModalDelete', 'LinkToDetailPage']}
                                    detailPagePrefix="/assembly"
                                    hasFooters={true}
                                />
                                <button className="btn" onClick={() => setAddEditSubAssemblyModalOpen(true)}>Add New Sub-Assembly</button>
                            </div>
                        }
                        {!isPending && (error === null) && assemblyDetails.subAssemblies.length === 0 &&
                            <div className="flex-child-element">
                                <h3>Sub-Assemblies</h3>
                                <button className="btn" onClick={() => setAddEditSubAssemblyModalOpen(true)}>Add New Sub-Assembly</button>
                            </div>
                        }
                    </div>
                </div>
            }
            {!isPending && error !== null &&
                <div className="error">
                    {error}
                </div>
            }
            {!isPending && (error === null) && editLaborModalOpen &&
                <AddEditLaborEngineeringMachiningModal
                    closeModal={() => {
                        setLaborToEdit(null);
                    }}
                    onModalSubmit={handleChangingLaborEngineeringMachining}
                    defaultValue={laborToEdit !== null && assemblyDetails.labor[laborToEdit]}
                    modalSubmitLoading={laborEditModalLoading}
                    type="Labor"
                    subTypeOptions={Object.keys(LaborValueToIdMappings)}
                    cancelFunction={() => {setEditLaborModalOpen(false)}}
                />
            }
            {!isPending && (error === null) && newLaborModalOpen &&
                <AddEditLaborEngineeringMachiningModal
                    closeModal={() => {}}
                    onModalSubmit={handleChangingLaborEngineeringMachining}
                    defaultValue={null}
                    modalSubmitLoading={laborEditModalLoading}
                    type="Labor"
                    subTypeOptions={Object.keys(LaborValueToIdMappings)}
                    newEntry={true}
                    cancelFunction={() => setNewLaborModalOpen(false)}
                />
            }
            {!isPending && (error === null) && editEngineeringModalOpen &&
                <AddEditLaborEngineeringMachiningModal
                    closeModal={() => {
                        setEngineeringToEdit(null);
                    }}
                    onModalSubmit={handleChangingLaborEngineeringMachining}
                    defaultValue={engineeringToEdit !== null && assemblyDetails.engineering[engineeringToEdit]}
                    modalSubmitLoading={engineeringEditModalLoading}
                    type="Engineering"
                    subTypeOptions={Object.keys(EngineeringValueToIdMappings)}
                    cancelFunction={() => setEditEngineeringModalOpen(false)}
                />
            }
            {!isPending && (error === null) && newEngineeringModalOpen &&
                <AddEditLaborEngineeringMachiningModal
                    closeModal={() => {}}
                    onModalSubmit={handleChangingLaborEngineeringMachining}
                    defaultValue={null}
                    modalSubmitLoading={engineeringEditModalLoading}
                    type="Engineering"
                    subTypeOptions={Object.keys(EngineeringValueToIdMappings)}
                    newEntry={true}
                    cancelFunction={() => setNewEngineeringModalOpen(false)}
                />
            }
            {!isPending && (error === null) && editMachiningModalOpen &&
                <AddEditLaborEngineeringMachiningModal
                    closeModal={() => {
                        setMachiningToEdit(null);
                    }}
                    onModalSubmit={handleChangingLaborEngineeringMachining}
                    defaultValue={machiningToEdit !== null && assemblyDetails.machining[machiningToEdit]}
                    modalSubmitLoading={machiningEditModalLoading}
                    type="Machine Shop"
                    subTypeOptions={Object.keys(MachineShopValueToIdMappings)}
                    cancelFunction={() => setEditMachiningModalOpen(false)}
                />
            }
            {!isPending && (error === null) && newMachiningModalOpen &&
                <AddEditLaborEngineeringMachiningModal
                    closeModal={() => {}}
                    onModalSubmit={handleChangingLaborEngineeringMachining}
                    defaultValue={null}
                    modalSubmitLoading={machiningEditModalLoading}
                    type="Machine Shop"
                    subTypeOptions={Object.keys(MachineShopValueToIdMappings)}
                    newEntry={true}
                    cancelFunction={() => setNewMachiningModalOpen(false)}
                />
            }
            {!isPending && (error === null) && deleteLaborModalOpen &&
                <DeleteEntityModal
                    closeModal={() => {
                        setLaborToDelete(null);
                    }}
                    onModalSubmit={handleSendDeleteLaborRequest}
                    entityType="Labor"
                    currentEntity={laborToDelete !== null && laborToDelete}
                    currentEntityString={laborToDelete !== null && laborToDelete.typeString}
                    modalSubmitLoading={deleteModalLoading}
                    cancelFunction={() => setDeleteLaborModalOpen(false)}
                />
            }
            {!isPending && (error === null) && deleteEngineeringModalOpen &&
                <DeleteEntityModal
                    closeModal={() => {
                        setEngineeringToDelete(null);
                    }}
                    onModalSubmit={handleSendDeleteEngineeringRequest}
                    entityType="Engineering"
                    currentEntity={engineeringToDelete !== null && engineeringToDelete}
                    currentEntityString={engineeringToDelete !== null && engineeringToDelete.typeString}
                    modalSubmitLoading={deleteModalLoading}
                    cancelFunction={() => setDeleteEngineeringModalOpen(false)}
                />
            }
            {!isPending && (error === null) && deleteMachiningModalOpen &&
                <DeleteEntityModal
                    closeModal={() => {
                        setMachiningToDelete(null);
                    }}
                    onModalSubmit={handleSendDeleteMachiningRequest}
                    entityType="Machine Shop"
                    currentEntity={machiningToDelete !== null && machiningToDelete}
                    currentEntityString={machiningToDelete !== null && machiningToDelete.typeString}
                    modalSubmitLoading={deleteModalLoading}
                    cancelFunction={() => setDeleteMachiningModalOpen(false)}
                />
            }
            {!isPending && (error === null) && addEditPartModalOpen &&
                <AddEditPartForAssembly
                    closeModal={() => {
                        setPartToEdit(null);
                    }}
                    onModalSubmit={handleAddEditPart}
                    defaultValue={partToEdit !== null && assemblyDetails.parts[partToEdit]}
                    modalSubmitLoading={partEditModalLoading}
                    parts={parts}
                    isPending={isPendingParts}
                    error={errorParts}
                    cancelFunction={() => setAddEditPartModalOpen(false)}
                />
            }
            {!isPending && (error === null) && deletePartModalOpen &&
                <DeleteEntityModal
                    closeModal={() => {
                        setPartToDelete(null);
                    }}
                    onModalSubmit={handleSendDeletePartRequest}
                    entityType="Part"
                    currentEntity={partToDelete !== null && partToDelete}
                    currentEntityString={partToDelete !== null && partToDelete.partName}
                    modalSubmitLoading={deleteModalLoading}
                    cancelFunction={() => setDeletePartModalOpen(false)}
                />
            }
            {!isPending && (error === null) && addEditSubAssemblyModalOpen &&
                <AddEditAssembly
                    closeModal={() => {
                        setSubAssemblyToEdit(null);
                    }}
                    onModalSubmit={handleAddEditSubAssembly}
                    defaultValue={subAssemblyToEdit !== null && assemblyDetails.subAssemblies[subAssemblyToEdit]}
                    modalSubmitLoading={subAssemblyEditModalLoading}
                    assemblies={assemblies}
                    isPending={isPendingSubAssemblies}
                    error={errorSubAssemblies}
                    cancelFunction={() => setAddEditSubAssemblyModalOpen(false)}
                    addingAssemblyToQuote={false}
                    assemblyToEditObject={subAssemblyToEdit === null ? null : assemblyDetails.subAssemblies[subAssemblyToEdit] }
                />
            }
            {!isPending && (error === null) && deleteSubAssemblyModalOpen &&
                <DeleteEntityModal
                    closeModal={() => {
                        setSubAssemblyToDelete(null);
                    }}
                    onModalSubmit={handleSendDeleteSubAssemblyRequest}
                    entityType="Sub-Assembly"
                    currentEntity={subAssemblyToDelete !== null && subAssemblyToDelete}
                    currentEntityString={subAssemblyToDelete !== null && subAssemblyToDelete.assemblyNumber}
                    modalSubmitLoading={deleteModalLoading}
                    cancelFunction={() => setDeleteSubAssemblyModalOpen(false)}
                />
            }

            {!isPending && (error === null) && addEditCustomTagModalOpen &&
                <AddEditCustomTagModal
                    closeModal={() => {
                        setCustomTagToEdit(null);
                    }}
                    onModalSubmit={handleAddEditCustomTag}
                    defaultValue={customTagToEdit !== null && assemblyDetails.customTags[customTagToEdit]}
                    modalSubmitLoading={customTagEditModalLoading}
                    cancelFunction={() => setAddEditCustomTagModalOpen(false)}
                    existingKeys={convertCustomTagListToDictList(existingCustomTags)}
                />
            }

            {!isPending && (error === null) && deleteCustomTagModalOpen &&
                <DeleteEntityModal
                    closeModal={() => {
                        setCustomTagToDelete(null);
                    }}
                    onModalSubmit={handleSendDeleteCustomTagRequest}
                    entityType="Assembly Tag"
                    currentEntity={customTagToDelete !== null && customTagToDelete}
                    currentEntityString={customTagToDelete !== null && customTagToDelete.key}
                    modalSubmitLoading={deleteModalLoading}
                    cancelFunction={() => setDeleteCustomTagModalOpen(false)}
                />
            }

        </div>
    );
}

export default AssemblyDetail;