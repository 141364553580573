import "../BaseModal.css"
import { AbbreviatedStates } from "../../../static-values/States";
import { useState } from "react";
import { ContactTableHeaders } from "../../../static-values/TableHeaders";
import Table from "../../Table/Table";
import AddEditContactModal from "../AddEditContactModal/AddEditContactModal";
import DeleteEntityModal from "../DeleteEntityModal/DeleteEntityModal";
import { EntityTypes } from "../../../static-values/EntityTypes";
import { Countries } from "../../../static-values/Countries";
import apiClient from "../../../services/apiClient";

const AddEditClientModal = ({ closeModal, onModalSubmit, defaultValue, modalSubmitLoading, clientList, setClientList, cancelFunction, readOnly = false }) => {
    const [formState, setFormState] = useState(defaultValue ||
    {
        name: "",
        addressLine1: "",
        addressLine2: "",
        addressCity: "",
        addressState: "AL",
        addressZipCode: "",
        addressCountry: "USA",
        contactFirstName: "",
        contactLastName: "",
        contactEmail: "",
        contactPhoneNumber: ""
    }
    );

    const [addEditContactModalOpen, setAddEditContactModalOpen] = useState(null);
    const [contactToEdit, setContactToEdit] = useState(null);
    const [addEditContactModalLoading, setAddEditContactModalLoading] = useState(null);

    const [contactDeleteModalOpen, setContactDeleteModalOpen] = useState(false);
    const [contactToDelete, setContactToDelete] = useState(null);
    const [deleteModalLoading, setDeleteModalLoading] = useState(null);

    const [clientInfoErrors, setClientInfoErrors] = useState("");
    const [addressInfoErrors, setAddressInfoErrors] = useState("");
    const [primaryContactInfoErrors, setPrimaryContactInfoErrors] = useState("");

    const validateForm = () => {
        if (!formState.hasOwnProperty('contacts') && !formState.hasOwnProperty('address') && formState.name && formState.addressLine1 && formState.addressCity && formState.addressState && formState.addressZipCode && formState.contactFirstName && formState.contactLastName && formState.contactEmail && formState.contactPhoneNumber) {
            setClientInfoErrors("");
            setPrimaryContactInfoErrors("");
            return true;
        }

        if (formState.hasOwnProperty('contacts') && formState.hasOwnProperty('address') && formState.name) {
            setClientInfoErrors("");
            setPrimaryContactInfoErrors("");
            return true;
        }

        const clientInfoReqFields = ['name'];
        const clientInfoReqFieldsToReadbleName = {
            'name': "Name"
        };
        const primaryContactInfoReqFields = ['contactFirstName', 'contactLastName', 'contactEmail', 'contactPhoneNumber'];
        const primaryContactInfoFieldNameToReadableName = {
            'contactFirstName': "First Name",
            'contactLastName': "Last Name",
            'contactEmail': "Email",
            'contactPhoneNumber': "Phone Number",
        };
        const addressInfoReqFields = ['addressLine1', 'addressState', 'addressCity', 'addressZipCode']
        const addressInfoFieldNameToReadableName = {
            'addressLine1': "Line 1",
            'addressState': "State",
            'addressCity': "City",
            'addressZipCode': "Zip Code"
        };

        let clientInfoErrorFields = []
        let primaryContactInfoErrorFields = []
        let addressInfoErrorFields = []
        for (const [key, value] of Object.entries(formState)) {
            if (!value && clientInfoReqFields.includes(key)) clientInfoErrorFields.push(clientInfoReqFieldsToReadbleName[key]);
            if (!value && primaryContactInfoReqFields.includes(key)) primaryContactInfoErrorFields.push(primaryContactInfoFieldNameToReadableName[key]);
            if (!value && addressInfoReqFields.includes(key)) addressInfoErrorFields.push(addressInfoFieldNameToReadableName[key]);
        }

        setClientInfoErrors(clientInfoErrorFields.join(', '));
        setPrimaryContactInfoErrors(primaryContactInfoErrorFields.join(', '));
        setAddressInfoErrors(addressInfoErrorFields.join(', '))
        return false;
    }

    const addressRelatedFields = ["addressLine1", "addressLine2", "addressCity", "addressState", "addressZipCode", "addressCountry"]

    const handleFormUpdate = (e) => {
        if (addressRelatedFields.includes(e.target.name) && formState.hasOwnProperty('address')) {
            let field = e.target.name.replace("address", "").toLowerCase();
            if (field === "zipcode") field = "zipCode"
            let newAddress = {
                ...formState['address'],
                [field]: e.target.value
            }
            setFormState({
                ...formState,
                "address": newAddress
            })
            return;
        }
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }

    const handleContactEdit = (contactId) => {
        const contactPos = formState.contacts.map(contact => contact.id).indexOf(contactId);
        setContactToEdit(contactPos);
        setAddEditContactModalOpen(true);
    }

    const handleContactDelete = (targetContactId) => {
        const contact = formState.contacts.filter(currContact => currContact.id === targetContactId)[0];
        if (contact.isPrimary) {
            alert("You cannot delete the primary contact. Make another contact primary and try again");
        }
        else {
            setContactToDelete(contact);
            setContactDeleteModalOpen(true);
        }
    }

    const handleSendDeleteRequest = (targetContactId) => {
        let adjustedClient = formState;
        setDeleteModalLoading(true);

        apiClient.delete(`/contact/${targetContactId}`)
            .then(response => {
                setContactToDelete(null);
                adjustedClient.contacts = adjustedClient.contacts.filter(currContact => currContact.id !== targetContactId)
                setClientList(clientList.map((currClient) => {
                    if (adjustedClient.id !== currClient.id) return currClient;
                    return adjustedClient;
                }))
                setDeleteModalLoading(false);
                setContactDeleteModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete contact. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleAddEditContactSubmit = (newContact) => {
        setAddEditContactModalLoading(true);

        let adjustedClient = formState;
        if (contactToEdit == null) {
            const newContactBody = {
                "firstName": newContact.firstName,
                "lastName": newContact.lastName,
                "email": newContact.email,
                "phoneNumber": newContact.phoneNumber,
                "entityId": formState.id,
                "isPrimary": Boolean(newContact.isPrimary),
                "entity": EntityTypes.Client
            };
            apiClient.post('/contact', newContactBody)
                .then(contactResponse => {
                    if (contactResponse.data.isPrimary) {
                        adjustedClient.contacts = adjustedClient.contacts.map((currContact) => {
                            if (currContact.isPrimary) {
                                currContact.isPrimary = false;
                            }
                            return currContact;
                        })
                        adjustedClient.primaryContact = contactResponse.data;
                        adjustedClient.contacts = [contactResponse.data, ...adjustedClient.contacts];
                    }
                    else {
                        adjustedClient.contacts = [...adjustedClient.contacts, contactResponse.data];
                    }
                    setClientList(clientList.map((currClient) => {
                        if (adjustedClient.id !== currClient.id) return currClient;
                        return adjustedClient;
                    }))
                    setAddEditContactModalLoading(false);
                    setAddEditContactModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to create new contact. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setAddEditContactModalLoading(false);
                });
        }
        else {
            const updatedContactBody = {
                "firstName": newContact.firstName,
                "lastName": newContact.lastName,
                "email": newContact.email,
                "phoneNumber": newContact.phoneNumber,
                "isPrimary": Boolean(newContact.isPrimary)
            };

            apiClient.patch(`/contact/${newContact.id}`, updatedContactBody)
                .then(updatedContactResponse => {
                    // get the contacts that did not change
                    adjustedClient.contacts = adjustedClient.contacts.filter(currContact => {
                        if (currContact.id !== updatedContactResponse.data.id) return currContact;
                    })
                    if (updatedContactResponse.data.isPrimary) {
                        adjustedClient.contacts = adjustedClient.contacts.map((currContact) => {
                            if (currContact.isPrimary) {
                                currContact.isPrimary = false;
                            }
                            return currContact;
                        })
                        adjustedClient.primaryContact = updatedContactResponse.data;
                        adjustedClient.contacts = [updatedContactResponse.data, ...adjustedClient.contacts];
                    }
                    else {
                        adjustedClient.contacts = [...adjustedClient.contacts, updatedContactResponse.data];
                    }
                    setClientList(clientList.map((currClient) => {
                        if (adjustedClient.id !== currClient.id) return currClient;
                        return adjustedClient;
                    }))
                    setAddEditContactModalLoading(false);
                    setAddEditContactModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to edit contact. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setAddEditContactModalLoading(false);
                });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) return;
        onModalSubmit(formState);

        closeModal();
    }

    return (
        <div className="base-modal-container" onClick={(e) => {
            if (e.target.className === "base-modal-container") closeModal();
        }}>
            <div className="base-modal">
                <form onSubmit={handleSubmit}>
                    <h2>Client Info</h2>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            name="name"
                            value={formState.name}
                            onChange={handleFormUpdate}
                            readOnly={readOnly}
                            className={readOnly ? "readonly-input" : "input"}
                        />
                    </div>
                    {clientInfoErrors && <div className="error">{`Please include: ${clientInfoErrors}`}</div>}
                    <h2>Address Info</h2>
                    <div className="form-group">
                        <label htmlFor="addressLine1">Line 1</label>
                        <input
                            name="addressLine1"
                            value={formState.hasOwnProperty('address') ? formState.address.line1 : formState.addressLine1}
                            onChange={handleFormUpdate}
                            readOnly={readOnly}
                            className={readOnly ? "readonly-input" : "input"}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="addressLine2">Line 2</label>
                        <input
                            name="addressLine2"
                            value={formState.hasOwnProperty('address') ? formState.address.line2 : formState.addressLine2}
                            onChange={handleFormUpdate}
                            readOnly={readOnly}
                            className={readOnly ? "readonly-input" : "input"}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="addressCity">City</label>
                        <input
                            name="addressCity"
                            value={formState.hasOwnProperty('address') ? formState.address.city : formState.addressCity}
                            onChange={handleFormUpdate}
                            readOnly={readOnly}
                            className={readOnly ? "readonly-input" : "input"}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="addressState">State</label>
                        <select name="addressState" disabled={readOnly} value={formState.hasOwnProperty('address') ? formState.address.state : formState.addressState} onChange={handleFormUpdate} className={readOnly ? "readonly-input" : "input"}>
                            {AbbreviatedStates.map((state) => <option key={state} value={state}>{state}</option>)}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="addressZipCode">Zip Code</label>
                        <input
                            name="addressZipCode"
                            value={formState.hasOwnProperty('address') ? formState.address.zipCode : formState.addressZipCode}
                            onChange={handleFormUpdate}
                            readOnly={readOnly}
                            className={readOnly ? "readonly-input" : "input"}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="addressCountry">Country</label>
                        <select name="addressCountry" disabled={readOnly} value={formState.hasOwnProperty('address') ? formState.address.country : formState.addressCountry} onChange={handleFormUpdate} className={readOnly ? "readonly-input" : "input"}>
                            {Countries.map((country) => <option key={country} value={country}>{country}</option>)}
                        </select>
                    </div>
                    {addressInfoErrors && <div className="error">{`Please include: ${addressInfoErrors}`}</div>}
                    {!formState.hasOwnProperty('contacts') && <h2>Primary Contact Info</h2>}
                    {!formState.hasOwnProperty('contacts') &&
                        <div className="form-group">
                            <label htmlFor="contactFirstName">First Name</label>
                            <input name="contactFirstName" value={formState.contactFirstName} onChange={handleFormUpdate} />
                        </div>
                    }
                    {!formState.hasOwnProperty('contacts') &&
                        <div className="form-group">
                            <label htmlFor="contactLastName">Last Name</label>
                            <input name="contactLastName" value={formState.contactLastName} onChange={handleFormUpdate} />
                        </div>
                    }
                    {!formState.hasOwnProperty('contacts') &&
                        <div className="form-group">
                            <label htmlFor="contactEmail">Email</label>
                            <input name="contactEmail" value={formState.contactEmail} onChange={handleFormUpdate} />
                        </div>
                    }
                    {!formState.hasOwnProperty('contacts') &&
                        <div className="form-group">
                            <label htmlFor="contactPhoneNumber">Phone Number</label>
                            <input name="contactPhoneNumber" value={formState.contactPhoneNumber} onChange={handleFormUpdate} />
                        </div>
                    }
                    {primaryContactInfoErrors && <div className="error">{`Please include: ${primaryContactInfoErrors}`}</div>}
                    {!modalSubmitLoading && !readOnly &&
                        <div>
                            <button className="btn inline-btn" type="button" onClick={() => cancelFunction()}>Cancel</button>
                            <button className="btn" type="submit">Submit</button>
                        </div>
                    }
                    {modalSubmitLoading && !readOnly && <button disabled className="btn" type="submit">Submitting...</button>}
                </form>

                {formState.hasOwnProperty('contacts') &&
                    <div className="contacts-table">
                        <h2>Contacts</h2>
                        <Table
                            data={formState.contacts}
                            columns={ContactTableHeaders}
                            deleteRowFunction={handleContactDelete}
                            editRowFunction={handleContactEdit}
                            actions={readOnly ? [] : ['ModalEdit', 'ModalDelete']}
                        />
                        {!readOnly && <button className="btn" onClick={() => setAddEditContactModalOpen(true)}>Add New Contact</button>}
                        {contactDeleteModalOpen &&
                            <DeleteEntityModal
                                closeModal={() => {
                                    setContactToDelete(null);
                                }}
                                onModalSubmit={handleSendDeleteRequest}
                                entityType="Contact"
                                cancelFunction={() => {
                                    setContactDeleteModalOpen(false);
                                }}
                                currentEntity={contactToDelete !== null && contactToDelete}
                                currentEntityString={contactToDelete !== null && `${contactToDelete.firstName} ${contactToDelete.lastName}`} modalSubmitLoading={deleteModalLoading}
                            />
                        }
                    </div>
                }

            </div>
            {addEditContactModalOpen &&
                <AddEditContactModal
                    closeModal={() => {
                        setContactToEdit(null);
                    }}
                    onModalSubmit={handleAddEditContactSubmit}
                    cancelFunction={() => {
                        setAddEditContactModalOpen(false);
                    }}
                    defaultValue={contactToEdit !== null && formState.contacts[contactToEdit]}
                    modalSubmitLoading={addEditContactModalLoading}
                />
            }
        </div>

    );
}

export default AddEditClientModal;