import {swap} from "./../utils/utils"

export const LaborIdToValueMappings = {
    1: "Cutting",
    2: "Fabrication",
    3: "Paint",
    4: "Assembly",
    5: "Electrical",
    6: "Testing"
}

export const ProperLaborOrder = [
    "Cutting",
    "Fabrication",
    "Paint",
    "Assembly",
    "Electrical",
    "Testing"
]

export const LaborValueToIdMappings = swap(LaborIdToValueMappings);

export const EngineeringIdToValueMappings = {
    1: "BrainStorm/Concept Generation",
    2: "Concept Drawing",
    3: "Calculations",
    4: "3D Drawing",
    5: "BOM Generation",
    6: "Detailed Shop Drawings",
    7: "Shop Support"
}

export const EngineeringValueToIdMappings = swap(EngineeringIdToValueMappings);

export const MachineShopIdToValueMappings = {
    1: "Machining"
}

export const MachineShopValueToIdMappings = swap(MachineShopIdToValueMappings);

export const QuoteStatusIdToValueMappings = {
    0: "In Process",
    10: "Ready To Submit", 
    20: "Submitted",
    30: "Finalized"
}

export const QuoteStatusValueToIdMappings = swap(QuoteStatusIdToValueMappings);

export const WonLostIdToValueMappings = {
    0: "Won",
    1: "Lost",
    2: "N/A"
}

export const WonLostValueToIdMappings = swap(WonLostIdToValueMappings);

export const DefaultTermsOfPayment = [
    {
        "id": 1,
        "displayValue": "50/40/10",
        "fullValue": "50% with signed official order\n40% prior to shipment\n10% due within 30 days of receipt"
    },
    {
        "id": 2,
        "displayValue": "30/30/30/10",
        "fullValue": "30% with signed official order\n30% upon approved engineered drawings\n30% at shipment\n10% within 30 days of receipt"
    },
    {
        "id": 3,
        "displayValue": "COD (Cash on Delivery)",
        "fullValue": "COD (Cash on Delivery)"
    },
    {
        "id": 4,
        "displayValue": "Custom Terms",
        "fullValue": "Custom Terms"
    }
]