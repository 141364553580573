import { useState } from "react";
import "../BaseModal.css"


const AddEditLaborEngineeringMachiningModal = ({ closeModal, onModalSubmit, defaultValue, modalSubmitLoading, type, subTypeOptions, cancelFunction, newEntry = false }) => {
    const [formState, setFormState] = useState(defaultValue || 
        {
            "modalType": type,
            "typeString": subTypeOptions[0],
            "hours": 0,
            "rate": 0
        }
    );
    
    const [errors, setErrors] = useState("");

    const requiredFields = ['hours', 'rate'];

    const validateForm = () => {
        if (parseFloat(formState.hours) >= 0 && parseFloat(formState.rate) >= 0) {
            setErrors("");
            return true;
        }

        let errorFields = []
        for (const [key, value] of Object.entries(formState)) {
            if (!value && requiredFields.includes(key)) errorFields.push(key);
        }
        
        setErrors(errorFields.join(', '));
        return false;
    }

    const handleFormUpdate = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            formState.modalType = type;
            onModalSubmit(formState);
            closeModal();
        }

    }

    return (  
        <div className="base-modal-container" onClick={(e) => {
            if (e.target.className === "base-modal-container") closeModal();
        }}>
            <div className="base-modal">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="typeString">{type} Type</label>
                        {newEntry && 
                            <select name="typeString" value={formState.typeString} onChange={handleFormUpdate}>
                                {subTypeOptions.map((subType) => <option key={subType} value={subType}>{subType}</option>)}
                            </select>
                        }
                        {!newEntry && <input name="typeString" value={formState.typeString} onChange={handleFormUpdate} readOnly/>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="hours">Hours</label>
                        <input name="hours" value={formState.hours} onChange={handleFormUpdate} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="rate">Rate</label>
                        <input name="rate" value={formState.rate} onChange={handleFormUpdate} />
                    </div>
                    {errors && <div className="error">{`Please include: ${errors}`}</div>}
                    {!modalSubmitLoading &&
                        <div>
                            <button className="btn inline-btn" type="button" onClick={() => cancelFunction()}>Cancel</button>
                            <button className="btn" type="submit">Submit</button>
                        </div>
                    }
                    {modalSubmitLoading && <button disabled className="btn" type="submit">Submitting...</button>}
                </form>
            </div>
        </div>
    );
}
 
export default AddEditLaborEngineeringMachiningModal;