import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar">
            <h1>SMASH 2.0</h1>
            <div className="links">
                <Link to="/quotes">Quotes</Link>
                <Link to="/assemblies">Assemblies</Link>
                <Link to="/clients">Clients</Link>
                <Link to="/parts">Parts</Link>
                <Link to="/vendors">Vendors</Link>
                <Link to="/logout">Logout</Link>
            </div>
        </nav>
    );
}
export default Navbar;