import { useState } from "react";

import "../../BaseModal.css"

const NewAssemblyAssemblyInformationModal = ({ closeModal, onModalSubmit, defaultValue, cancelFunction = () => {} }) => {
    const [formState, setFormState] = useState(defaultValue || {
        "assemblyNumber": "",
        "assemblyDescription": "",
        "contingencyPercentageString": "0%",
        "profitMarginPercentageString": "0%",
    });

    const [errors, setErrors] = useState("");
    const [contigencyPercentageStringError, setContingencyPercentageStringError] = useState("");
    const [profitMarginPercentageStringError, setProfitMarginPercentageStringError] = useState("");
    const requiredFields = ["assemblyNumber", "assemblyDescription"];

    const validatePercentageString = (percentageString) => {
        const floatValue = percentageString.replace("%", "") / 100;

        if (floatValue > 1 || floatValue <= 0) return false;
        else return true;
    }

    const validateForm = () => {
        var percentageErrors = false;
        if (!validatePercentageString(formState.contingencyPercentageString)) {
            setContingencyPercentageStringError("Contingency Percentage must be less than 100% and greater than 0%");
            percentageErrors = true;
        }
        else {
            setContingencyPercentageStringError("");
            percentageErrors = false;
        }
        if (!validatePercentageString(formState.profitMarginPercentageString)) {
            setProfitMarginPercentageStringError("Profit Margin Percentage must be less than 100% and greater than 0%");
            percentageErrors = true;
        }
        else {
            setProfitMarginPercentageStringError("");
            percentageErrors = false;
        }
        if (formState.assemblyNumber !== "" && formState.assemblyDescription !== "" && !percentageErrors) {
            setContingencyPercentageStringError("");
            setProfitMarginPercentageStringError("");
            setErrors("");
            return true;
        }

        let errorFields = []
        for (const [key, value] of Object.entries(formState)) {
            if (!value && requiredFields.includes(key)) errorFields.push(key);
        }
        setErrors(errorFields.join(', '));
        return false;
    }

    const handleFormUpdate = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onModalSubmit(formState);
            closeModal();
        }
    }

    return (
        <div className="base-modal-container">
            <div className="base-modal">
                <h1>Add New Assembly</h1>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="assemblyNumber">Assembly Number</label>
                        <input style={{ width: "100%", textAlign: "center" }} name="assemblyNumber" value={formState.assemblyNumber} onChange={handleFormUpdate} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="assemblyDescription">Assembly Description</label>
                        <textarea rows={10} name="assemblyDescription" value={formState.assemblyDescription} onChange={handleFormUpdate} />
                    </div>
                    {errors && <div className="error">{`Please include: ${errors}`}</div>}
                    <div className="form-group">
                        <label htmlFor="contingencyPercentageString">Contingency Percentage</label>
                        <input className="percentage-input" name="contingencyPercentageString" value={formState.contingencyPercentageString} onChange={handleFormUpdate} />
                    </div>
                    {contigencyPercentageStringError && <div className="error">{contigencyPercentageStringError}</div>}
                    <div className="form-group">
                        <label htmlFor="profitMarginPercentageString">Profit Margin Percentage</label>
                        <input className="percentage-input" name="profitMarginPercentageString" value={formState.profitMarginPercentageString} onChange={handleFormUpdate} />
                    </div>
                    {profitMarginPercentageStringError && <div className="error">{profitMarginPercentageStringError}</div>}
                    <div>
                        <button className="btn inline-btn" type="button" onClick={() => cancelFunction()}>Cancel</button>
                        <button className="btn" type="submit">Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default NewAssemblyAssemblyInformationModal;