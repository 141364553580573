const phoneNumberRegex = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;

export const validateEmail = (email) => {
    return true;
}

export const validatePhoneNumber = (phoneNumber) => {
    if (phoneNumberRegex.test(phoneNumber)) return true;

    return false;
}