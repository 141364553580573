import { useState } from "react";
import { DefaultTermsOfPayment } from "../../../static-values/Mappings";
const ExportQuoteAsPdfModal = ({
    defaultValue,
    closeModal,
    onModalSubmit,
    modalSubmitLoading,
    cancelFunction
}) => {
    const [formState, setFormState] = useState(defaultValue || {
        "freightIncluded": true,
        "installationIncluded": true,
        "leadTime": "",
        "termsOfPaymentId": 1,
        "customTermsOfPayment": ""
    })
    const [termsOfPaymentErrors, setTermsOfPaymentErrors] = useState("");
    const [leadTimeErrors, setLeadTimeErrors] = useState("");
    const validateForm = () => {
        if ((parseInt(formState.termsOfPaymentId) === 4 && formState.customTermsOfPayment !== "") && formState.leadTime !== ""){
            setTermsOfPaymentErrors("");
            setLeadTimeErrors("");
            return true;
        }
        else if (formState.leadTime !== "") {
            setTermsOfPaymentErrors("");
            setLeadTimeErrors("");
            return true;
        }
        if (parseInt(formState.termsOfPaymentId) === 4 && formState.customTermsOfPayment === "") setTermsOfPaymentErrors("Please enter a custom terms of payment");
        else setTermsOfPaymentErrors("");
        if (formState.leadTime === "") setLeadTimeErrors("Please enter a lead time");
        else setLeadTimeErrors("");
        return false;
    }

    const handleFormUpdate = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onModalSubmit(formState);
            closeModal();
        }

    }

    return (
        <div className="base-modal-container">
            <div className="base-modal">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="freightIncluded">Freight Included?</label>
                        <select name="freightIncluded" value={formState.freightIncluded} onChange={handleFormUpdate}>
                            <option key={1} value={true}>Yes</option>
                            <option key={2} value={false}>No</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="installationIncluded">Installation Included?</label>
                        <select name="installationIncluded" value={formState.installationIncluded} onChange={handleFormUpdate}>
                            <option key={1} value={true}>Yes</option>
                            <option key={2} value={false}>No</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="leadTime">Lead Time</label>
                        <input
                            name="leadTime"
                            value={formState.leadTime}
                            onChange={handleFormUpdate}
                            style={{width: "45%"}}
                        />
                    </div>
                    {leadTimeErrors && <div className="error">{leadTimeErrors}</div>}
                    <div className="form-group">
                        <label htmlFor="termsOfPaymentId">Terms of Payment</label>
                        <select name="termsOfPaymentId" value={formState.termsOfPaymentId} onChange={handleFormUpdate}>
                            {
                                DefaultTermsOfPayment.map((currTerm, idx) => {
                                    return <option key={currTerm.id} value={currTerm.id}>{currTerm.displayValue}</option>
                                })
                            }
                        </select>
                    </div>
                    {parseInt(formState.termsOfPaymentId) === 4 &&
                        <div className="form-group">
                            <textarea
                                name="customTermsOfPayment"
                                value={formState.customTermsOfPayment}
                                onChange={handleFormUpdate}
                                rows={5}
                            />
                        </div>
                    }
                    {termsOfPaymentErrors && <div className="error">{termsOfPaymentErrors}</div>}
                    {!modalSubmitLoading &&
                        <div>
                            <button className="btn inline-btn" type="button" onClick={() => cancelFunction()}>Cancel</button>
                            <button className="btn" type="submit">Submit</button>
                        </div>
                    }
                    {modalSubmitLoading && <button disabled className="btn" type="submit">Submitting...</button>}
                </form>
            </div>
        </div>
    )
}

export default ExportQuoteAsPdfModal;