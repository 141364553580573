import { useState } from "react";
import { calculateSum } from "../../../../utils/utils";
import { useNavigate } from "react-router-dom";

const NewAssemblySummaryAndFinalize = ({ closeModal, onModalSubmit, defaultValue, modalSubmitLoading, backFunction = () => {}, cancelFunction = () => {} }) => {
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        onModalSubmit();
        closeModal();
    }

    return (
        <div className="base-modal-container">
            <div className="base-modal">
                <h1>Finalize New Assembly</h1>
                <form onSubmit={handleSubmit}>
                    <h3>Assembly Information</h3>
                    <div className="form-group">
                        <label htmlFor="assemblyNumber">Assembly Number</label>
                        <input name="assemblyNumber" value={defaultValue.assemblyNumber} readOnly />
                    </div>
                    <div className="form-group">
                        <label htmlFor="assemblyDescription">Assembly Description</label>
                        <textarea rows={10} name="assemblyDescription" value={defaultValue.assemblyDescription} readOnly />
                    </div>
                    <div className="form-group">
                        <label htmlFor="contingencyPercentageString">Contingency Percentage</label>
                        <input style={{ width: "65px", textAlign: "center" }} name="contingencyPercentageString" value={
                            defaultValue.contingencyPercentageString.includes("%") ? defaultValue.contingencyPercentageString : `${defaultValue.contingencyPercentageString}%`
                        } readOnly />
                    </div>
                    <div className="form-group">
                        <label htmlFor="profitMarginPercentageString">Profit Margin Percentage</label>
                        <input style={{ width: "65px", textAlign: "center" }} name="profitMarginPercentageString" value={
                            defaultValue.profitMarginPercentageString.includes("%") ? defaultValue.profitMarginPercentageString : `${defaultValue.profitMarginPercentageString}%`
                        } readOnly />
                    </div>
                    <h3>Man Hours</h3>
                    <div className="form-group">
                        <label htmlFor="totalLabor">Total Labor Hours</label>
                        <input name="totalLabor" value={calculateSum(defaultValue.labor, "hours")} readOnly />
                        <label htmlFor="totalEngineering">Total Engineering Hours</label>
                        <input name="totalEngineering" value={calculateSum(defaultValue.engineering, "hours")} readOnly />
                        <label htmlFor="totalMachining">Total Machining Hours</label>
                        <input name="totalMachining" value={calculateSum(defaultValue.machining, "hours")} readOnly />
                    </div>
                    <h3>Parts and Sub-Assemblies</h3>
                    <div className="form-group">
                        <label htmlFor="numDistinctParts">Number of Distinct Parts</label>
                        <input name="numDistinctParts" value={defaultValue.parts.length} readOnly />
                        <label htmlFor="numSubAssemblies">Number of Distinct Sub-Assemblies</label>
                        <input name="numSubAssemblies" value={defaultValue.subAssemblies.length} readOnly />
                    </div>
                    <div>
                        {!modalSubmitLoading && <button className="btn inline-btn" onClick={() => cancelFunction()} type="button">Cancel</button>}
                        {!modalSubmitLoading && <button className="btn inline-btn" onClick={() => backFunction()}>Back</button>}
                        {!modalSubmitLoading && <button className="btn inline-btn-end" type="submit">Submit</button>}
                        {modalSubmitLoading && <button disabled className="btn inline-btn-end" type="submit">Submitting...</button>}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default NewAssemblySummaryAndFinalize;