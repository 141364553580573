import useGet from "../../utils/hooks/useGet";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import "./Vendors.css";
import Table from "../Table/Table";
import { VendorTableHeaders } from "../../static-values/TableHeaders";
import AddEditVendorModal from "../Modals/AddEditVendorModal/AddEditVendorModal";
import DeleteEntityModal from "../Modals/DeleteEntityModal/DeleteEntityModal";
import apiClient from "../../services/apiClient";
import { testAuth } from "../../services/apiClient";

const Vendors = () => {
    const navigate = useNavigate();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [vendorToEdit, setVendorToEdit] = useState(null);
    const [editModalLoading, setEditModalLoading] = useState(false);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [vendorToDelete, setVendorToDelete] = useState(null);
    const [deleteModalLoading, setDeleteModalLoading] = useState(null);

    const handleVendorEdit = (vendorId) => {
        const vendorPos = vendors.map(vendor => vendor.id).indexOf(vendorId);
        setVendorToEdit(vendorPos);
        setEditModalOpen(true);
    }

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                setEditModalOpen(false);
            }
        };
        window.addEventListener('keydown', handleEsc);

        (async () => {
            const testAuthResult = await testAuth(apiClient);
            if (!testAuthResult) {
                navigate('/login');
            }
        })();

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [])

    const { data: vendors, setData: setVendors, isPending, error } = useGet("/vendor");

    const handleSendDeleteRequest = (targetVendorId) => {
        setDeleteModalLoading(true);

        apiClient.delete(`/vendor/${targetVendorId}`)
            .then(response => {
                setVendorToDelete(null);
                setVendors(vendors.filter((currVendor, idx) => currVendor.id !== targetVendorId));
                setDeleteModalLoading(false);
                setDeleteModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                else if (err.response.status === 400) {
                    alert(err.response.data);
                }
                else {
                    alert(`Failed to delete vendor. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                }
                setDeleteModalLoading(false);
            });
    }

    const handleDeleteRow = (targetVendorId) => {
        const vendor = vendors.filter((currVendor, idx) => currVendor.id === targetVendorId)[0];
        setVendorToDelete(vendor);
        setDeleteModalOpen(true);
    }

    const handleVendorModalSubmit = (newVendor) => {
        setEditModalLoading(true);

        // If a new vendor
        if (vendorToEdit === null) {
            const newVendorBody = {
                "name": newVendor.name,
                "city": newVendor.city,
                "state": newVendor.state,
                "contacts": [
                    {
                        "firstName": newVendor.contactFirstName,
                        "lastName": newVendor.contactLastName,
                        "email": newVendor.contactEmail,
                        "phoneNumber": newVendor.contactPhoneNumber,
                        "isPrimary": true
                    }
                ]
            }

            apiClient.post("/vendor", newVendorBody)
                .then(vendorResponse => {
                    setEditModalLoading(false);
                    setVendors([...vendors, vendorResponse.data]);
                    setEditModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to create new vendor. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setEditModalLoading(false);
                });
        }
        // If editing an existing vendor
        else {
            const updatedVendorBody = {
                "name": newVendor.name,
                "city": newVendor.city,
                "state": newVendor.state
            }
            apiClient.patch(`/vendor/${newVendor.id}`, updatedVendorBody)
                .then(updatedVendorResponse => {
                    setEditModalLoading(false);
                    setVendors(vendors.map((currVendor, idx) => {
                        if (idx !== vendorToEdit) return currVendor;
                        return updatedVendorResponse.data;
                    }));
                    setEditModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to edit new vendor. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setEditModalLoading(false);
                });
        }
    }

    return (
        <div className="vendors">
            <div className="table-title">
                <h2>Vendors</h2>
            </div>
            {isPending && <p>Loading data</p>}
            {!isPending && error !== null &&
                <div className="error">
                    {error}
                </div>
            }
            {!isPending && (error === null) &&
                <Table data={vendors} columns={VendorTableHeaders} deleteRowFunction={handleDeleteRow} editRowFunction={handleVendorEdit} initialState={{ sortBy: [{ id: "name", desc: false }] }} />}
            {!isPending && (error === null) && editModalOpen &&
                <AddEditVendorModal
                    closeModal={() => {
                        setVendorToEdit(null);
                    }}
                    onModalSubmit={handleVendorModalSubmit}
                    defaultValue={vendorToEdit !== null && vendors[vendorToEdit]}
                    modalSubmitLoading={editModalLoading}
                    vendorList={vendors}
                    setVendorList={setVendors}
                    cancelFunction={() => {
                        setEditModalOpen(false);
                        setVendorToEdit(null);
                    }}
                />
            }
            {!isPending && (error === null) && deleteModalOpen &&
                <DeleteEntityModal
                    closeModal={() => {
                        setVendorToEdit(null);
                    }}
                    onModalSubmit={handleSendDeleteRequest}
                    entityType="Vendor"
                    currentEntity={vendorToDelete !== null && vendorToDelete}
                    currentEntityString={vendorToDelete !== null && vendorToDelete.name}
                    modalSubmitLoading={deleteModalLoading}
                    cancelFunction={() => {
                        setDeleteModalOpen(false)
                        setVendorToEdit(null);
                    }}
                />
            }
            {!isPending && (error === null) && <button className="btn add-new-btn" onClick={() => setEditModalOpen(true)}>Add New Vendor</button>}
        </div>
    );
}

export default Vendors;