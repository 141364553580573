import { useState } from "react";
import "../../BaseModal.css"

import Table from "../../../Table/Table";
import { GetPartTableHeadersForAssembly } from "../../../../static-values/TableHeaders";
import DeleteEntityModal from "../../DeleteEntityModal/DeleteEntityModal";
import { calculateSum } from "../../../../utils/utils";
import AddEditPartForAssembly from "../../AddEditPartForAssembly/AddEditPartForAssembly";
import useGet from "../../../../utils/hooks/useGet";

import { addDropDownDisplayNameToParts } from "../../../../utils/utils";

const NewAssemblyPartsModal = ({ closeModal, onModalSubmit, defaultValue, backFunction = () => {}, cancelFunction = () => {} }) => {
    const formatDataForParts = (parts) => {
        return addDropDownDisplayNameToParts(parts);
    }

    const { data: parts, setData: setParts, isPendingParts, errorParts } = useGet(`/part`, formatDataForParts);

    

    const [formState, setFormState] = useState(defaultValue);

    const [partToDelete, setPartToDelete] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteModalLoading, setDeleteModalLoading] = useState(false);

    const [partToEdit, setPartToEdit] = useState(null);
    const [addEditPartModalOpen, setAddEditPartModalOpen] = useState(false);
    const [partEditModalLoading, setPartEditModalLoading] = useState(false);
    
    
    const handlePartDeleteRow = (partId) => {
        const part = formState.filter((currPart, idx) => currPart.id === partId)[0];
        setPartToDelete(part);
        setDeleteModalOpen(true);
    }

    const handlePartEditRow = (partId) => {
        const position = formState.map(entry => entry.id).indexOf(partId);
        setPartToEdit(position);
        setAddEditPartModalOpen(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onModalSubmit(formState);
        closeModal();
    }

    const handlePartAddEdit = (partEntry) => {
        setPartEditModalLoading(true);
        const partId = parseInt(partEntry.id)

        if (partToEdit === null) {
            const possibleExistingPart = formState.map(entry => entry.id).indexOf(partId);
            if (possibleExistingPart !== -1) {
                alert(`Cannot add part ${parts[possibleExistingPart].partName} as it already exists`);
                setPartEditModalLoading(false);
                return;
            }

            var partToAdd = parts.filter((currPart, idx) => currPart.id === partId)[0]
            partToAdd.quantity = parseInt(partEntry.quantity);
            partToAdd.total = partToAdd.price * partToAdd.quantity;
            var newParts = [...formState, partToAdd];
            setFormState(newParts);
            setPartEditModalLoading(false);
        }
        else {
            var updatedPart = parts[partToEdit];
            updatedPart.quantity = parseInt(partEntry.quantity);
            updatedPart.total = updatedPart.price * updatedPart.quantity;

            formState[partToEdit] = updatedPart;
            setFormState(formState);
            setPartEditModalLoading(false);
        }
    }

    const handlePartDelete = (partId) => {
        setDeleteModalLoading(true);
        const newFormState = formState.filter((currPart, idx) => currPart.id !== partId);
        setFormState(newFormState);
        setPartToDelete(null);
        setDeleteModalLoading(false);
    }

    return (
        <div className="base-modal-container">
            <div className="base-modal">
                <h1>Add Parts</h1>
                <div>
                    <Table
                        data={formState}
                        columns={GetPartTableHeadersForAssembly(calculateSum(formState, "total"))}
                        deleteRowFunction={handlePartDeleteRow}
                        editRowFunction={handlePartEditRow}
                        initialState={{ sortBy: [{ id: "partName", desc: false }] }}
                        actions={['ModalEdit', 'ModalDelete']}
                        hasFooters={true}
                    />
                    <button className="btn" onClick={() => setAddEditPartModalOpen(true)}>Add New Part</button>
                </div>
                <button className="btn inline-btn" onClick={() => { cancelFunction() }}>Cancel</button>
                <button className="btn inline-btn" onClick={() => { backFunction(formState) }}>Back</button>
                <button className="btn" onClick={handleSubmit}>Next</button>
                {addEditPartModalOpen && 
                    <AddEditPartForAssembly
                        closeModal={() => {
                            setAddEditPartModalOpen(false);
                            setPartToEdit(null);
                        }}
                        onModalSubmit={handlePartAddEdit}
                        defaultValue={partToEdit !== null && formState[partToEdit]}
                        modalSubmitLoading={partEditModalLoading}
                        parts={parts}
                        isPending={isPendingParts}
                        error={errorParts}
                        cancelFunction={() => {
                            setAddEditPartModalOpen(false);
                            setPartToEdit(null);
                        }}
                    />
                }
                {deleteModalOpen &&
                    <DeleteEntityModal
                        closeModal={() => {
                            setDeleteModalOpen(false);
                            setPartToDelete(null);
                        }}
                        onModalSubmit={handlePartDelete}
                        entityType="Part"
                        currentEntity={partToDelete !== null && partToDelete}
                        currentEntityString={partToDelete.partName}
                        modalSubmitLoading={deleteModalLoading}
                    />
                }
            </div>

        </div>
    );
}

export default NewAssemblyPartsModal;