import { QuoteStatusIdToValueMappings } from "../../static-values/Mappings";

const ColumnDropDownFilterQuoteStatus = ({ column }) => {
    const {filterValue, setFilter } = column;
    return ( 
        <span>
            Search: {''}
            <select value={filterValue || -1} onChange={(e) => setFilter(e.target.value)}>
                {
                    Object.keys(QuoteStatusIdToValueMappings).map((key, idx) => {
                        return <option key={key} value={key}>{QuoteStatusIdToValueMappings[key]}</option>
                    })
                }
                <option key={-1} value={-1}>----</option>
            </select>
        </span>
     );
}
 
export default ColumnDropDownFilterQuoteStatus;