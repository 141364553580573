import { Countries } from "../../static-values/Countries";

const ColumnDropDownFilterCountries = ({ column }) => {
    const { filterValue, setFilter } = column;
    return (<span>
        Search: {''}
        <select value={filterValue || 1} onChange={(e) => setFilter(e.target.value)}>
            {Countries.map((country) => <option key={country} value={country}>{country}</option>)}
        </select>
    </span>);
}

export default ColumnDropDownFilterCountries;