const ColumnDropDownFilterMakeBuy = ({ column }) => {
    const {filterValue, setFilter } = column;
    return ( 
        <span>
            Search: {''}
            <select value={filterValue || -1} onChange={(e) => setFilter(e.target.value)}>
                <option key={1} value={0}>Make</option>
                <option key={2} value={1}>Buy</option>
            </select>
        </span>
     );
}
 
export default ColumnDropDownFilterMakeBuy;