export const getLocalStorage = (itemName) => {
    return localStorage.getItem(itemName);
}

export const setLocalStorage = (itemName, itemValue) => {
    return localStorage.setItem(itemName, itemValue)
}

export const removeLocalStorage = (itemName) => {
    return localStorage.removeItem(itemName);
}