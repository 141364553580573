import { useEffect, useState } from "react";
import "../BaseModal.css";

import SearchableDropdown from "../../SearchableDropdown/SearchableDropdown";

const AddNewQuoteModal = ({
    defaultValue,
    closeModal,
    onModalSubmit,
    modalSubmitLoading,
    clientList,
    isPendingClients,
    errorClient,
    assemblies,
    isPendingAssemblies,
    errorAssemblies,
    cancelFunction,
    initialAssemblyId = null,
    startingQuoteFromAssembly = false,
    disableAddingInitialAssembly = false
}) => {
    const [formState, setFormState] = useState(defaultValue ||
    {
        "clientId": 0,
        "attention": "",
        "projectName": "",
        "customerPONumber": "",
        "initialAssemblyId": initialAssemblyId ?? 0,
        "initialAssemblyQuantity": 1
    })

    const [clientValue, setClientValue] = useState("Select a client...");
    const [assemblyValue, setAssemblyValue] = useState("Select an assembly");

    const [errors, setErrors] = useState("");
    const [clientFieldErrors, setClientFieldErrors] = useState(null);
    const [loadingClientListErrors, setLoadingClientListErrors] = useState(false);
    const [addInitialAssembly, setAddInitialAssembly] = useState(startingQuoteFromAssembly);

    const requiredFields = ['projectName'];
    const validateForm = () => {
        if (formState.projectName &&
            formState.clientId !== 0 &&
            (clientValue !== null && clientValue === getClientFromId(formState.clientId)?.name)
            && !isPendingClients &&
            errorClient === null
        ) {
            setErrors("");
            setClientFieldErrors("");
            setLoadingClientListErrors(false);
            return true;
        }

        if (formState.clientId === 0 || clientValue === null || clientValue !== getClientFromId(formState.clientId)?.name) {
            setClientFieldErrors("You must select a client to attach to the quote")
        }
        else setClientFieldErrors(null);

        let errorFields = []
        for (const [key, value] of Object.entries(formState)) {
            if (!value && requiredFields.includes(key)) errorFields.push(key);
        }
        setErrors(errorFields.join(', '));
        if (isPendingClients || errorClient !== null) {
            setLoadingClientListErrors(true);
        }
        return false;
    }

    const getClientFromId = (id) => {
        const clientPos = clientList.map(currClient => currClient.id).indexOf(id);
        return clientList[clientPos];
    }

    const getAssemblyFromId = (id) => {
        const assemblyPos = assemblies.map(currAssembly => currAssembly.id).indexOf(id);
        return assemblies[assemblyPos];
    }

    useEffect(() => {
        if (formState.clientId !== 0) {
            var client = getClientFromId(formState.clientId);
            setClientValue(client.name);
        }
    }, [])

    const handleFormUpdate = (e, updateField = null, getObjectFunction, setValueFunction, displayValue) => {
        if (typeof (e) === "number") {
            setFormState({
                ...formState,
                [updateField]: e
            })
            setValueFunction(getObjectFunction(e)[displayValue]);
        }
        else {
            setFormState({
                ...formState,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onModalSubmit(formState, addInitialAssembly);
            closeModal();
        }

    }

    return (
        <div className="base-modal-container">
            <div className="base-modal">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="clientId">Client</label>
                        {isPendingClients && !errorClient &&
                            <select disabled name="id" value={formState.id}>
                                <option key={1} value="Loading clients...">Loading clients...</option>
                            </select>
                        }
                        {!isPendingClients && !errorClient &&
                            <SearchableDropdown
                                options={clientList}
                                label="name"
                                id="id"
                                selectedVal={clientValue}
                                handleChange={(val) => typeof (val) === 'number' ? handleFormUpdate(val, "clientId", getClientFromId, setClientValue, "name") : setClientValue(val)}
                            />
                        }
                        {loadingClientListErrors && <div className="error">Please wait for client list to load</div>}
                        {!isPendingClients && errorClient !== null && <div className="error">Problem loading client list:{errorClient}</div>}
                        {clientFieldErrors && <div className="error">{clientFieldErrors}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="attention">Attention</label>
                        <input
                            name="attention"
                            value={formState.attention}
                            onChange={handleFormUpdate}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="projectName">Project Name</label>
                        <input
                            name="projectName"
                            value={formState.projectName}
                            onChange={handleFormUpdate}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="customerPONumber">Customer PO Number (optional)</label>
                        <input
                            name="customerPONumber"
                            value={formState.customerPONumber}
                            onChange={handleFormUpdate}
                        />
                    </div>
                    {errors && <div className="error">Please include the following fields: {errors}</div>}

                    {!disableAddingInitialAssembly &&
                        <>
                            <div className="form-group">
                                <label htmlFor="addInitialAssembly">Add Initial Assembly?{"\t"}
                                    <input
                                        name="addInitialAssembly"
                                        type="checkbox"
                                        checked={addInitialAssembly}
                                        disabled={startingQuoteFromAssembly}
                                        onChange={() => setAddInitialAssembly(!addInitialAssembly)}
                                    />
                                </label>
                            </div>
                            {addInitialAssembly &&
                                <>
                                    <div className="form-group">
                                        {startingQuoteFromAssembly &&
                                            <select name="initialAssemblyId" value={formState.initialAssemblyId} disabled>
                                                <option key={formState.initialAssemblyId} value={formState.initialAssemblyId}>
                                                    {getAssemblyFromId(formState.initialAssemblyId).assemblyNumber}
                                                </option>
                                            </select>
                                        }
                                        {!startingQuoteFromAssembly && !isPendingAssemblies && errorAssemblies === null &&
                                            <SearchableDropdown
                                                options={assemblies}
                                                label="assemblyNumber"
                                                id="id"
                                                selectedVal={assemblyValue}
                                                handleChange={(val) => typeof (val) === 'number' ? handleFormUpdate(val, "initialAssemblyId", getAssemblyFromId, setAssemblyValue, "assemblyNumber") : setAssemblyValue(val)}
                                            />
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="initialAssemblyQuantity">Quantity</label>
                                        <input
                                            name="initialAssemblyQuantity"
                                            value={formState.initialAssemblyQuantity}
                                            onChange={handleFormUpdate}
                                        />
                                    </div>
                                </>
                            }
                        </>
                    }
                    {!modalSubmitLoading &&
                        <div>
                            <button className="btn inline-btn" type="button" onClick={() => cancelFunction()}>Cancel</button>
                            <button className="btn" type="submit">Submit</button>
                        </div>
                    }
                    {modalSubmitLoading && <button disabled className="btn" type="submit">Submitting...</button>}
                </form>
            </div>
        </div>
    )

}

export default AddNewQuoteModal;