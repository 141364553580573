import useGet from "../../utils/hooks/useGet";
import { ConfigValues } from "../../utils/configValues";

const ColumnDropDownFilterVendors = ({ column }) => {
    const { data: vendors, setData: setVendors, isPending, error } = useGet(`${ConfigValues.baseUrl}/vendor`);
    const { filterValue, setFilter } = column;
    return (<span>
        Search: {''}
        {isPending && !error &&
            <select value={1} onChange={(e) => setFilter(e.target.value)}>
                <option key={1} value="Loading vendors...">Loading vendors...</option>
            </select>
        }
        {!isPending && !error &&
            <select value={filterValue || 1} onChange={(e) => setFilter(e.target.value)}>
                {vendors.sort((a, b) => (a.name).localeCompare(b.name)).map((vendor) => <option key={vendor.id} value={vendor.name}>{vendor.name}</option>)}
            </select>
        }
    </span>);
}

export default ColumnDropDownFilterVendors;