import ColumnDropDownFilterCountries from "../components/ColumnFilters/ColumnDropDownFilterCountries";
import ColumnDropDownFilterMakeBuy from "../components/ColumnFilters/ColumnDropDownFilterMakeBuy";
import ColumnDropDownFilterQuoteStatus from "../components/ColumnFilters/ColumnDropDownFilterQuoteStatus";
import ColumnDropDownFilterStates from "../components/ColumnFilters/ColumnDropDownFilterStates";
import ColumnDropDownFilterVendors from "../components/ColumnFilters/ColumnDropDownFilterVendors";
import ColumnFilter from "../components/ColumnFilters/ColumnFilter";
import ColumnDropDownFilterWonLost from "../components/ColumnFilters/ColumnDropDownFilterWonLost";

import { formatFloatPercentageToString, formatMoney } from "../utils/utils";
import { QuoteStatusIdToValueMappings, WonLostIdToValueMappings } from "./Mappings";

const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.includes('-')) return phoneNumber;
    const cleanedPhoneNumber = phoneNumber.replace(/[^a-zA-Z0-9 ]/g, '')
    const firstThree = cleanedPhoneNumber.slice(0, 3);
    const secondThree = cleanedPhoneNumber.slice(3, 6);
    const lastFour = cleanedPhoneNumber.slice(6, 10);

    return `${firstThree}-${secondThree}-${lastFour}`;
}

export const VendorTableHeaders = [
    {
        Header: "Id",
        accessor: "id",
        disableFilters: true
    },
    {
        Header: "Name",
        accessor: "name",
    },
    {
        Header: "City",
        accessor: "city",
    },
    {
        Header: "State",
        accessor: "state",
    },
    {
        Header: "Primary Contact",
        accessor: "primaryContact",
        disableFilters: true,
        Cell: ({ value }) => {
            var formattedPhoneNumber = formatPhoneNumber(value.phoneNumber);
            if (value.firstName.includes("N/A")) return formattedPhoneNumber
            return `${value.firstName} ${value.lastName} - ${formattedPhoneNumber}`
        }
    }
];

export const ContactTableHeaders = [
    {
        Header: "First Name",
        accessor: "firstName",
    },
    {
        Header: "Last Name",
        accessor: "lastName",
    },
    {
        Header: "Email",
        accessor: "email",
    },
    {
        Header: "Phone Number",
        accessor: "phoneNumber",
        Cell: ({ value }) => { return formatPhoneNumber(value) }
    },
    {
        Header: "Primary?",
        accessor: "isPrimary",
        Cell: ({ value }) => {
            return value ? "True" : "False"
        }
    }
]

export const PartTableHeaders = [
    {
        Header: "Part Number",
        accessor: "partName"
    },
    {
        Header: "Part Description",
        accessor: "partDescription"
    },
    {
        Header: "Material",
        accessor: "material"
    },
    {
        Header: "Material Type",
        accessor: "materialType"
    },
    {
        Header: "MakeBuy",
        accessor: "makeBuy",
        Filter: ColumnDropDownFilterMakeBuy,
        Cell: ({ value }) => {
            switch (value) {
                case 0:
                    return "Make";
                case 1:
                    return "Buy";
                default:
                    return "Unknown";
            }
        }
    },
    {
        Header: "Price",
        accessor: "price",
        Cell: ({ value }) => {
            return formatMoney(value);
        }
    },
    {
        Header: "Material Amount",
        accessor: "length"
    },
    {
        Header: "Machine",
        accessor: "machine"
    },
    {
        Header: "Vendor Name",
        accessor: "vendor.name",
        Filter: ColumnDropDownFilterVendors
    }
]

export const CustomTagTableHeaders = [
    {
        accessor: "key",
        disableFilters: true
    },
    {
        accessor: "value",
        disableFilters: true
    }
]

export const GetPartTableHeadersForAssembly = (partTotal) => {
    return [
        {
            Header: "Materials",
            accessor: "partName",
            disableFilters: true,
            Footer: "Materials Total"
        },
        {
            Header: "Qty",
            accessor: "quantity",
            disableFilters: true
        },
        {
            Header: "Price",
            accessor: "price",
            disableFilters: true,
            Cell: ({ value }) => {
                return formatMoney(value);
            },
        },
        {
            Header: "Total",
            accessor: "total",
            disableFilters: true,
            Footer: ({ value }) => {
                return formatMoney(partTotal);
            },
            Cell: ({ value }) => {
                return formatMoney(value);
            },
        }
    ]
}


export const ClientTableHeaders = [
    {
        Header: "Name",
        accessor: "name"
    },
    {
        Header: "Address",
        columns: [
            {
                Header: "Line 1",
                accessor: "address.line1"
            },
            {
                Header: "Line 2",
                accessor: "address.line2"
            },
            {
                Header: "City",
                accessor: "address.city"
            },
            {
                Header: "State",
                accessor: "address.state",
                Filter: ColumnDropDownFilterStates
            },
            {
                Header: "Country",
                accessor: "address.country",
                Filter: ColumnDropDownFilterCountries
            }
        ]
    },
    {
        Header: "Primary Contact",
        accessor: "primaryContact",
        disableFilters: true,
        Cell: ({ value }) => { return `${value.firstName} ${value.lastName} - ${formatPhoneNumber(value.phoneNumber)}` }
    }
]

export const AssemblyTableHeaders = [
    {
        Header: "Assembly Number",
        accessor: "assemblyNumber"
    },
    {
        Header: "Assembly Description",
        accessor: "assemblyDescription"
    },
    {
        Header: "Price",
        accessor: "unitPrice",
        disableFilters: true,
        Cell: ({ value }) => {
            return formatMoney(value);
        }
    }
]

export const QuoteTableHeaders = [
    {
        Header: "Quote Number",
        accessor: "quoteNumber",
        Filter: ColumnFilter
    },
    {
        Header: "Client",
        accessor: "client.name",
        Filter: ColumnFilter
    },
    {
        Header: "Project Name",
        accessor: "projectName"
    },
    {
        Header: "Customer PO Number",
        accessor: "customerPONumber"
    },
    {
        Header: "Status",
        accessor: "status",
        Filter: ColumnDropDownFilterQuoteStatus,
        Cell: ({ value }) => {
            return QuoteStatusIdToValueMappings[value]
        }
    },
    {
        Header: "Won/Lost",
        accessor: "wonLost",
        Filter: ColumnDropDownFilterWonLost,
        Cell: ({ value }) => {
            return WonLostIdToValueMappings[value]
        }
    }
]


export const GetSubAssemblyTableHeadersForSubAssembly = (subAssemblyTotal, footerText = "Sub Assembly Total") => {
    return [
        {
            Header: "Assembly Number",
            accessor: "assemblyNumber",
            Footer: footerText
        },
        {
            Header: "Contingency",
            accessor: "contingencyPercentage",
            Cell: ({ value }) => {
                return formatFloatPercentageToString(value)
            },
            disableFilters: true
        },
        {
            Header: "Profit Margin",
            accessor: "profitMarginPercentage",
            Cell: ({ value }) => {
                return formatFloatPercentageToString(value)
            },
            disableFilters: true
        },
        {
            Header: "Qty",
            accessor: "quantity",
            disableFilters: true
        },
        {
            Header: "Price",
            accessor: "unitPrice",
            Cell: ({ value }) => {
                return formatMoney(value);
            },
            disableFilters: true
        },
        {
            Header: "Total",
            accessor: "total",
            Footer: ({ value }) => {
                return formatMoney(subAssemblyTotal);
            },
            Cell: ({ value }) => {
                return formatMoney(value);
            },
            disableFilters: true
        },
    ]
}

export const GetHeadersForSmallTables = (tableType, hoursTotal = 0, overallTotal = 0) => {
    return [
        {
            Header: "Type",
            accessor: "type",
            disableFilters: true
        },
        {
            Header: tableType,
            accessor: "typeString",
            disableFilters: true,
            Footer: `${tableType} Total`
        },
        {
            Header: "Hours",
            accessor: "hours",
            disableFilters: true,
            Footer: hoursTotal
        },
        {
            Header: "Rate",
            accessor: "rate",
            Cell: ({ value }) => {
                return formatMoney(value);
            },
            disableFilters: true
        },
        {
            Header: "Total",
            accessor: "total",
            Footer: ({ value }) => {
                return formatMoney(overallTotal);
            },
            Cell: ({ value }) => {
                return formatMoney(value);
            },
            disableFilters: true
        }
    ]
}

export const DocumentTableHeaders = [
    {
        Header: "File Name",
        accessor: "fileName",
        disableFilters: true
    },
    {
        Header: "Uploaded By",
        accessor: "uploadedByUser.fullName",
        disableFilters: true
    },
    {
        Header: "Uploaded On",
        accessor: "dateCreatedCST",
        disableFilters: true,
        Cell: ({ value }) => {
            var date = new Date(value);
            return date.toLocaleString();
        }
    }
    // {
    //     Header: "",
    //     accessor: "presignedUrl",
    //     disableFilters: true,
    //     Cell: ({value}) => {
    //         return <Link target="_blank" rel="noopener noreferrer" to={value}>
    //             <BsDownload />
    //         </Link>
    //     }
    // }
]