import { ConfigValues } from "../../utils/configValues";
import { getSessionCookie } from "../../utils/sessionsUtils";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { ClientTableHeaders } from "../../static-values/TableHeaders";

import Table from "../Table/Table";
import useGet from "../../utils/hooks/useGet";
import DeleteEntityModal from "../Modals/DeleteEntityModal/DeleteEntityModal";
import AddEditClientModal from "../Modals/AddEditClientModal/AddEditClientModal";
import "./Clients.css";
import apiClient, { testAuth } from "../../services/apiClient";

const Clients = () => {
    const navigate = useNavigate();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [clientToEdit, setClientToEdit] = useState(null);
    const [editModalLoading, setEditModalLoading] = useState(false);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [clientToDelete, setClientToDelete] = useState(null);
    const [deleteModalLoading, setDeleteModalLoading] = useState(null);

    const handleClientEdit = (clientId) => {
        const clientPos = clients.map(client => client.id).indexOf(clientId);
        setClientToEdit(clientPos);
        setEditModalOpen(true);
    }

    const { data: clients, setData: setClients, isPending, error } = useGet(`${ConfigValues.baseUrl}/client`);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                setEditModalOpen(false);
            }
        };
        window.addEventListener('keydown', handleEsc);

        (async () => {
            const testAuthResult = await testAuth(apiClient);
            if (!testAuthResult) {
                navigate('/login');
            }
        })();

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [])

    const handleSendDeleteRequest = (targetClientId) => {
        setDeleteModalLoading(true);

        apiClient.delete(`client/${targetClientId}`)
            .then(response => {
                setClientToDelete(null);
                setClients(clients.filter((currClient, idx) => currClient.id !== targetClientId));
                setDeleteModalLoading(false);
                setDeleteModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete client. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleDeleteRow = (targetClientId) => {
        const client = clients.filter((currClient, idx) => currClient.id === targetClientId)[0];
        setClientToDelete(client);
        setDeleteModalOpen(true);
    }

    const handleClientModalSubmit = (newClient) => {
        setEditModalLoading(true);

        if (clientToEdit === null) {
            const newClientBody = {
                "name": newClient.name,
                "address": {
                    "line1": newClient.addressLine1,
                    "line2": newClient.addressLine2,
                    "city": newClient.addressCity,
                    "state": newClient.addressState,
                    "zipCode": newClient.addressZipCode,
                    "country": newClient.addressCountry
                },
                "contact": {
                    "firstName": newClient.contactFirstName,
                    "lastName": newClient.contactLastName,
                    "email": newClient.contactEmail,
                    "phoneNumber": newClient.contactPhoneNumber,
                    "isPrimary": true
                }
            }

            apiClient.post('/client', newClientBody)
                .then(clientResponse => {
                    setEditModalLoading(false);
                    setClients([...clients, clientResponse.data]);
                    setEditModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to create client. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setDeleteModalLoading(false);
                    setEditModalOpen(false);
                });
        }
        // If editing an existing client
        else {
            const updatedClientBody = {
                "name": newClient.name,
                "address": {
                    "line1": newClient.address.line1,
                    "line2": newClient.address.line2,
                    "city": newClient.address.city,
                    "state": newClient.address.state,
                    "zipCode": newClient.address.zipCode,
                    "country": newClient.address.country
                }
            }
            apiClient.patch(`/client/${newClient.id}`, updatedClientBody)
                .then(updatedClientResponse => {
                    setEditModalLoading(false);
                    setClients(clients.map((currClient, idx) => {
                        if (idx !== clientToEdit) return currClient;
                        return updatedClientResponse.data;
                    }));
                    setEditModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to edit client. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setDeleteModalLoading(false);
                    setEditModalOpen(false);
                });
        }
    }

    return (
        <div className="clients">
            <div className="table-title">
                <h2>Clients</h2>
            </div>
            {isPending && <p>Loading data</p>}
            {!isPending && error !== null &&
                <div className="error">
                    {error}
                </div>
            }
            {!isPending && (error === null) &&
                <Table data={clients} columns={ClientTableHeaders} deleteRowFunction={handleDeleteRow} editRowFunction={handleClientEdit} initialState={{ sortBy: [{ id: "name", desc: false }] }} actionGoesOnRow={2} />}
            {!isPending && (error === null) && editModalOpen &&
                <AddEditClientModal
                    closeModal={() => {
                        setClientToEdit(null);
                    }}
                    onModalSubmit={handleClientModalSubmit}
                    defaultValue={clientToEdit !== null && clients[clientToEdit]}
                    modalSubmitLoading={editModalLoading}
                    clientList={clients}
                    setClientList={setClients}
                    cancelFunction={() => {
                        setClientToEdit(null);
                        setEditModalOpen(false)
                    }}
                />
            }
            {!isPending && (error === null) && deleteModalOpen &&
                <DeleteEntityModal
                    closeModal={() => {
                        setClientToDelete(null);
                    }}
                    onModalSubmit={handleSendDeleteRequest}
                    entityType="Client"
                    currentEntity={clientToDelete !== null && clientToDelete}
                    currentEntityString={clientToDelete !== null && clientToDelete.name}
                    modalSubmitLoading={deleteModalLoading}
                    cancelFunction={() => {
                        setDeleteModalOpen(false);
                    }}
                />
            }
            {!isPending && (error === null) && <button className="btn add-new-btn" onClick={() => setEditModalOpen(true)}>Add New Client</button>}
        </div>
    );
}

export default Clients;