import "./Logout.css"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { removeLocalStorage } from "../../utils/sessionsUtils";

const Logout = () => {

    const navigate = useNavigate();

    useEffect(() => {
        removeLocalStorage('idToken');
        removeLocalStorage('userId');
        removeLocalStorage('refreshToken');
        navigate('/');
    })

    return (  
        <div className="logout">
        </div>
    );
}
 
export default Logout;