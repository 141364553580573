import "../BaseModal.css"
import { AbbreviatedStates } from "../../../static-values/States";
import { useState } from "react";
import { ContactTableHeaders } from "../../../static-values/TableHeaders";
import Table from "../../Table/Table";
import AddEditContactModal from "../AddEditContactModal/AddEditContactModal";
import DeleteEntityModal from "../DeleteEntityModal/DeleteEntityModal";
import { EntityTypes } from "../../../static-values/EntityTypes";
import { getSessionCookie } from "../../../utils/sessionsUtils";
import { ConfigValues } from "../../../utils/configValues";
import apiClient from "../../../services/apiClient";

const AddEditVendorModal = ({ closeModal, onModalSubmit, defaultValue, modalSubmitLoading, vendorList, setVendorList, cancelFunction }) => {
    const [formState, setFormState] = useState(defaultValue ||
    {
        name: "",
        city: "",
        state: "AL",
        contactFirstName: "",
        contactLastName: "",
        contactEmail: "",
        contactPhoneNumber: ""
    }
    );

    const [addEditContactModalOpen, setAddEditContactModalOpen] = useState(null);
    const [contactToEdit, setContactToEdit] = useState(null);
    const [addEditContactModalLoading, setAddEditContactModalLoading] = useState(null);

    const [contactDeleteModalOpen, setContactDeleteModalOpen] = useState(false);
    const [contactToDelete, setContactToDelete] = useState(null);
    const [deleteModalLoading, setDeleteModalLoading] = useState(null);

    const [vendorInfoErrors, setVendorInfoErrors] = useState("");
    const [primaryContactInfoErrors, setPrimaryContactInfoErrors] = useState("");

    const validateForm = () => {
        if (!formState.hasOwnProperty('contacts') && formState.name && formState.city && formState.state && formState.contactFirstName && formState.contactLastName && formState.contactEmail && formState.contactPhoneNumber) {
            setVendorInfoErrors("");
            setPrimaryContactInfoErrors("");
            return true;
        }

        if (formState.hasOwnProperty('contacts') && formState.name && formState.city && formState.state) {
            setVendorInfoErrors("");
            setPrimaryContactInfoErrors("");
            return true;
        }

        const vendorInfoReqFields = ['name', 'city', 'state'];
        const vendorInfoReqFieldsToReadbleName = {
            'name': "Name",
            'city': "City",
            'state': "State"
        };
        const primaryContactInfoReqFields = ['contactFirstName', 'contactLastName', 'contactEmail', 'contactPhoneNumber'];
        const primaryContactInfoFieldNameToReadableName = {
            'contactFirstName': "First Name",
            'contactLastName': "Last Name",
            'contactEmail': "Email",
            'contactPhoneNumber': "Phone Number",
        };

        let vendorInfoErrorFields = []
        let primaryContactInfoErrorFields = []
        for (const [key, value] of Object.entries(formState)) {
            if (!value && vendorInfoReqFields.includes(key)) vendorInfoErrorFields.push(vendorInfoReqFieldsToReadbleName[key]);
            if (!value && primaryContactInfoReqFields.includes(key)) primaryContactInfoErrorFields.push(primaryContactInfoFieldNameToReadableName[key]);
        }

        setVendorInfoErrors(vendorInfoErrorFields.join(', '));
        setPrimaryContactInfoErrors(primaryContactInfoErrorFields.join(', '));
        return false;
    }

    const handleFormUpdate = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }

    const handleContactEdit = (contactId) => {
        const contactPos = formState.contacts.map(contact => contact.id).indexOf(contactId);
        setContactToEdit(contactPos);
        setAddEditContactModalOpen(true);
    }

    const handleContactDelete = (targetContactId) => {
        const contact = formState.contacts.filter(currContact => currContact.id === targetContactId)[0];
        if (contact.isPrimary) {
            alert("You cannot delete the primary contact. Make another contact primary and try again");
        }
        else {
            setContactToDelete(contact);
            setContactDeleteModalOpen(true);
        }
    }

    const handleSendDeleteRequest = (targetContactId) => {
        let adjustedVendor = formState;
        setDeleteModalLoading(true);

        apiClient.delete(`/contact/${targetContactId}`)
            .then(res => {
                setContactToDelete(null);
                adjustedVendor.contacts = adjustedVendor.contacts.filter(currContact => currContact.id !== targetContactId)
                setVendorList(vendorList.map((currVendor) => {
                    if (adjustedVendor.id !== currVendor.id) return currVendor;
                    return adjustedVendor;
                }))
                setContactDeleteModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete contact. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleAddEditContactSubmit = (newContact) => {
        setAddEditContactModalLoading(true);

        let adjustedVendor = formState;
        if (contactToEdit == null) {
            const newContactBody = {
                "firstName": newContact.firstName,
                "lastName": newContact.lastName,
                "email": newContact.email,
                "phoneNumber": newContact.phoneNumber,
                "entityId": formState.id,
                "isPrimary": Boolean(newContact.isPrimary),
                "entity": EntityTypes.Vendor
            };
            
            apiClient.post('/contact', newContactBody)
                .then(contactResponse => {
                    if (contactResponse.data.isPrimary) {
                        adjustedVendor.contacts = adjustedVendor.contacts.map((currContact) => {
                            if (currContact.isPrimary) {
                                currContact.isPrimary = false;
                            }
                            return currContact;
                        })
                        adjustedVendor.primaryContact = contactResponse.data;
                        adjustedVendor.contacts = [contactResponse.data, ...adjustedVendor.contacts];
                    }
                    else {
                        adjustedVendor.contacts = [...adjustedVendor.contacts, contactResponse.data];
                    }
                    setVendorList(vendorList.map((currVendor) => {
                        if (adjustedVendor.id !== currVendor.id) return currVendor;
                        return adjustedVendor;
                    }))
                    setAddEditContactModalLoading(false);
                    setAddEditContactModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to create new contact. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setAddEditContactModalLoading(false);
                });
        }
        else {
            const updatedContactBody = {
                "firstName": newContact.firstName,
                "lastName": newContact.lastName,
                "email": newContact.email,
                "phoneNumber": newContact.phoneNumber,
                "isPrimary": Boolean(newContact.isPrimary)
            };

            apiClient.patch(`/contact/${newContact.id}`, updatedContactBody)
                .then(updatedContactResponse => {
                    // get the contacts that did not change
                    adjustedVendor.contacts = adjustedVendor.contacts.filter(currContact => {
                        if (currContact.id !== updatedContactResponse.data.id) return currContact;
                    })
                    if (updatedContactResponse.data.isPrimary) {
                        adjustedVendor.contacts = adjustedVendor.contacts.map((currContact) => {
                            if (currContact.isPrimary) {
                                currContact.isPrimary = false;
                            }
                            return currContact;
                        })
                        adjustedVendor.primaryContact = updatedContactResponse.data;
                        adjustedVendor.contacts = [updatedContactResponse.data, ...adjustedVendor.contacts];
                    }
                    else {
                        adjustedVendor.contacts = [...adjustedVendor.contacts, updatedContactResponse.data];
                    }
                    setVendorList(vendorList.map((currVendor) => {
                        if (adjustedVendor.id !== currVendor.id) return currVendor;
                        return adjustedVendor;
                    }))
                    setAddEditContactModalLoading(false);
                    setAddEditContactModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to edit contact. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setAddEditContactModalLoading(false);
                });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) return;
        onModalSubmit(formState);

        closeModal();
    }

    return (
        <div className="base-modal-container" onClick={(e) => {
            if (e.target.className === "base-modal-container") closeModal();
        }}>
            <div className="base-modal">
                <form onSubmit={handleSubmit}>
                    <h2>Vendor Info</h2>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input name="name" value={formState.name} onChange={handleFormUpdate} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="city">City</label>
                        <input name="city" value={formState.city} onChange={handleFormUpdate} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="state">State</label>
                        <select name="state" value={formState.state} onChange={handleFormUpdate}>
                            {AbbreviatedStates.map((state) => {
                                return (
                                    <option key={state} value={state}>{state}</option>
                                )
                            })}
                        </select>
                    </div>
                    {vendorInfoErrors && <div className="error">{`Please include: ${vendorInfoErrors}`}</div>}
                    {!formState.hasOwnProperty('contacts') && <h2>Primary Contact Info</h2>}
                    {!formState.hasOwnProperty('contacts') &&
                        <div className="form-group">
                            <label htmlFor="contactFirstName">First Name</label>
                            <input name="contactFirstName" value={formState.contactFirstName} onChange={handleFormUpdate} />
                        </div>
                    }
                    {!formState.hasOwnProperty('contacts') &&
                        <div className="form-group">
                            <label htmlFor="contactLastName">Last Name</label>
                            <input name="contactLastName" value={formState.contactLastName} onChange={handleFormUpdate} />
                        </div>
                    }
                    {!formState.hasOwnProperty('contacts') &&
                        <div className="form-group">
                            <label htmlFor="contactEmail">Email</label>
                            <input name="contactEmail" value={formState.contactEmail} onChange={handleFormUpdate} />
                        </div>
                    }
                    {!formState.hasOwnProperty('contacts') &&
                        <div className="form-group">
                            <label htmlFor="contactPhoneNumber">Phone Number</label>
                            <input name="contactPhoneNumber" value={formState.contactPhoneNumber} onChange={handleFormUpdate} />
                        </div>
                    }
                    {primaryContactInfoErrors && <div className="error">{`Please include: ${primaryContactInfoErrors}`}</div>}
                    {!modalSubmitLoading &&
                        <div>
                            <button className="btn inline-btn" type="button" onClick={() => cancelFunction()}>Cancel</button>
                            <button className="btn" type="submit">Submit</button>
                        </div>
                    }
                    {modalSubmitLoading && <button disabled className="btn" type="submit">Submitting...</button>}
                </form>

                {formState.hasOwnProperty('contacts') &&
                    <div className="contacts-table">
                        <h2>Contacts</h2>
                        <Table data={formState.contacts} columns={ContactTableHeaders} deleteRowFunction={handleContactDelete} editRowFunction={handleContactEdit} />
                        <button className="btn" onClick={() => setAddEditContactModalOpen(true)}>Add New Contact</button>
                        {contactDeleteModalOpen &&
                            <DeleteEntityModal
                                closeModal={() => {
                                    setContactToDelete(null);
                                }}
                                onModalSubmit={handleSendDeleteRequest}
                                entityType="Contact"
                                currentEntity={contactToDelete !== null && contactToDelete}
                                currentEntityString={contactToDelete !== null && `${contactToDelete.firstName} ${contactToDelete.lastName}`}
                                modalSubmitLoading={deleteModalLoading}
                                cancelFunction={() => {
                                    setContactDeleteModalOpen(false);
                                }}
                            />
                        }
                    </div>
                }

            </div>
            {addEditContactModalOpen &&
                <AddEditContactModal
                    closeModal={() => {
                        setContactToEdit(null);
                    }}
                    onModalSubmit={handleAddEditContactSubmit}
                    defaultValue={contactToEdit !== null && formState.contacts[contactToEdit]}
                    modalSubmitLoading={addEditContactModalLoading}
                    cancelFunction={() => {
                        setAddEditContactModalOpen(false);
                    }}
                />
            }
        </div>

    );
}

export default AddEditVendorModal;