import { useNavigate, useParams } from "react-router-dom";

import useGet from "../../utils/hooks/useGet";
import { useState, useEffect } from "react";
import { CommentSection } from 'react-comments-section';
import 'react-comments-section/dist/index.css';
import "./QuoteDetail.css"
import AddEditClientModal from "../Modals/AddEditClientModal/AddEditClientModal";
import { formatFloatPercentageToString, formatMoney, formatStringPercentageToFloat, formatStringMoneyToFloat, addDropDownDisplayNameToParts } from "../../utils/utils";
import Table from "../Table/Table";
import { calculateSum } from "../../utils/utils";
import { GetPartTableHeadersForAssembly, GetSubAssemblyTableHeadersForSubAssembly } from "../../static-values/TableHeaders";
import { DocumentTableHeaders } from "../../static-values/TableHeaders";
import apiClient from "../../services/apiClient";
import AddEditPartForAssembly from "../Modals/AddEditPartForAssembly/AddEditPartForAssembly";
import AddEditAssembly from "../Modals/AddEditAssembly/AddEditAssembly";
import DeleteEntityModal from "../Modals/DeleteEntityModal/DeleteEntityModal";
import { DefaultTermsOfPayment, QuoteStatusIdToValueMappings, WonLostIdToValueMappings } from "../../static-values/Mappings";
import { getLocalStorage } from "../../utils/sessionsUtils";
import UploadDocumentModal from "../Modals/UploadDocumentModal/UploadDocumentModal";
import { EntityTypes } from "../../static-values/EntityTypes";
import AddNewQuoteModal from "../Modals/AddNewQuoteModal/AddNewQuoteModal";
import { QuoteStatusValueToIdMappings } from "../../static-values/Mappings";
import DatePicker from "react-datepicker";
import SearchableDropdown from "../SearchableDropdown/SearchableDropdown";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ExportQuoteAsPdfModal from "../Modals/ExportQuoteAsPdfModal/ExportQuoteAsPdfModal";

const QuoteDetail = () => {
    const { quoteId } = useParams();
    const navigate = useNavigate();

    const createCommentsFromNotes = (notes) => {
        var comments = notes.map((currNote, idx) => {
            var date = new Date(currNote.dateCreatedCST);
            return {
                userId: currNote.isAudit ? 0 : currNote.userId,
                comId: currNote.id,
                fullName: `${currNote.user.firstName} ${currNote.user.lastName} - ${date.toLocaleString()}`,
                userProfile: "",
                text: currNote.message,
                avatarUrl: `https://ui-avatars.com/api/name=${currNote.user.firstName}%20${currNote.user.lastName}&background=random`,
                replies: []
            }
        })

        return comments;
    }

    const formatData = (quoteDetails) => {
        quoteDetails.comments = createCommentsFromNotes(quoteDetails.notes);
        quoteDetails.assemblies = quoteDetails.assemblies.map(currAssembly => {
            currAssembly.contingencyPercentageString = formatFloatPercentageToString(currAssembly.contingencyPercentage);
            currAssembly.profitMarginPercentageString = formatFloatPercentageToString(currAssembly.profitMarginPercentage);
            currAssembly.unitPriceString = formatMoney(currAssembly.unitPrice)

            return currAssembly;
        });

        setSalespersonValue(quoteDetails.salesPerson.fullName);
        return quoteDetails;
    }

    const formatDataForAssemblies = (assemblies) => {
        var assembliesFormatted = assemblies.map(currAssembly => {
            currAssembly.contingencyPercentageString = formatFloatPercentageToString(currAssembly.contingencyPercentage);
            currAssembly.profitMarginPercentageString = formatFloatPercentageToString(currAssembly.profitMarginPercentage);
            currAssembly.unitPriceString = formatMoney(currAssembly.unitPrice)
        });

        return assembliesFormatted;
    }

    const formatDataForParts = (parts) => {
        return addDropDownDisplayNameToParts(parts);
    }

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                setAddEditAssemblyModalOpen(false);
                setAddEditPartModalOpen(false);
                setViewClientDetailsModalOpen(false);
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [])

    const { data: quoteDetails, setData: setQuoteDetails, isPending, error } = useGet(`/quote/${quoteId}`, formatData);
    const { data: parts, setData: setParts, isPending: isPendingParts, error: errorParts } = useGet(`/part`, formatDataForParts);
    const { data: clients, setData: setClients, isPending: isPendingClient, error: errorClient } = useGet("/client")
    const { data: assemblies, setData: setAssemblies, isPending: isPendingSubAssemblies, error: errorSubAssemblies } = useGet(`/assembly`, formatDataForAssemblies);
    const { data: users, setData: setUsers, isPendingUsers, errorUsers } = useGet(`/user`);

    const [salesPersonValue, setSalespersonValue] = useState("Loading salesperson...")
    const [showAssemblyPricesOnExportedPdf, setShowAssemblyPricesOnExportedPdf] = useState(true);

    const [exportAsPdfModalOpen, setExportAsPdfModalOpen] = useState(false);
    const [exportingAsPdf, setExportingAsPdf] = useState(false);

    const [viewClientDetailsModalOpen, setViewClientDetailsModalOpen] = useState(false);
    const [deleteModalLoading, setDeleteModalLoading] = useState(false);
    const [pendingChanges, setPendingChanges] = useState(false);
    const [sendingUpdate, setSendingUpdate] = useState(false);

    const [addEditPartModalOpen, setAddEditPartModalOpen] = useState(false);
    const [partToEdit, setPartToEdit] = useState(null);
    const [deletePartModalOpen, setDeletePartModalOpen] = useState(false);
    const [partToDelete, setPartToDelete] = useState(null);
    const [partEditModalLoading, setPartEditModalLoading] = useState(false);

    const [addEditAssemblyModalOpen, setAddEditAssemblyModalOpen] = useState(false);
    const [assemblyToEdit, setAssemblyToEdit] = useState(null);
    const [deleteAssemblyModalOpen, setDeleteAssemblyModalOpen] = useState(false);
    const [assemblyToDelete, setAssemblyToDelete] = useState(null);
    const [assemblyEditModalLoading, setAssemblyEditModalLoading] = useState(false);

    const [deleteDocumentModalOpen, setDeleteDocumentModalOpen] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);
    const [uploadDocumentModalOpen, setUploadDocumentModalOpen] = useState(false);
    const [uploadDocumentModalLoading, setUploadDocumentModalLoading] = useState(false);

    const [copyQuoteModalOpen, setCopyQuoteModalOpen] = useState(false);
    const [copyQuoteModalLoading, setCopyQuoteModalLoading] = useState(false);

    const recalculatePartAndAssemblyTotal = (updatedQuoteInfo) => {
        updatedQuoteInfo.partTotal = updatedQuoteInfo.parts.map(entry => entry.total).reduce((a, b) => a + b, 0);
        updatedQuoteInfo.assemblyTotal = updatedQuoteInfo.assemblies.map(entry => entry.total).reduce((a, b) => a + b, 0);

        return updatedQuoteInfo;
    }

    const handleFormUpdate = (e) => {
        setPendingChanges(true);
        if (typeof (e) === "number") {
            var currentUser = users.filter(user => user.id === e)[0];
            setSalespersonValue(currentUser.fullName);
            setQuoteDetails({
                ...quoteDetails,
                ["salesPersonUserId"]: currentUser.cognitoUserId
            })
        }
        else {
            setQuoteDetails({
                ...quoteDetails,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleNewNote = (data) => {
        const newNote = {
            "message": data.text,
            "userId": data.userId
        }
        apiClient.post(`/quote/${quoteDetails.id}/note`, newNote)
            .then(response => {
                var newNotes = [response.data, ...quoteDetails.notes]
                var date = new Date(response.data.dateCreatedCST);
                var newComment = {
                    userId: response.data.userId,
                    comId: response.data.id,
                    fullName: `${response.data.user.firstName} ${response.data.user.lastName} - ${date.toLocaleString()}`,
                    userProfile: "",
                    text: response.data.message,
                    avatarUrl: `https://ui-avatars.com/api/name=${response.data.user.firstName}%20${response.data.user.lastName}&background=random`,
                    replies: []
                }
                var newComments = [newComment, ...quoteDetails.comments]
                setQuoteDetails({
                    ...quoteDetails,
                    ["notes"]: newNotes,
                    ["comments"]: newComments
                });
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to add new note. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleEditNote = (data) => {
        const editedNote = {
            "id": data.comId,
            "message": data.text,
            "userId": data.userId
        }

        apiClient.patch(`/quote/${quoteDetails.id}/note`, editedNote)
            .then(response => { })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to edit note. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleDeleteNote = (data) => {
        apiClient.delete(`/quote/${quoteDetails.id}/note/${data.comIdToDelete}`)
            .then(response => { })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete note. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleQuoteDetailsUpdate = (e) => {
        e.preventDefault();
        setSendingUpdate(true);

        const updatedQuoteValues = {
            "status": parseInt(quoteDetails.status),
            "wonLost": quoteDetails.wonLost === null ? null : parseInt(quoteDetails.wonLost),
            "customerPONumber": quoteDetails.customerPONumber,
            "projectName": quoteDetails.projectName,
            "updatedByUserId": getLocalStorage("userId"),
            "salesPersonUserId": quoteDetails.salesPersonUserId,
            "quoteAttention": quoteDetails.quoteAttention
        }

        if (quoteDetails.expirationDate !== null) {
            if (typeof (quoteDetails.expirationDate) === 'string') {
                quoteDetails.expirationDate = new Date(quoteDetails.expirationDate);
            }
            quoteDetails.expirationDate.setHours(0);
            quoteDetails.expirationDate.setMinutes(0);
            quoteDetails.expirationDate.setSeconds(0);
            updatedQuoteValues["expirationDate"] = quoteDetails.expirationDate.toISOString()
        }
        else {
            var expirationDate = moment().add(30, 'days').toDate();
            expirationDate.setHours(0);
            expirationDate.setMinutes(0);
            expirationDate.setSeconds(0);
            updatedQuoteValues["expirationDate"] = expirationDate.toISOString()
        }

        apiClient.patch(`/quote/${quoteDetails.id}`, updatedQuoteValues)
            .then(response => {
                setPendingChanges(false);
                setQuoteDetails({
                    ...quoteDetails,
                    ["status"]: response.data.status,
                    ["wonLost"]: response.data.wonLost,
                    ["notes"]: response.data.notes,
                    ["customerPONumber"]: response.data.customerPONumber,
                    ["projectName"]: response.data.projectName,
                    ["comments"]: createCommentsFromNotes(response.data.notes),
                    ["expirationDate"]: response.data.expirationDate,
                    ["salesPersonUserId"]: response.data.salesPersonUserId,
                    ["salesPerson"]: response.data.salesPerson
                });
                setSalespersonValue(response.data.salesPerson.fullName);
                setSendingUpdate(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                else if (err.response.status === 400) {
                    alert(err.response.data);
                    setPendingChanges(false);
                    window.location.reload(false);
                }
                else {
                    alert(`Failed to edit quote status. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                }
                setPendingChanges(false);
                setSendingUpdate(false);
            });
    }

    const handlePartEdit = (partId) => {
        const position = quoteDetails.parts.map(entry => entry.id).indexOf(partId);
        setPartToEdit(position);
        setAddEditPartModalOpen(true);
    }

    const handlePartDeleteRow = (partId) => {
        const part = quoteDetails.parts.filter((currPart, idx) => currPart.id === partId)[0];
        setPartToDelete(part);
        setDeletePartModalOpen(true);
    }

    const handleAddEditPart = (partRequest) => {
        setPartEditModalLoading(true);

        if (partToEdit === null) {
            const newPartAssociationBody = {
                "id": partRequest.id,
                "quantity": partRequest.quantity
            }

            apiClient.post(`/quote/${quoteDetails.id}/part`, newPartAssociationBody)
                .then(response => {
                    quoteDetails.parts = [...quoteDetails.parts, response.data]
                    recalculatePartAndAssemblyTotal(quoteDetails);
                    setQuoteDetails(quoteDetails);
                    setPartEditModalLoading(false);
                    setAddEditPartModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    else if (err.response.status === 400) {
                        alert(err.response.data);
                    }
                    else {
                        alert(`Failed to add new part. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    }
                    setPartEditModalLoading(false);
                });
        }
        else {
            const updatedPartAssociationBody = {
                "id": partRequest.id,
                "quantity": partRequest.quantity
            }

            apiClient.patch(`/quote/${quoteDetails.id}/part`, updatedPartAssociationBody)
                .then(response => {
                    var editedPart = quoteDetails.parts[partToEdit];
                    editedPart.quantity = parseFloat(partRequest.quantity);
                    editedPart.total = parseFloat(response.data.total);
                    quoteDetails.parts[partToEdit] = editedPart;
                    recalculatePartAndAssemblyTotal(quoteDetails);
                    setQuoteDetails(quoteDetails);
                    setPartEditModalLoading(false);
                    setAddEditPartModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to update existing part. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setPartEditModalLoading(false);
                });
        }
    }

    const handleSendDeletePartRequest = (targetPartId) => {
        setDeleteModalLoading(true);

        apiClient.delete(`/quote/${quoteDetails.id}/part/${targetPartId}`)
            .then(response => {
                setPartToDelete(null);
                var newPartEntries = quoteDetails.parts.filter((currPart, idx) => currPart.id !== targetPartId)
                quoteDetails.parts = newPartEntries;
                var newQuoteDetails = recalculatePartAndAssemblyTotal(quoteDetails);
                setQuoteDetails(newQuoteDetails);
                setDeleteModalLoading(false);
                setDeletePartModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete part. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleAssemblyEdit = (assemblyId) => {
        const position = quoteDetails.assemblies.map(entry => entry.id).indexOf(assemblyId);
        setAssemblyToEdit(position);
        setAddEditAssemblyModalOpen(true);
    }

    const handleAssemblyDeleteRow = (assemblyId) => {
        const subAssembly = quoteDetails.assemblies.filter((currAssembly, idx) => currAssembly.id === assemblyId)[0];
        setAssemblyToDelete(subAssembly);
        setDeleteAssemblyModalOpen(true);
    }

    const handleAddEditAssembly = (subAssemblyRequest) => {
        setAssemblyEditModalLoading(true);

        if (assemblyToEdit === null) {
            var newAssemblyAssociationBody = {
                "id": subAssemblyRequest.id,
                "quantity": subAssemblyRequest.quantity
            }

            if (subAssemblyRequest.hasOwnProperty("overwrittenContingencyPercentageString")) {
                newAssemblyAssociationBody['overrideContingencyPercentage'] = formatStringPercentageToFloat(subAssemblyRequest.overwrittenContingencyPercentageString);
            }

            if (subAssemblyRequest.hasOwnProperty("overwrittenProfitMarginPercentageString")) {
                newAssemblyAssociationBody['overrideProfitMarginPercentage'] = formatStringPercentageToFloat(subAssemblyRequest.overwrittenProfitMarginPercentageString)
            }

            if (subAssemblyRequest.hasOwnProperty("overwrittenUnitPriceString")) {
                newAssemblyAssociationBody['overrideUnitPrice'] = formatStringMoneyToFloat(subAssemblyRequest.overwrittenUnitPriceString)
            }

            apiClient.post(`/quote/${quoteDetails.id}/assembly`, newAssemblyAssociationBody)
                .then(response => {
                    quoteDetails.assemblies = [...quoteDetails.assemblies, response.data]
                    var newQuoteDetails = recalculatePartAndAssemblyTotal(quoteDetails);
                    setQuoteDetails(newQuoteDetails);
                    setAssemblyEditModalLoading(false);
                    setAddEditAssemblyModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    else if (err.response.status === 400) {
                        alert(err.response.data);
                    }
                    else {
                        alert(`Failed to add new Assembly. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    }
                    setAssemblyEditModalLoading(false);
                });
        }
        else {
            const updatedAssemblyAssociationBody = {
                "id": subAssemblyRequest.id,
                "quantity": subAssemblyRequest.quantity
            }

            if (subAssemblyRequest.hasOwnProperty("overwrittenContingencyPercentageString")) {
                updatedAssemblyAssociationBody['overrideContingencyPercentage'] = formatStringPercentageToFloat(subAssemblyRequest.overwrittenContingencyPercentageString);
            }

            if (subAssemblyRequest.hasOwnProperty("overwrittenProfitMarginPercentageString")) {
                updatedAssemblyAssociationBody['overrideProfitMarginPercentage'] = formatStringPercentageToFloat(subAssemblyRequest.overwrittenProfitMarginPercentageString)
            }

            if (subAssemblyRequest.hasOwnProperty("overwrittenUnitPriceString")) {
                updatedAssemblyAssociationBody['overrideUnitPrice'] = formatStringMoneyToFloat(subAssemblyRequest.overwrittenUnitPriceString)
            }
            apiClient.patch(`/quote/${quoteDetails.id}/assembly`, updatedAssemblyAssociationBody)
                .then(response => {
                    var editedAssembly = quoteDetails.assemblies[assemblyToEdit];
                    editedAssembly.quantity = subAssemblyRequest.quantity;
                    editedAssembly.contingencyPercentage = response.data.contingencyPercentage;
                    editedAssembly.profitMarginPercentage = response.data.profitMarginPercentage;
                    editedAssembly.unitPrice = response.data.unitPrice;
                    editedAssembly.total = response.data.total;
                    quoteDetails.assemblies[assemblyToEdit] = editedAssembly;
                    var newQuoteDetails = recalculatePartAndAssemblyTotal(quoteDetails);
                    setQuoteDetails(newQuoteDetails);
                    setAssemblyEditModalLoading(false);
                    setAddEditAssemblyModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to update existing Assembly. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setAssemblyEditModalLoading(false);
                });
        }
    }

    const handleSendDeleteAssemblyRequest = (targetAssemblyId) => {
        setDeleteModalLoading(true);

        apiClient.delete(`/quote/${quoteDetails.id}/assembly/${targetAssemblyId}`)
            .then(response => {
                var newAssemblyEntries = quoteDetails.assemblies.filter((currAssembly, idx) => currAssembly.id !== targetAssemblyId);
                quoteDetails.assemblies = newAssemblyEntries;
                var newQuoteDetails = recalculatePartAndAssemblyTotal(quoteDetails);
                setQuoteDetails({
                    ...quoteDetails,
                    ["assemblies"]: newAssemblyEntries,
                    ["partTotal"]: newQuoteDetails.partTotal,
                    ["assemblyTotal"]: newQuoteDetails.assemblyTotal
                });
                setDeleteModalLoading(false);
                setDeleteAssemblyModalOpen(false);
                setAssemblyToDelete(null);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete assembly. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleDocumentDeleteRow = (documentId) => {
        const document = quoteDetails.documents.filter((currDocument, idx) => currDocument.id === documentId)[0];
        setDocumentToDelete(document);
        setDeleteDocumentModalOpen(true);
    }

    const handleUploadNewDocument = (file) => {
        setUploadDocumentModalLoading(true);

        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("userId", getLocalStorage("userId"));
        formData.append("entityType", EntityTypes.Quote);
        formData.append("entityId", quoteDetails.id);

        apiClient.post(`/document`, formData)
            .then(response => {
                var newDocumentEntries = [
                    ...quoteDetails.documents,
                    response.data
                ];
                setQuoteDetails({
                    ...quoteDetails,
                    ["documents"]: newDocumentEntries
                });
                setUploadDocumentModalOpen(false);
                setUploadDocumentModalLoading(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to add new document. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setUploadDocumentModalLoading(false);
            });
    }

    const handleSendDeleteDocumentRequest = (targetDocumentId) => {
        setDeleteModalLoading(true);

        apiClient.delete(`/document/${targetDocumentId}`)
            .then(response => {
                setDocumentToDelete(null);
                var newDocumentEntries = quoteDetails.documents.filter((currDocument, idx) => currDocument.id !== targetDocumentId)
                setQuoteDetails({
                    ...quoteDetails,
                    ["documents"]: newDocumentEntries
                });
                setDeleteModalLoading(false);
                setDeleteDocumentModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete document. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const handleCopyQuote = (copiedQuoteDetails, _) => {
        setCopyQuoteModalLoading(true);
        const copyQuoteBody = {
            "clientId": copiedQuoteDetails.clientId,
            "projectName": copiedQuoteDetails.projectName,
            "customerPONumber": copiedQuoteDetails.customerPONumber,
            "userId": getLocalStorage("userId"),
            "salesPersonUserId": getLocalStorage("userId"),
            "attention": copiedQuoteDetails.attention
        }

        apiClient.post(`/quote/copy/${quoteDetails.id}?fromQuoteDetails=true`, copyQuoteBody)
            .then(response => {
                navigate(`/quote/${response.data.id}`);
                setCopyQuoteModalLoading(false);
                setCopyQuoteModalOpen(false);
                setQuoteDetails(response.data);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to copy quote to new quote. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setCopyQuoteModalLoading(false);
            });
    }


    const handleExportAsPdf = (exportQuoteDetails) => {
        setExportingAsPdf(true);
        var termsOfPayment = DefaultTermsOfPayment.filter(currTerm => currTerm.id === parseInt(exportQuoteDetails.termsOfPaymentId))[0].fullValue;

        if (parseInt(exportQuoteDetails.termsOfPaymentId) === 4) termsOfPayment = exportQuoteDetails.customTermsOfPayment;
        var exportQuoteDetails = {
            "freightIncluded": exportQuoteDetails.freightIncluded === "true" || exportQuoteDetails.freightIncluded === true ? true : false,
            "installationIncluded": exportQuoteDetails.installationIncluded === "true" || exportQuoteDetails.installationIncluded === true ? true : false,
            "leadTime": exportQuoteDetails.leadTime,
            "termsOfPayment": termsOfPayment
        }

        apiClient.post(`/quote/generate-quote-pdf/${quoteDetails.id}?requestedByUserId=${getLocalStorage("userId")}&showAssemblyPrices=${showAssemblyPricesOnExportedPdf}`, exportQuoteDetails)
            .then(response => {
                var newDocumentEntries = [
                    ...quoteDetails.documents,
                    response.data
                ];
                setQuoteDetails({
                    ...quoteDetails,
                    ["documents"]: newDocumentEntries
                });
                setExportAsPdfModalOpen(false);
                setExportingAsPdf(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                console.log(err);
                alert(`Failed to export as pdf. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setExportingAsPdf(false);
            });
    }

    return (
        <>
            {isPending && <p>Loading information...</p>}
            {!isPending && error !== null &&
                <div className="error">
                    {error}
                </div>
            }
            {!isPending && error === null &&
                <div className="quote-information">
                    <h1>Quote {quoteDetails.quoteNumber}</h1>
                    <div className="flex-parent-element" style={{ width: "75%" }}>
                        <div className="form-group flex-child-element">
                            <div className="flex-parent-element">
                                <div className="flex-child-element">
                                    <label htmlFor="quoteNumber">Quote Number:</label>
                                </div>
                                <div className="flex-child-element">
                                    <input name="quoteNumber" className="readonly-input"
                                        value={quoteDetails.quoteNumber}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="flex-parent-element">
                                <div className="flex-child-element">
                                    <label htmlFor="salesPerson">Sales Person:</label>
                                </div>
                                <div className="flex-child-element">
                                    <SearchableDropdown
                                        options={users}
                                        label="fullName"
                                        id="id"
                                        selectedVal={salesPersonValue}
                                        handleChange={(val) => typeof (val) === 'number' ? handleFormUpdate(val) : setSalespersonValue(val)}
                                    />
                                </div>
                            </div>
                            <div className="flex-parent-element">
                                <div className="flex-child-element">
                                    <label htmlFor="quoteAttention">Attention:</label>
                                </div>
                                <div className="flex-child-element">
                                    <input name="quoteAttention"
                                        value={quoteDetails.quoteAttention}
                                        onChange={handleFormUpdate}
                                    />
                                </div>
                            </div>
                            <div className="flex-parent-element">
                                <div className="flex-child-element">
                                    <label htmlFor="projectName">Project Name:</label>
                                </div>
                                <div className="flex-child-element">
                                    <input name="projectName"
                                        value={quoteDetails.projectName}
                                        onChange={handleFormUpdate}
                                    />
                                </div>
                            </div>
                            <div className="flex-parent-element">
                                <div className="flex-child-element">
                                    <label htmlFor="customerPONumber">Customer PO Number:</label>
                                </div>
                                <div className="flex-child-element">
                                    <input
                                        name="customerPONumber"
                                        value={quoteDetails.customerPONumber}
                                        onChange={handleFormUpdate}
                                    />
                                </div>
                            </div>
                            <div className="flex-parent-element">
                                <div className="flex-child-element">
                                    <label htmlFor="status">Status:</label>
                                </div>
                                <div className="flex-child-element">
                                    <select name="status" value={quoteDetails.status} onChange={handleFormUpdate}>
                                        {
                                            Object.keys(QuoteStatusIdToValueMappings).map((key, idx) => {
                                                return <option key={key} value={key}>{QuoteStatusIdToValueMappings[key]}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            {(parseInt(quoteDetails.status) === parseInt(QuoteStatusValueToIdMappings.Submitted) || parseInt(quoteDetails.status) === parseInt(QuoteStatusValueToIdMappings.Finalized)) &&
                                <div className="flex-parent-element">
                                    <div className="flex-child-element">
                                        <label htmlFor="expirationDate">Expiration Date:</label>
                                    </div>
                                    <div className="flex-child-element">
                                        <DatePicker
                                            selected={quoteDetails.expirationDate === null ? moment().add(30, 'days').toDate() : new Date(quoteDetails.expirationDate)}
                                            onChange={(date) => {
                                                setPendingChanges(true);
                                                setQuoteDetails({
                                                    ...quoteDetails,
                                                    ["expirationDate"]: date
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {parseInt(quoteDetails.status) === parseInt(QuoteStatusValueToIdMappings.Finalized) &&
                                <div className="flex-parent-element">
                                    <div className="flex-child-element">
                                        <label htmlFor="wonLost">Won/Lost:</label>
                                    </div>
                                    <div className="flex-child-element">
                                        <select name="wonLost" value={quoteDetails.wonLost} onChange={handleFormUpdate}>
                                            {
                                                Object.keys(WonLostIdToValueMappings).map((key, idx) => {
                                                    return <option key={key} value={key}>{WonLostIdToValueMappings[key]}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            }
                            {pendingChanges && !sendingUpdate &&
                                <button type="button" style={{ margin: "auto", width: "15%" }} className="btn" onClick={handleQuoteDetailsUpdate}>Submit Changes</button>
                            }
                            {!pendingChanges && parseInt(quoteDetails.status) === parseInt(QuoteStatusValueToIdMappings.Submitted) && !exportingAsPdf &&
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="showAssemblyPricesOnExportedPdf">Show Assembly Pricing?{"\t"}
                                            <input
                                                name="showAssemblyPricesOnExportedPdf"
                                                type="checkbox"
                                                checked={showAssemblyPricesOnExportedPdf}
                                                onChange={() => setShowAssemblyPricesOnExportedPdf(!showAssemblyPricesOnExportedPdf)}
                                            />
                                        </label>
                                    </div>
                                    <button type="button" style={{ margin: "auto", width: "15%" }} className="btn" onClick={() => setExportAsPdfModalOpen(true)}>Export as PDF</button>
                                </div>
                            }
                            {!pendingChanges && parseInt(quoteDetails.status) === parseInt(QuoteStatusValueToIdMappings.Submitted) && exportingAsPdf &&
                                <button type="button" style={{ margin: "auto", width: "15%" }} className="btn" disabled>Exporting...</button>
                            }
                            {pendingChanges && sendingUpdate &&
                                <button type="button" style={{ margin: "auto", width: "15%" }} className="btn" onClick={handleQuoteDetailsUpdate} disabled>Submitting...</button>
                            }
                        </div>
                        <div className="form-group flex-child-element" style={{ margin: "auto" }}>
                            <h4 style={{ marginTop: "1%" }}>Client Information</h4>
                            <div className="flex-parent-element">
                                <div className="flex-child-element">
                                    <label htmlFor="clientName">Client:</label>
                                </div>
                                <div className="flex-child-element">
                                    <input name="clientName"
                                        value={quoteDetails.client.name}
                                        readOnly
                                        className="readonly-input"
                                    />
                                </div>
                            </div>
                            <div className="flex-parent-element">
                                <div className="flex-child-element">
                                    <label htmlFor="primaryContactName">Primary Contact:</label>
                                </div>
                                <div className="flex-child-element">
                                    <input name="clientName"
                                        value={quoteDetails.client.primaryContact.fullName}
                                        readOnly
                                        className="readonly-input"
                                    />
                                </div>
                            </div>
                            <div className="flex-parent-element">
                                <div className="flex-child-element">
                                    <label htmlFor="primaryContactName">Phone Number:</label>
                                </div>
                                <div className="flex-child-element">
                                    <input name="clientName"
                                        value={quoteDetails.client.primaryContact.phoneNumber}
                                        readOnly
                                        className="readonly-input"
                                    />
                                </div>
                            </div>
                            <button type="button" style={{ margin: "auto", width: "17%" }} className="btn" onClick={() => { setViewClientDetailsModalOpen(true) }}>View Client Details</button>
                            <h4>Pricing</h4>
                            <div className="flex-parent-element" style={{ marginTop: "0%", marginBottom: "0%" }}>
                                <div className="flex-child-element">
                                    <label htmlFor="quotePartPrice">Total Part Price:</label>
                                </div>
                                <div className="flex-child-element">
                                    <input htmlFor="quotePartPrice"
                                        value={formatMoney(quoteDetails.partTotal)}
                                        readOnly
                                        className="readonly-input"
                                    />
                                </div>
                            </div>
                            <div className="flex-parent-element" style={{ marginTop: "0%", marginBottom: "0%" }}>
                                <div className="flex-child-element">
                                    <label htmlFor="quoteAssemblyPrice">Total Assembly Price:</label>
                                </div>
                                <div className="flex-child-element">
                                    <input htmlFor="quoteAssemblyPrice"
                                        value={formatMoney(quoteDetails.assemblyTotal)}
                                        readOnly
                                        className="readonly-input"
                                    />
                                </div>
                            </div>
                            <div className="flex-parent-element" style={{ marginTop: "0%", marginBottom: "0%" }}>
                                <div className="flex-child-element">
                                    <label htmlFor="quoteTotalPrice">Total Quote Price:</label>
                                </div>
                                <div className="flex-child-element">
                                    <input htmlFor="quoteTotalPrice"
                                        value={formatMoney(quoteDetails.assemblyTotal + quoteDetails.partTotal)}
                                        readOnly
                                        className="readonly-input"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-parent-element">
                        <div className="part-info">
                            {!isPending && (error === null) && quoteDetails.parts.length > 0 &&
                                <div className="flex-child-element">
                                    <h3>Parts</h3>
                                    <Table
                                        data={quoteDetails.parts}
                                        columns={GetPartTableHeadersForAssembly(calculateSum(quoteDetails.parts, "total"))}
                                        deleteRowFunction={handlePartDeleteRow}
                                        editRowFunction={handlePartEdit}
                                        initialState={{ sortBy: [{ id: "partName", desc: false }] }}
                                        actions={quoteDetails.status === 3 ? [] : ['ModalEdit', 'ModalDelete']}
                                        hasFooters={true}
                                    />
                                    <button className="btn" onClick={() => {
                                        setAddEditPartModalOpen(true);
                                        setPartToEdit(null);
                                    }}
                                        disabled={isPendingParts}
                                    >Add New Part</button>
                                </div>
                            }
                            {!isPending && (error === null) && quoteDetails.parts.length === 0 &&
                                <div className="flex-child-element">
                                    <h3>Parts</h3>
                                    <button className="btn" onClick={() => setAddEditPartModalOpen(true)}>Add New Part</button>
                                </div>
                            }
                        </div>
                        <div className="assembly-info">
                            {!isPending && (error === null) && quoteDetails.assemblies.length > 0 &&
                                <div className="flex-child-element">
                                    <h3>Assemblies</h3>
                                    <Table
                                        data={quoteDetails.assemblies}
                                        columns={GetSubAssemblyTableHeadersForSubAssembly(quoteDetails.assemblyTotal, "Assembly Total")}
                                        deleteRowFunction={handleAssemblyDeleteRow}
                                        editRowFunction={handleAssemblyEdit}
                                        initialState={{ sortBy: [{ id: "assemblyNumber", desc: false }] }}
                                        actions={quoteDetails.status === 3 ? [] : ['ModalEdit', 'ModalDelete', 'LinkToDetailPage']}
                                        detailPagePrefix="/assembly"
                                        hasFooters={true}
                                    />
                                    <button
                                        className="btn"
                                        onClick={() => {
                                            setAssemblyToEdit(null);
                                            setAddEditAssemblyModalOpen(true);
                                        }}
                                        disabled={isPendingSubAssemblies}
                                    >Add New Assembly</button>
                                </div>
                            }
                            {!isPending && (error === null) && quoteDetails.assemblies.length === 0 &&
                                <div className="flex-child-element">
                                    <h3>Assemblies</h3>
                                    <button className="btn" onClick={() => setAddEditAssemblyModalOpen(true)}>Add New Assembly</button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="flex-parent-element">
                        <div className="flex-child-element">
                            <h3>Documents</h3>
                            <Table
                                data={quoteDetails.documents}
                                columns={DocumentTableHeaders}
                                deleteRowFunction={handleDocumentDeleteRow}
                                editRowFunction={() => { }}
                                initialState={{ sortBy: [{ id: "dateCreatedCST", desc: true }] }}
                                actions={["DownloadFile", "ModalDelete"]}
                                hasFooters={false}
                            />
                            <button className="btn" onClick={() => setUploadDocumentModalOpen(true)}>Upload Document</button>
                        </div>
                    </div>
                    <div className="flex-parent-element">
                        <div className="flex-child-element">
                            <CommentSection
                                currentUser={{
                                    currentUserId: getLocalStorage('userId'),
                                    currentUserImg:
                                        `https://ui-avatars.com/api/name=${getLocalStorage('userFullName').replace(' ', '%20')}&background=random`,
                                    currentUserFullName: getLocalStorage('userFullName')
                                }}
                                commentData={quoteDetails.comments}
                                onSubmitAction={handleNewNote}
                                onDeleteAction={handleDeleteNote}
                                onEditAction={handleEditNote}
                                removeEmoji={true}
                                advancedInput={true}
                            />
                        </div>
                    </div>
                    {!isPending && (error === null) && addEditPartModalOpen &&
                        <AddEditPartForAssembly
                            closeModal={() => {
                                setPartToEdit(null);
                            }}
                            onModalSubmit={handleAddEditPart}
                            defaultValue={partToEdit !== null && quoteDetails.parts[partToEdit]}
                            modalSubmitLoading={partEditModalLoading}
                            parts={parts}
                            isPending={isPendingParts}
                            error={errorParts}
                            cancelFunction={() => {
                                setAddEditPartModalOpen(false);
                            }}
                        />
                    }
                    {!isPending && (error === null) && deletePartModalOpen &&
                        <DeleteEntityModal
                            closeModal={() => {
                                setPartToDelete(null);
                            }}
                            cancelFunction={() => {
                                setDeletePartModalOpen(false);
                            }}
                            onModalSubmit={handleSendDeletePartRequest}
                            entityType="Part"
                            currentEntity={partToDelete !== null && partToDelete}
                            currentEntityString={partToDelete !== null && (partToDelete.partName ?? "")}
                            modalSubmitLoading={deleteModalLoading}
                        />
                    }
                    {!isPending && (error === null) && addEditAssemblyModalOpen &&
                        <AddEditAssembly
                            closeModal={() => {
                                setAssemblyToEdit(null);
                            }}
                            cancelFunction={() => {
                                setAddEditAssemblyModalOpen(false);
                            }}
                            onModalSubmit={handleAddEditAssembly}
                            defaultValue={assemblyToEdit !== null && quoteDetails.assemblies[assemblyToEdit]}
                            modalSubmitLoading={assemblyEditModalLoading}
                            assemblies={assemblies}
                            isPending={isPendingSubAssemblies}
                            error={errorSubAssemblies}
                            addingAssemblyToQuote={true}
                            assemblyToEditObject={assemblyToEdit === null ? null : quoteDetails.assemblies[assemblyToEdit]}
                        />
                    }
                    {!isPending && (error === null) && deleteAssemblyModalOpen &&
                        <DeleteEntityModal
                            closeModal={() => {
                                setAssemblyToDelete(null);
                            }}
                            cancelFunction={() => {
                                setDeleteAssemblyModalOpen(false);
                            }}
                            onModalSubmit={handleSendDeleteAssemblyRequest}
                            entityType="Assembly"
                            currentEntity={assemblyToDelete !== null && assemblyToDelete}
                            currentEntityString={assemblyToDelete !== null && (assemblyToDelete.assemblyNumber ?? "")}
                            modalSubmitLoading={deleteModalLoading}
                        />
                    }
                    {viewClientDetailsModalOpen &&
                        <AddEditClientModal
                            closeModal={() => {
                                setViewClientDetailsModalOpen(false);
                            }}
                            onModalSubmit={() => { }}
                            defaultValue={quoteDetails.client}
                            modalSubmitLoading={false}
                            readOnly={true}
                        />
                    }
                    {uploadDocumentModalOpen &&
                        <UploadDocumentModal
                            closeModal={() => {
                                setUploadDocumentModalOpen(false);
                            }}
                            onModalSubmit={handleUploadNewDocument}
                            uploadLoading={uploadDocumentModalLoading}
                        />
                    }
                    {!isPending && (error === null) && deleteDocumentModalOpen &&
                        <DeleteEntityModal
                            closeModal={() => {
                                setDocumentToDelete(null);
                            }}
                            cancelFunction={() => {
                                setDeleteDocumentModalOpen(false);
                            }}
                            onModalSubmit={handleSendDeleteDocumentRequest}
                            entityType="Document"
                            currentEntity={documentToDelete !== null && documentToDelete}
                            currentEntityString={documentToDelete !== null && (documentToDelete.fileName ?? "")}
                            modalSubmitLoading={deleteModalLoading}
                        />
                    }
                    {!isPending && (error === null) && exportAsPdfModalOpen &&
                        <ExportQuoteAsPdfModal
                            defaultValue={null}
                            closeModal={() => {
                                setExportAsPdfModalOpen(false);
                            }}
                            onModalSubmit={handleExportAsPdf}
                            modalSubmitLoading={exportingAsPdf}
                            cancelFunction={() => {
                                setExportAsPdfModalOpen(false);
                            }}
                        />
                    }
                    <button
                        className="btn add-new-btn"
                        onClick={() => { setCopyQuoteModalOpen(true) }}
                    >Copy to New Quote</button>
                    {copyQuoteModalOpen &&
                        <AddNewQuoteModal
                            closeModal={() => { }}
                            onModalSubmit={handleCopyQuote}
                            defaultValue={{
                                "clientId": quoteDetails["client_Id"],
                                "customerPONumber": quoteDetails["customerPONumber"],
                                "projectName": quoteDetails["projectName"]
                            }}
                            modalSubmitLoading={copyQuoteModalLoading}
                            clientList={clients}
                            isPendingClients={isPendingClient}
                            errorClient={errorClient}
                            assemblies={assemblies}
                            isPendingAssemblies={isPendingSubAssemblies}
                            errorAssemblies={errorSubAssemblies}
                            cancelFunction={() => {
                                setCopyQuoteModalLoading(false);
                                setCopyQuoteModalOpen(false);
                            }}
                            disableAddingInitialAssembly={true}
                        />
                    }
                </div>
            }
        </>
    );
}

export default QuoteDetail;