import { useState } from "react";
import { formatMoney } from "../../../utils/utils";
import SearchableDropdown from "../../SearchableDropdown/SearchableDropdown";
const AddEditPartForAssembly = ({ closeModal, onModalSubmit, defaultValue, modalSubmitLoading, parts, error, isPending, cancelFunction }) => {
    const [formState, setFormState] = useState(defaultValue ||
    {
        "id": parts[0].id,
        "quantity": 1
    }
    );
    const [value, setValue] = useState("Select a part...");

    const [errors, setErrors] = useState("");

    const requiredFields = ['quantity'];

    const validateForm = () => {
        if (parseFloat(formState.quantity) > 0 && formState.id !== "Loading parts..." && formState.id !== "Unable to load parts") {
            setErrors("");
            return true;
        }

        let errorFields = []
        for (const [key, value] of Object.entries(formState)) {
            if (!value && requiredFields.includes(key)) errorFields.push(key);
        }
        setErrors(errorFields.join(', '));
        return false;
    }

    const getPartFromId = (id) => {
        const partPos = parts.map(currPart => currPart.id).indexOf(id);
        return parts[partPos];
    }

    const handleFormUpdate = (e) => {
        if (typeof (e) === "number") {
            setFormState({
                ...formState,
                ["id"]: e
            })
            setValue(getPartFromId(e).partName);
        }
        else {
            setFormState({
                ...formState,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onModalSubmit(formState);
            closeModal();
        }

    }

    return (
        <div className="base-modal-container" onClick={(e) => {
            if (e.target.className === "base-modal-container") closeModal();
        }}>
            <div className="base-modal">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="id">Part</label>
                        {formState.hasOwnProperty('partName') &&
                            <select name="id" value={formState.id} onChange={handleFormUpdate} disabled>
                                <option key={formState.id} value={formState.id}>{formState.partName} - {formState.vendor.name} - {formatMoney(formState.price)}</option>
                            </select>
                        }
                        {!formState.hasOwnProperty('partName') && isPending && !error &&
                            <select name="id" value={formState.id} onChange={handleFormUpdate}>
                                <option key={1} value="Loading parts...">Loading parts...</option>
                            </select>
                        }
                        {!formState.hasOwnProperty('partName') && !isPending && !error &&
                            <SearchableDropdown
                                options={parts}
                                label="dropdownDisplayValue"
                                id="id"
                                selectedVal={value}
                                handleChange={(val) => typeof (val) === 'number' ? handleFormUpdate(val) : setValue(val)}
                            />
                        }
                        {!formState.hasOwnProperty('partName') && !isPending && error &&
                            <select name="id" value={formState.id} onChange={handleFormUpdate}>
                                <option key={1} value="Unable to load parts">Unable to load parts</option>
                            </select>
                        }
                    </div>
                    <div className="form-group">
                        <label htmlFor="quantity">Quantity</label>
                        <input name="quantity" value={formState.quantity} onChange={handleFormUpdate} />
                    </div>
                    {errors && <div className="error">{`Please include: ${errors}`}</div>}
                    {!modalSubmitLoading &&
                        <div>
                            <button className="btn inline-btn" type="button" onClick={() => cancelFunction()}>Cancel</button>
                            <button className="btn" type="submit">Submit</button>
                        </div>
                    }
                    {modalSubmitLoading && <button disabled className="btn" type="submit">Submitting...</button>}
                </form>
            </div>
        </div>
    );
}

export default AddEditPartForAssembly;