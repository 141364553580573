import { FileUploader } from "react-drag-drop-files";
import { Link } from 'react-router-dom';
import "./UploadDocumentModal.css"

const UploadDocumentModal = ({ closeModal, onModalSubmit, fileTypes = ["png", "jpg", "jpeg", "docx", "doc", "xlsx", "pdf", "dwg", "dxf"], uploadLoading = false, allowPartTemplateDownload = false }) => {
    const handleSubmit = (file) => {
        onModalSubmit(file);
    }

    return (
        <div className="base-modal-container" onClick={(e) => {
            if (e.target.className === "base-modal-container") closeModal();
        }}>
            <div className="base-modal">
                <FileUploader
                    name="file"
                    types={fileTypes}
                    minSize={0}
                    maxSize={10}
                    classes="drop_area"
                    onDrop={handleSubmit}
                    onSelect={handleSubmit}
                />
                <div style={{marginTop: "5px"}}>
                    {allowPartTemplateDownload && <Link to="/ImportPartTemplate.xlsx" target="_blank" download>Download Import Template</Link>}
                </div>
                <div>
                    {!uploadLoading && <button className="btn" type="button" onClick={closeModal}>Close</button>}
                    {uploadLoading && <button className="btn" type="button" disabled>Uploading...</button>}
                </div>

            </div>
        </div>
    );
}

export default UploadDocumentModal;