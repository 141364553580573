import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import ColumnFilter from '../ColumnFilters/ColumnFilter';
import { BsFillTrashFill, BsFillPencilFill, BsFillEyeFill } from "react-icons/bs";
import "./NonPaginatedTable.css";

const NonPaginatedTable = ({ data, columns, deleteRowFunction, editRowFunction, initialState, actionGoesOnRow = 1, actions = ['ModalEdit', 'ModalDelete'], detailPagePrefix = null, useDefaultFilter = true, hasFooters = true, renderHeaders = true }) => {
    const defaultColumn = useMemo(
        () => ({
            Filter: ColumnFilter
        }),
        []
    )
    const navigate = useNavigate();

    useEffect(() => { }, [data]);

    const tableInstance = useTable({ columns, data, defaultColumn, initialState: initialState },
        useFilters,
        useSortBy
    );
    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        setFilter,
        prepareRow } = tableInstance;
    let headerRowCount = 0;
    return (
        <>
            <table {...getTableProps()} align='center'>
                {renderHeaders &&
                    <thead>
                        {headerGroups.map((headerGroup) => {
                            headerRowCount += 1;
                            return (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                            </span>
                                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                                        </th>
                                    ))}
                                    {headerRowCount === actionGoesOnRow && actions.length !== 0 && <th>Actions</th>}
                                    {headerRowCount !== actionGoesOnRow && actions.length !== 0 && <th></th>}
                                </tr>
                            )
                        })}
                    </thead>
                }
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}</td>
                                })}
                                {
                                    actions.length !== 0 && <td key={`actions-${row.id}`}>
                                        <span className="actions">
                                            {actions.includes('ModalDelete') && <BsFillTrashFill color="red" fill="red" onClick={() => deleteRowFunction(row.original.id)} />}
                                            {actions.includes('ModalEdit') && <BsFillPencilFill onClick={() => (editRowFunction(row.original.id))} />}
                                            {actions.includes('LinkToDetailPage') && <BsFillEyeFill onClick={() => (navigate(`${detailPagePrefix}/${row.original.id}`))} />}
                                        </span>
                                    </td>
                                }

                            </tr>
                        )
                    })
                    }
                </tbody>
                {hasFooters &&
                    <tfoot>
                        {footerGroups.map(footerGroup => (
                            <tr {...footerGroup.getFooterGroupProps()}>
                                {footerGroup.headers.map(column => (
                                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                ))}
                                {actions.length !== 0 && <td></td>}
                            </tr>
                        ))}
                    </tfoot>
                }
            </table>
        </>
    );
}

export default NonPaginatedTable;