const ConfirmationModal = ({ closeModal, onModalSubmit, displayText }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        onModalSubmit();
        
        closeModal();
    }

    return (
        <div className="base-modal-container">
            <div className="base-modal">
                <p>{displayText}</p>
                <button className="btn inline-btn" onClick={ handleSubmit }>Yes</button>
                <button className="btn inline-btn-end" onClick={() => closeModal()}>No</button>
            </div>
        </div>
    );
}
 
export default ConfirmationModal;