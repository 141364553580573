import { useState } from "react";
import { formatMoney } from "../../../utils/utils";

import SearchableDropdown from "../../SearchableDropdown/SearchableDropdown";

const AddEditAssembly = ({ closeModal, onModalSubmit, defaultValue, modalSubmitLoading, assemblies, isPending, error, cancelFunction = () => {}, addingAssemblyToQuote = false, assemblyToEditObject = null }) => {
    const [formState, setFormState] = useState(defaultValue ||
    {
        "id": assemblyToEditObject === null ? assemblies[0].id : assemblyToEditObject.id,
        "quantity": 1
    }
    );

    const [value, setValue] = useState("Select an assembly...");

    const [overwriteAssemblyPricing, setOverwriteAssemblyPricing] = useState(false);
    const [overwrittenContingencyPercentageError, setOverwrittenContingencyPercentageError] = useState(null);
    const [overwrittenProfitMarginPercentageError, setOverwrittenProfitMarginPercentageError] = useState(null);
    const [overwrittenUnitPriceError, setOverwrittenUnitPriceError] = useState(null);
    const [errors, setErrors] = useState("");

    const requiredFields = ['quantity'];

    const validatePercentageString = (percentageString) => {
        const floatValue = percentageString.replace("%", "") / 100;
        if (floatValue > 1 || floatValue <= 0) return false;
        else return true;
    }

    const validateMoneyString = (moneyString) => {
        const floatValue = moneyString.replace("", "") / 100;
        if (floatValue <= 0) return false;
        else return true;
    }

    const validateForm = () => {
        var customContingencyFieldErrors = false;
        var customProfitMarginFieldErrors = false;
        var customUnitPriceFieldErrors = false;
        if (formState.hasOwnProperty("overwrittenContingencyPercentageString") && !validatePercentageString(formState.overwrittenContingencyPercentageString)) {
            setOverwrittenContingencyPercentageError("Contingency Percentage must be less than 100% and greater than 0%");
            customContingencyFieldErrors = true;
        }
        else {
            setOverwrittenContingencyPercentageError("");
            customContingencyFieldErrors = false;
        }
        if (formState.hasOwnProperty("overwrittenProfitMarginPercentageString") && !validatePercentageString(formState.overwrittenProfitMarginPercentageString)) {
            setOverwrittenProfitMarginPercentageError("Profit Margin Percentage must be less than 100% and greater than 0%");
            customProfitMarginFieldErrors = true;
        }
        else {
            setOverwrittenProfitMarginPercentageError("");
            customProfitMarginFieldErrors = false;
        }

        if (formState.hasOwnProperty("overwrittenUnitPriceString") && !validateMoneyString(formState.overwrittenUnitPriceString)) {
            setOverwrittenUnitPriceError("Unit Price must be above $0");
            customUnitPriceFieldErrors = true;
        }
        else {
            setOverwrittenUnitPriceError("");
            customUnitPriceFieldErrors = false;
        }
        if (!customContingencyFieldErrors && !customProfitMarginFieldErrors && !customUnitPriceFieldErrors && parseFloat(formState.quantity) > 0 && formState.id !== "Loading assemblies..." && formState.id !== "Unable to load assemblies") {
            setErrors("");
            return true;
        }

        let errorFields = []
        for (const [key, value] of Object.entries(formState)) {
            if (!value && requiredFields.includes(key)) errorFields.push(key);
        }
        setErrors(errorFields.join(', '));
        return false;
    }

    const handleChangingAssemblyOption = (newIdValue) => {
        var newFormState = formState;
        delete newFormState["overwrittenContingencyPercentageString"];
        delete newFormState["overwrittenProfitMarginPercentageString"];
        delete newFormState["overwrittenUnitPriceString"];
        setFormState({
            ...formState,
            ["id"]: newIdValue
        });
    }

    const handleFormUpdate = (e) => {
        if (typeof (e) === "number") {
            handleChangingAssemblyOption(e);
            setValue(getAssemblyFromId(e).assemblyNumber);
        }
        else if (e.target.name === "id") {
            handleChangingAssemblyOption(e.target.value);
        }
        else {
            setFormState({
                ...formState,
                [e.target.name]: e.target.value
            })
        }
    }

    const getAssemblyFromId = (id) => {
        const assemblyPos = assemblies.map(currAssembly => currAssembly.id).indexOf(id);
        return assemblies[assemblyPos];
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onModalSubmit(formState);
            closeModal();
        }

    }

    const getContingencyPercentageString = () => {
        if (assemblyToEditObject === null) {
            var assemblyPosition = assemblies.map(currAssembly => {
                return currAssembly.id;
            }).indexOf(parseInt(formState.id));
            return assemblies[assemblyPosition].contingencyPercentageString;
        }
        else return assemblyToEditObject.contingencyPercentageString;
    }

    const getProfitMarginPercentageString = () => {
        if (assemblyToEditObject === null) {
            var assemblyPosition = assemblies.map(currAssembly => {
                return currAssembly.id;
            }).indexOf(parseInt(formState.id));
            return assemblies[assemblyPosition].profitMarginPercentageString;
        }
        else return assemblyToEditObject.profitMarginPercentageString;

    }

    const getUnitPrice = () => {
        if (assemblyToEditObject === null) {
            var assemblyPosition = assemblies.map(currAssembly => {
                return currAssembly.id;
            }).indexOf(parseInt(formState.id));
            return assemblies[assemblyPosition].unitPriceString;
        }
        else return assemblyToEditObject.unitPriceString;
    }

    return (
        <div className="base-modal-container" onClick={(e) => {
            if (e.target.className === "base-modal-container") closeModal();
        }}>
            <div className="base-modal">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="id">Sub-Assembly</label>
                        {formState.hasOwnProperty('assemblyNumber') &&
                            <select name="id" value={formState.id} onChange={handleFormUpdate} disabled>
                                <option key={formState.id} value={formState.id}>{formState.assemblyNumber}</option>
                            </select>
                        }
                        {!formState.hasOwnProperty('assemblyNumber') && isPending && !error &&
                            <select name="id" value={formState.id} onChange={handleFormUpdate}>
                                <option key={1} value="Loading assemblies...">Loading assemblies...</option>
                            </select>
                        }
                        {!formState.hasOwnProperty('assemblyNumber') && !isPending && !error &&
                            <SearchableDropdown
                                options={assemblies}
                                label="assemblyNumber"
                                id="id"
                                selectedVal={value}
                                handleChange={(val) => typeof (val) === 'number' ? handleFormUpdate(val) : setValue(val)}
                            />
                        }
                        {!formState.hasOwnProperty('assemblyNumber') && !isPending && error &&
                            <select name="id" value={formState.id} onChange={handleFormUpdate} disabled>
                                <option key={1} value="Unable to load assemblies">Unable to load assemblies</option>
                            </select>
                        }
                    </div>
                    <div className="form-group">
                        <label htmlFor="quantity">Quantity</label>
                        <input name="quantity" value={formState.quantity} onChange={handleFormUpdate} />
                    </div>
                    {addingAssemblyToQuote &&
                        <div className="form-group">
                            <label htmlFor="overwriteAssemblyPricing">Overwrite Pricing?{"\t"}
                                <input
                                    name="overwriteAssemblyPricing"
                                    type="checkbox"
                                    checked={overwriteAssemblyPricing}
                                    onChange={() => setOverwriteAssemblyPricing(!overwriteAssemblyPricing)}
                                />
                            </label>
                        </div>
                    }
                    {addingAssemblyToQuote && overwriteAssemblyPricing &&
                        <>
                            <div className="form-group">
                                <label htmlFor="overwrittenContingencyPercentageString">Custom Contingency Percentage:</label>
                                <input
                                    value={formState.hasOwnProperty("overwrittenContingencyPercentageString") ? formState.overwrittenContingencyPercentageString : getContingencyPercentageString()}
                                    name="overwrittenContingencyPercentageString"
                                    onChange={handleFormUpdate}
                                    className="percentage-input"
                                />
                            </div>
                            {overwrittenContingencyPercentageError && <div className="error">{overwrittenContingencyPercentageError}</div>}
                            <div className="form-group">
                                <label htmlFor="overwrittenProfitMarginPercentageString">Custom Profit Margin Percentage:</label>
                                <input
                                    value={formState.hasOwnProperty("overwrittenProfitMarginPercentageString") ? formState.overwrittenProfitMarginPercentageString : getProfitMarginPercentageString()}
                                    name="overwrittenProfitMarginPercentageString"
                                    onChange={handleFormUpdate}
                                    className="percentage-input"
                                />
                            </div>
                            {overwrittenProfitMarginPercentageError && <div className="error">{overwrittenProfitMarginPercentageError}</div>}
                            <div className="form-group">
                                <label htmlFor="overwrittenUnitPriceString">Custom Sales Price</label>
                                <input
                                    value={formState.hasOwnProperty("overwrittenUnitPriceString") ? formState.overWrittenUnitPriceString : getUnitPrice()}
                                    name="overwrittenUnitPriceString"
                                    onChange={handleFormUpdate}
                                    style={{ textAlign: "center" }}
                                />
                            </div>
                            {overwrittenUnitPriceError && <div className="error">{overwrittenUnitPriceError}</div>}
                        </>
                    }

                    {errors && <div className="error">{`Please include: ${errors}`}</div>}
                    {!modalSubmitLoading &&
                        <div>
                            <button className="btn inline-btn" type="button" onClick={() => cancelFunction()}>Cancel</button>
                            <button className="btn" type="submit">Submit</button>
                        </div>
                    }
                    {modalSubmitLoading && <button disabled className="btn" type="submit">Submitting...</button>}
                </form>
            </div>
        </div>
    );
}

export default AddEditAssembly;