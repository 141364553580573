import { useState } from "react";
import SearchableDropdown from "../../SearchableDropdown/SearchableDropdown";

const AddEditCustomTagModal = ({ closeModal, onModalSubmit, defaultValue, modalSubmitLoading, cancelFunction, existingKeys = [] }) => {
    const [formState, setFormState] = useState(defaultValue ||
    {
        "key": "",
        "value": ""
    });
    const [errors, setErrors] = useState("");
    const [useExistingCustomTagKey, setUseExistingCustomTagKey] = useState(true);

    const requiredFields = ["key", "value"];

    const validateForm = () => {
        if (formState.key !== "" && formState.value !== "") {
            setErrors("");
            return true;
        }

        let errorFields = []
        for (const [key, value] of Object.entries(formState)) {
            if (!value && requiredFields.includes(key)) errorFields.push(key);
        }
        setErrors(errorFields.join(', '));
        return false;
    }

    const [value, setValue] = useState("Select an existing key...");

    const handleFormUpdate = (e, updateForKey) => {
        if (updateForKey) {
            setValue(e);
            setFormState({
                ...formState,
                ["key"]: e
            })
        }
        else {
            setFormState({
                ...formState,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onModalSubmit(formState);
            closeModal();
        }
    }

    return (
        <div className="base-modal-container" onClick={(e) => {
            if (e.target.className === "base-modal-container") closeModal();
        }}>
            <div className="base-modal">
                <form onSubmit={handleSubmit}>
                    {useExistingCustomTagKey && existingKeys.length > 0 && 
                        <SearchableDropdown
                            options={existingKeys}
                            label="value"
                            id="id"
                            selectedVal={value}
                            handleChange={(val) => handleFormUpdate(val, true)}
                        />
                    }
                    {(!useExistingCustomTagKey || existingKeys.length === 0) &&
                        <div className="form-group">
                            <label htmlFor="key">Key</label>
                            <input name="key" value={formState.key} onChange={handleFormUpdate} />
                        </div>
                    }
                    <div className="form-group">
                        <label htmlFor="useCustomTagValue">Use Existing Key Value?{"\t"}
                            <input
                                name="useCustomTagValue"
                                type="checkbox"
                                checked={useExistingCustomTagKey && existingKeys.length > 0}
                                onChange={() => setUseExistingCustomTagKey(!useExistingCustomTagKey)}
                                disabled={existingKeys.length === 0}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="value">Value</label>
                        <input name="value" value={formState.value} onChange={handleFormUpdate} />
                    </div>
                    {errors && <div className="error">{`Please include: ${errors}`}</div>}
                    {!modalSubmitLoading &&
                        <div>
                            <button className="btn inline-btn" type="button" onClick={() => cancelFunction()}>Cancel</button>
                            <button className="btn" type="submit">Submit</button>
                        </div>
                    }
                    {modalSubmitLoading && <button disabled className="btn" type="submit">Submitting...</button>}
                </form>
            </div>
        </div>
    );
}

export default AddEditCustomTagModal;