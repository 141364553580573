export const swap = (dictionary) => {
    var ret = {}
    for (var key in dictionary) {
        ret[dictionary[key]] = key;
    }
    return ret;
}

export const formatMoney = (value) => {
    const floatValue = parseFloat(value.toString())
    return `$${floatValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
}

export const formatStringPercentageToFloat = (value) => {
    return parseFloat(value.replace('%', '')) / 100;
}

export const formatFloatPercentageToString = (value) => {
    return `${(value * 100).toFixed(2)}%`;
}

export const formatStringMoneyToFloat = (value) => {
    return parseFloat(value.replace('$', '').replace(',', ''))
}

export const addDropDownDisplayNameToParts = (parts) => {
    var partsFormatted = parts.map(currPart => {
        currPart.dropdownDisplayValue = `${currPart.partName} - ${currPart.vendor.name} - ${formatMoney(currPart.price)}`
    })

    return partsFormatted;
}

export const convertCustomTagListToDictList = (customTags) => {
    var customTagDictList = customTags.map(currTag => {
        return {
            "id": currTag,
            "value": currTag
        }
    })
    return customTagDictList;
}

export const calculateSum = (data, fieldToSum) => {
    let total = 0;
    data.forEach(element => {
        total += element[fieldToSum]
    });

    return total;
}

export const generateDefaultManHours = (idToValuesMapping, defaultRate, defaultHours) => {
    var defaultManHours = []
    for (const [key, value] of Object.entries(idToValuesMapping)) {
        defaultManHours.push({
            "id": parseInt(key),
            "type": parseInt(key),
            "typeString": value,
            "hours": defaultHours,
            "rate": defaultRate,
            "total": defaultHours * defaultRate
        })
    }

    return defaultManHours;
}