import axios from "axios";

import { ConfigValues } from "../utils/configValues";
import { getLocalStorage, setLocalStorage } from "../utils/sessionsUtils";

const apiClient = axios.create({
    baseURL: ConfigValues.baseUrl,
    // headers: {
    //     "Content-Type": "application/json"
    // }
})

const apiClientFormData = axios.create({

})

apiClient.interceptors.request.use(
    (config) => {
        const idToken = getLocalStorage("idToken");
        if (idToken) {
            config.headers["Authorization"] = `Bearer ${idToken}`;
        }
        return config
    },
    (error) => {
        return Promise.reject(error);
    }
)

apiClient.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (!originalConfig.url.includes("/user/login") && err.response) {
            if (err.response.status === 401 && !originalConfig._retry){
                originalConfig._retry = true;

                try {
                    const idToken = await refreshToken();
                    
                    setLocalStorage('idToken', idToken.data);
                    apiClient.defaults.headers.common["Authorization"] = `Bearer ${idToken.data}`;

                    return apiClient(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
);

function refreshToken() {
    return apiClient.post("/user/refresh", {
        refreshToken: getLocalStorage("refreshToken")
    });
}


export const testAuth = async (client) => {
    return client.get("/user/test-auth")
    .then(res => { 
        return true;
    })
    .catch(err => { 
        return false;
    });
}

export default apiClient;