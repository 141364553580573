import { useState, useEffect } from "react";
import apiClient from "../../services/apiClient";

const useGet = (endpoint, formatData = (data) => { }) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        apiClient.get(endpoint)
            .then((response) => {
                formatData(response.data);
                setData(response.data);
                setIsPending(false);
                setError(null);
            })
            .catch((err) => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                setError(err.message);
                setIsPending(false);
            });
    }, [endpoint]);

    return { data, setData, isPending, error }
}

export default useGet;