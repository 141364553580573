import { BrowserRouter as Router, Route, Routes, Navigate, useParams } from 'react-router-dom';

import './App.css';

import Navbar from '../Navbar/Navbar';
import Login from '../Login/Login';
import Logout from '../Logout/Logout';
import Vendors from '../Vendors/Vendors';
import Parts from '../Parts/Parts';
import Clients from '../Clients/Clients';
import Assemblies from '../Assemblies/Assemblies';
import AssemblyDetail from '../AssemblyDetail/AssemblyDetail';
import NewAssembly from '../NewAssembly/NewAssembly';
import Quotes from '../Quotes/Quotes';
import QuoteDetail from '../QuoteDetail/QuoteDetail';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Routes>
            <Route path='/' element={<Navigate to="/quotes" />} />
            <Route path='/vendors' Component={Vendors} />
            <Route path='/login' Component={Login} />
            <Route path='/logout' Component={Logout} />
            <Route path='/parts' Component={Parts} />
            <Route path='/clients' Component={Clients} />
            <Route path='/quotes' Component={Quotes} />
            <Route path='/quote/:quoteId' Component={QuoteDetail}></Route>
            <Route path='/assemblies' Component={Assemblies} />
            <Route path='/assembly/:assemblyId' Component={AssemblyDetail} />
            <Route path='/assembly/:assemblyId/' Component={AssemblyDetail} />
            <Route path='/assembly/new' Component={NewAssembly} />
            <Route path='/health' />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
