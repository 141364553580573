import { ConfigValues } from "../../utils/configValues";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { PartTableHeaders } from "../../static-values/TableHeaders";
import UploadDocumentModal from "../Modals/UploadDocumentModal/UploadDocumentModal";
import ConfirmationModal from "../Modals/ConfirmationModal/ConfirmationModal";
import Table from "../Table/Table";
import useGet from "../../utils/hooks/useGet";
import DeleteEntityModal from "../Modals/DeleteEntityModal/DeleteEntityModal";
import AddEditPartModal from "../Modals/AddEditPartModal/AddEditPartModal";
import "./Parts.css";
import apiClient, { testAuth } from "../../services/apiClient";
import { mkConfig, generateCsv, download } from "export-to-csv";

const Parts = () => {
    const navigate = useNavigate();

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [partToEdit, setPartToEdit] = useState(null);
    const [editModalLoading, setEditModalLoading] = useState(false);

    const [partsThatFailedToImport, setPartsThatFailedToImport] = useState(null);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [partToDelete, setPartToDelete] = useState(null);
    const [deleteModalLoading, setDeleteModalLoading] = useState(null);

    const [importPartsModalOpen, setImportPartsModalOpen] = useState(false);
    const [importPartsModalLoading, setImportPartsModalLoading] = useState(false);

    const handlePartEdit = (partId) => {
        const partPos = parts.map(part => part.id).indexOf(partId);
        setPartToEdit(partPos);
        setEditModalOpen(true);
    }

    const { data: parts, setData: setParts, isPending, error } = useGet(`${ConfigValues.baseUrl}/part`);
    const { data: vendors, setData: setVendors, isPending: isPendingVendors, error: errorVendors } = useGet(`/vendor`);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                setEditModalOpen(false);
            }
        };
        window.addEventListener('keydown', handleEsc);

        (async () => {
            const testAuthResult = await testAuth(apiClient);
            if (!testAuthResult) {
                navigate('/login');
            }
        })();

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [])

    const handleSendDeleteRequest = (targetPartId) => {
        setDeleteModalLoading(true);

        apiClient.delete(`/part/${targetPartId}`)
            .then(res => {
                setPartToDelete(null);
                setParts(parts.filter((currPart, idx) => currPart.id !== targetPartId));
                setDeleteModalLoading(false);
                setDeleteModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                else if (err.response.status === 400) {
                    alert(err.response.data);
                }
                else {
                    alert(`Failed to delete part. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                }
                setDeleteModalLoading(false);
            });
    }

    const handleDeleteRow = (targetPartId) => {
        const part = parts.filter((currPart, idx) => currPart.id === targetPartId)[0];
        setPartToDelete(part);
        setDeleteModalOpen(true);
    }

    const handlePartModalSubmit = (newPart) => {
        setEditModalLoading(true);

        // If a new part
        if (partToEdit === null) {
            const newPartBody = {
                "partName": newPart.partName,
                "partDescription": newPart.partDescription,
                "material": newPart.material,
                "materialType": newPart.materialType,
                "makeBuy": parseInt(newPart.makeBuy),
                "price": parseFloat(newPart.price),
                "vendorId": newPart.vendorId,
                "length": newPart.length,
                "machine": newPart.machine
            }

            apiClient.post('/part', newPartBody)
                .then(partResponse => {
                    setParts([...parts, partResponse.data]);
                    setEditModalLoading(false);
                    setEditModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    else if (err.response.status === 400) {
                        alert(err.response.data);
                    }
                    else {
                        alert(`Failed to add new part. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    }
                    setEditModalLoading(false);
                });
        }
        // If editing an existing part
        else {
            const updatedPartBody = {
                "partName": newPart.partName,
                "partDescription": newPart.partDescription,
                "material": newPart.material,
                "materialType": newPart.materialType,
                "makeBuy": parseInt(newPart.makeBuy),
                "price": parseFloat(newPart.price),
                "vendorId": newPart.vendorId,
                "length": newPart.length,
                "machine": newPart.machine
            }
            apiClient.patch(`/part/${newPart.id}`, updatedPartBody)
                .then(updatedPartResponse => {
                    setEditModalLoading(false);
                    setParts(parts.map((currPart, idx) => {
                        if (idx !== partToEdit) return currPart;
                        return updatedPartResponse.data;
                    }));
                    setEditModalOpen(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    else if (err.response.status === 400) {
                        alert(err.response.data);
                    }
                    else {
                        alert(`Failed to update existing part. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    }
                    setEditModalLoading(false);
                });

        }
    }

    const handlePartsImport = (file) => {
        setImportPartsModalLoading(true);

        const formData = new FormData();
        formData.append("file", file, file.name);

        apiClient.post('/part/import', formData)
            .then(response => {
                var newPartEntries = parts.concat(response.data.successfullyImportedParts);
                setParts(newPartEntries);
                setImportPartsModalLoading(false);
                setImportPartsModalOpen(false);
                console.log(response.data)
                if (response.data.failedToImportParts.length > 0) {
                    setPartsThatFailedToImport(response.data.failedToImportParts);
                }
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                else if (err.response.status === 400) {
                    alert(err.response.data);
                }
                else {
                    alert(`Failed to import parts. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                }
                setImportPartsModalLoading(false);
            });
    }

    const orderKeysInPart = (data) => {
        var fieldOrder = {
            "partName": "Part Number",
            "partDescription": "Part Description",
            "material": "Material",
            "makeBuy": "Make/Buy",
            "vendorId": "Vendor Id",
            "price": "Price",
            "length": "Material Amount",
            "machine": "Machine"
        }
        var newObject = {};
        for (const [key, value] of Object.entries(fieldOrder)) {
            if (data.hasOwnProperty(key)) {
                newObject[value] = data[key];
            }
        }
        return newObject;
    }

    const handleDownloadFailedToImportParts = () => {
        var removeKeysFromParts = ["id", "dateCreated", "dateCreatedCST", "dateModified", "dateModifiedCST", "isDeleted", "quantity", "total", "vendor"]
        var partsToExport = partsThatFailedToImport.map((currPart, index) => {
            removeKeysFromParts.forEach(element => {
                delete currPart[element];
            });
            switch (currPart.makeBuy) {
                case 0:
                    currPart.makeBuy = "Make";
                    break;
                case 1:
                    currPart.makeBuy = "Buy";
                    break;
                default:
                    currPart.makeBuy = "Unknown";
                    break;
            }
            return orderKeysInPart(currPart);
        });
        const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: "FailedPartImports" });
        const csv = generateCsv(csvConfig)(partsToExport);
        download(csvConfig)(csv);
    }

    return (
        <div className="parts">
            <div className="table-title">
                <h2>Parts</h2>
            </div>
            {isPending && <p>Loading data</p>}
            {!isPending && error !== null &&
                <div className="error">
                    {error}
                </div>
            }
            {!isPending && (error === null) &&
                <Table
                    data={parts}
                    columns={PartTableHeaders}
                    deleteRowFunction={handleDeleteRow}
                    editRowFunction={handlePartEdit}
                    initialState={{ sortBy: [{ id: "partName", desc: false }] }}
                />
            }
            {!isPending && (error === null) && editModalOpen &&
                <AddEditPartModal
                    closeModal={() => {
                        setPartToEdit(null);
                    }}
                    onModalSubmit={handlePartModalSubmit}
                    defaultValue={partToEdit !== null && parts[partToEdit]}
                    modalSubmitLoading={editModalLoading}
                    partList={parts}
                    setPartList={setParts}
                    cancelFunction={() => {
                        setEditModalOpen(false);
                        setPartToEdit(null);
                    }}
                    vendorsList={vendors}
                    isPendingVendors={isPendingVendors}
                    errorVendors={errorVendors}
                />
            }
            {!isPending && (error === null) && deleteModalOpen &&
                <DeleteEntityModal
                    closeModal={() => {
                        setPartToEdit(null);
                    }}
                    onModalSubmit={handleSendDeleteRequest}
                    entityType="Part"
                    cancelFunction={() => {
                        setDeleteModalOpen(false);
                        setPartToEdit(null);
                    }}
                    currentEntity={partToDelete !== null && partToDelete}
                    currentEntityString={partToDelete !== null && partToDelete.partName}
                    modalSubmitLoading={deleteModalLoading}
                />
            }
            {importPartsModalOpen &&
                <UploadDocumentModal
                    closeModal={() => {
                        setImportPartsModalOpen(false);
                    }}
                    onModalSubmit={handlePartsImport}
                    fileTypes={["xlsx"]}
                    uploadLoading={importPartsModalLoading}
                    allowPartTemplateDownload={true}
                />
            }
            {partsThatFailedToImport !== null &&
                <ConfirmationModal
                    onModalSubmit={handleDownloadFailedToImportParts}
                    closeModal={() => {
                        setPartsThatFailedToImport(null);
                    }}
                    displayText="There were parts that failed to import. Would you like to download the parts that failed to import?"
                />
            }
            {!isPending && (error === null) &&
                <div>
                    <button className="btn add-new-btn" onClick={() => setEditModalOpen(true)}>Add New Part</button>
                    <button className="btn add-new-btn" style={{ marginRight: "5px" }} onClick={() => setImportPartsModalOpen(true)}>Import Parts</button>
                </div>
            }
        </div>
    );
}

export default Parts;