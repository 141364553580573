import { AbbreviatedStates } from "../../static-values/States";

const ColumnDropDownFilterStates = ({ column }) => {
    const { filterValue, setFilter } = column;
    return (<span>
        Search: {''}
        <select value={filterValue || 1} onChange={(e) => setFilter(e.target.value)}>
            {AbbreviatedStates.map((state) => <option key={state} value={state}>{state}</option>)}
        </select>
    </span>);
}

export default ColumnDropDownFilterStates;