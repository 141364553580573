import useGet from "../../utils/hooks/useGet";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

import apiClient from "../../services/apiClient";
import { testAuth } from "../../services/apiClient";
import Table from "../Table/Table";
import { QuoteTableHeaders } from "../../static-values/TableHeaders";
import AddNewQuoteModal from "../Modals/AddNewQuoteModal/AddNewQuoteModal";
import DeleteEntityModal from "../Modals/DeleteEntityModal/DeleteEntityModal";
import { getLocalStorage } from "../../utils/sessionsUtils";

const Quotes = () => {
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const testAuthResult = await testAuth(apiClient);
            if (!testAuthResult) {
                navigate('/login');
            }
        })();
    }, []);

    const { data: quotes, setData: setQuotes, isPending, error } = useGet("/quote");
    const { data: clients, setData: setClients, isPending: isPendingClient, error: errorClient } = useGet("/client")
    const { data: assemblies, setData: setAssemblies, isPending: isPendingAssembly, error: errorAssembly } = useGet("/assembly");

    const [newQuoteModalOpen, setNewQuoteModalOpen] = useState(false);
    const [newQuoteModalLoading, setNewQuoteModalLoading] = useState(false);

    const [quoteToDelete, setQuoteToDelete] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteModalLoading, setDeleteModalLoading] = useState(false);

    const [quoteToCopy, setQuoteToCopy] = useState(null);

    const handleQuoteDeleteRow = (targetQuoteId) => {
        const quote = quotes.filter((currQuote, idx) => currQuote.id === targetQuoteId)[0];
        setQuoteToDelete(quote);
        setDeleteModalOpen(true);
    }

    const handleSendDeleteRequest = (targetQuoteId) => {
        setDeleteModalLoading(true);

        apiClient.delete(`/quote/${targetQuoteId}`)
            .then(response => {
                setQuoteToDelete(null);
                setQuotes(quotes.filter((currQuote, idx) => currQuote.id !== targetQuoteId))
                setDeleteModalLoading(false);
                setDeleteModalOpen(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to delete quote. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setDeleteModalLoading(false);
            });
    }

    const getQuoteFromId = (id) => {
        const quotePos = quotes.map(currQuote => currQuote.id).indexOf(id);
        return quotes[quotePos];
    }

    const handleNewQuoteSubmit = (newQuote, addInitialAssembly) => {
        setNewQuoteModalLoading(true);

        if (quoteToCopy !== null) {
            const copyQuoteBody = {
                "clientId": newQuote.clientId,
                "projectName": newQuote.projectName,
                "customerPONumber": newQuote.customerPONumber,
                "userId": getLocalStorage("userId"),
                "salesPersonUserId": getLocalStorage("userId"),
                "attention" : newQuote.attention
            }

            apiClient.post(`/quote/copy/${quoteToCopy.id}`, copyQuoteBody)
                .then(response => {
                    setNewQuoteModalLoading(false);
                    setNewQuoteModalOpen(false);
                    navigate(`/quote/${response.data.id}`);
                    window.location.reload(false);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to copy quote. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setNewQuoteModalLoading(false);
                });
        }

        else {
            const newQuoteBody = {
                "clientId": parseInt(newQuote.clientId),
                "projectName": newQuote.projectName,
                "customerPONumber": newQuote.customerPONumber,
                "userId": getLocalStorage("userId"),
                "salesPersonUserId": getLocalStorage("userId"),
                "attention" : newQuote.attention
            };

            if (addInitialAssembly) {
                newQuoteBody["initialAssemblyId"] = newQuote.initialAssemblyId;
                newQuoteBody["initialAssemblyQuantity"] = parseInt(newQuote.initialAssemblyQuantity);
            }

            apiClient.post("/quote", newQuoteBody)
                .then(response => {
                    setNewQuoteModalLoading(false);
                    setNewQuoteModalOpen(false);
                    navigate(`/quote/${response.data.id}`);
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log("Fetch aborted");
                    }
                    alert(`Failed to create new quote. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                    setNewQuoteModalLoading(false);
                });
        }

    }

    return (
        <div className="quotes">
            <div className="table-title">
                <h2>Quotes</h2>
            </div>
            {isPending && <p>Loading data</p>}
            {!isPending && error !== null &&
                <div className="error">
                    {error}
                </div>
            }
            {!isPending && (error === null) &&
                <Table data={quotes}
                    columns={QuoteTableHeaders}
                    deleteRowFunction={handleQuoteDeleteRow}
                    editRowFunction={() => { }}
                    initialState={{ sortBy: [{ id: "quoteNumber", desc: false }] }}
                    actions={['ModalDelete', 'LinkToDetailPage', 'CopyQuote']}
                    detailPagePrefix="/quote"
                    copyQuoteFunction={(quoteId) => {
                        var quoteToCopy = getQuoteFromId(quoteId);
                        setQuoteToCopy({
                            ...quoteToCopy,
                            ["clientId"]: quoteToCopy["client_Id"]
                        });
                        setNewQuoteModalOpen(true);
                    }}
                />
            }
            {!isPending && (error === null) &&
                <button
                    className="btn add-new-btn"
                    onClick={() => { setNewQuoteModalOpen(true) }}
                >Add New Quote</button>}
            {!isPendingClient && (errorClient === null) && newQuoteModalOpen &&
                <AddNewQuoteModal
                    closeModal={() => { }}
                    onModalSubmit={handleNewQuoteSubmit}
                    defaultValue={quoteToCopy ?? null}
                    modalSubmitLoading={newQuoteModalLoading}
                    clientList={clients}
                    isPendingClients={isPendingClient}
                    errorClient={errorClient}
                    assemblies={assemblies}
                    isPendingAssemblies={isPendingAssembly}
                    errorAssemblies={errorAssembly}
                    cancelFunction={() => {
                        setNewQuoteModalOpen(false);
                        setNewQuoteModalLoading(false);
                        setQuoteToCopy(null);
                    }}
                    disableAddingInitialAssembly={quoteToCopy === null ? false : true}
                />
            }
            {!isPending && (error === null) && deleteModalOpen &&
                <DeleteEntityModal
                    closeModal={() => {
                        setQuoteToDelete(null);
                    }}
                    onModalSubmit={handleSendDeleteRequest}
                    entityType="Quote"
                    currentEntity={quoteToDelete !== null && quoteToDelete}
                    currentEntityString={quoteToDelete !== null && `${quoteToDelete.quoteNumber} for ${quoteToDelete.client.name}`}
                    modalSubmitLoading={deleteModalLoading}
                    cancelFunction={() => {
                        setDeleteModalOpen(false);
                    }}
                />
            }
        </div>
    );
}

export default Quotes;