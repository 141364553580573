import { useState, useEffect } from "react";
import "./NewAssembly.css"
import NewAssemblyAssemblyInformationModal from "../Modals/NewAssemblyModals/NewAssemblyAssemblyInformationModal/NewAssemblyAssemblyInformationModal";
import NewAssemblyManHoursModal from "../Modals/NewAssemblyModals/NewAssemblyManHoursModal/NewAssemblyManHoursModal";

import { EngineeringIdToValueMappings, EngineeringValueToIdMappings, LaborIdToValueMappings, LaborValueToIdMappings, MachineShopIdToValueMappings, MachineShopValueToIdMappings } from "../../static-values/Mappings";
import { formatStringPercentageToFloat, generateDefaultManHours } from "../../utils/utils";
import NewAssemblyPartsModal from "../Modals/NewAssemblyModals/NewAssemblyPartsModal/NewAssemblyPartsModal";
import NewAssemblyCustomTagsModal from "../Modals/NewAssemblyModals/NewAssemblyCustomTagsModal/NewAssemblyCustomTagsModal";
import NewAssemblySubAssembliesModal from "../Modals/NewAssemblyModals/NewAssemblySubAssembliesModal/NewAssemblySubAssembliesModal";
import NewAssemblySummaryAndFinalize from "../Modals/NewAssemblyModals/NewAssemblySummaryAndFinalize/NewAssemblySummaryAndFinalize";
import useGet from "../../utils/hooks/useGet";
import apiClient from "../../services/apiClient";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../Modals/ConfirmationModal/ConfirmationModal";
const NewAssembly = () => {
    const navigate = useNavigate();
    const { data: assemblies, setData: setAssemblies, isPendingSubAssemblies, errorSubAssemblies } = useGet(`/assembly`);
    const [cancelConfirmationModalOpen, setCancelConfirmationModalOpen] = useState(false);

    const [finalizeLoading, setFinalizeLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [assemblyInformation, setAssemblyInformation] = useState({
        "assemblyNumber": "",
        "assemblyDescription": "",
        "contingencyPercentageString": "0%",
        "profitMarginPercentageString": "0%",
    });

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [])

    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }

    const handleCancel = () => {
        navigate("/assemblies");
    }

    const [customTags, setCustomTags] = useState([]);
    const [labor, setLabor] = useState(generateDefaultManHours(LaborIdToValueMappings, 55, 1));
    const [engineering, setEngineering] = useState(generateDefaultManHours(EngineeringIdToValueMappings, 65, 1));
    const [machining, setMachining] = useState(generateDefaultManHours(MachineShopIdToValueMappings, 87, 1));

    const [parts, setParts] = useState([]);
    const [subAssemblies, setSubAssemblies] = useState([]);

    const handleFinalize = () => {
        setFinalizeLoading(true);

        const customTagsDict = {}
        customTags.forEach(tag => {
            customTagsDict[tag.key] = tag.value
        })

        const newAssemblyParts = parts.map((currPart, idx) => {
            return {
                "id": currPart.id,
                "quantity": currPart.quantity
            }
        })

        const newAssemblySubAssemblies = subAssemblies.map((currSubAssembly, idx) => {
            return {
                "id": currSubAssembly.id,
                "quantity": currSubAssembly.quantity
            }
        })

        const newAssemblyLabor = labor.map((currLabor, idx) => {
            return {
                "type": currLabor.type,
                "rate": currLabor.rate,
                "hours": currLabor.hours
            }
        })

        const newAssemblyEngineering = engineering.map((currEngineering, idx) => {
            return {
                "type": currEngineering.type,
                "rate": currEngineering.rate,
                "hours": currEngineering.hours
            }
        })

        const newAssemblyMachining = machining.map((currMachining, idx) => {
            return {
                "type": currMachining.type,
                "rate": currMachining.rate,
                "hours": currMachining.hours
            }
        })

        const newAssembly = {
            "assemblyNumber": assemblyInformation.assemblyNumber,
            "assemblyDescription": assemblyInformation.assemblyDescription,
            "contingencyPercentage": formatStringPercentageToFloat(assemblyInformation.contingencyPercentageString),
            "profitMarginPercentage": formatStringPercentageToFloat(assemblyInformation.profitMarginPercentageString),
            "parts": newAssemblyParts,
            "subAssemblies": newAssemblySubAssemblies,
            "labor": newAssemblyLabor,
            "engineering": newAssemblyEngineering,
            "machining": newAssemblyMachining,
            "customTags": customTagsDict
        }

        apiClient.post('/assembly', newAssembly)
            .then(response => {
                navigate(`/assembly/${response.data.id}`)
                setFinalizeLoading(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log("Fetch aborted");
                }
                alert(`Failed to create new assembly. Please provide error details:\nError Name: ${err.name}\nError Message: ${err.message}`)
                setFinalizeLoading(false);
            });
    }

    return (
        <>
            {step === 1 &&
                <NewAssemblyAssemblyInformationModal
                    closeModal={() => {
                        setStep(step + 1);
                    }}
                    onModalSubmit={(assemblyInformationFromModal) => {
                        setAssemblyInformation({
                            ...assemblyInformation,
                            ["assemblyNumber"]: assemblyInformationFromModal.assemblyNumber,
                            ["assemblyDescription"]: assemblyInformationFromModal.assemblyDescription,
                            ["contingencyPercentageString"]: assemblyInformationFromModal.contingencyPercentageString,
                            ["profitMarginPercentageString"]: assemblyInformationFromModal.profitMarginPercentageString
                        });
                    }}
                    defaultValue={assemblyInformation}
                    cancelFunction={() => {
                        setCancelConfirmationModalOpen(true);
                    }}
                />
            }
            {step === 2 &&
                <NewAssemblyCustomTagsModal
                    closeModal={() => {
                        setStep(step + 1);
                    }}
                    onModalSubmit={(customTagInformation) => {
                        setCustomTags(customTagInformation);
                    }}
                    defaultValue={customTags}
                    backFunction={(customTagInformation) => {
                        setCustomTags(customTagInformation);
                        setStep(step - 1);
                    }}
                    cancelFunction={() => {
                        setCancelConfirmationModalOpen(true);
                    }}
                />
            }
            {step === 3 &&
                <NewAssemblyManHoursModal
                    closeModal={() => {
                        setStep(step + 1);
                    }}
                    onModalSubmit={(laborInformation) => {
                        setLabor(laborInformation);
                    }}
                    defaultValue={labor}
                    backFunction={(laborInformation) => {
                        setLabor(laborInformation);
                        setStep(step - 1);
                    }}
                    manHourType="Labor"
                    subTypeOptions={Object.keys(LaborValueToIdMappings)}
                    valuesToIdMapping={LaborValueToIdMappings}
                    cancelFunction={() => {
                        setCancelConfirmationModalOpen(true);
                    }}
                />
            }
            {step === 4 &&
                <NewAssemblyManHoursModal
                    closeModal={() => {
                        setStep(step + 1);
                    }}
                    onModalSubmit={(engineeringInformation) => {
                        setEngineering(engineeringInformation);
                    }}
                    defaultValue={engineering}
                    backFunction={(engineeringInformation) => {
                        setEngineering(engineeringInformation);
                        setStep(step - 1);
                    }}
                    manHourType="Engineering"
                    subTypeOptions={Object.keys(EngineeringValueToIdMappings)}
                    valuesToIdMapping={EngineeringValueToIdMappings}
                    cancelFunction={() => {
                        setCancelConfirmationModalOpen(true);
                    }}
                />
            }
            {step === 5 &&
                <NewAssemblyManHoursModal
                    closeModal={() => {
                        setStep(step + 1);
                    }}
                    onModalSubmit={(machiningInformation) => {
                        setMachining(machiningInformation);
                    }}
                    defaultValue={machining}
                    backFunction={(machiningInformation) => {
                        setMachining(machiningInformation);
                        setStep(step - 1);
                    }}
                    manHourType="Machine Shop"
                    subTypeOptions={Object.keys(MachineShopValueToIdMappings)}
                    valuesToIdMapping={MachineShopValueToIdMappings}
                    cancelFunction={() => {
                        setCancelConfirmationModalOpen(true);
                    }}
                />
            }
            {step === 6 &&
                <NewAssemblyPartsModal
                    closeModal={() => {
                        setStep(step + 1);
                    }}
                    onModalSubmit={(partInformation) => {
                        setParts(partInformation);
                    }}
                    defaultValue={parts}
                    backFunction={(partInformation) => {
                        setParts(partInformation);
                        setStep(step - 1);
                    }}
                    cancelFunction={() => {
                        setCancelConfirmationModalOpen(true);
                    }}
                />
            }
            {step === 7 &&
                <NewAssemblySubAssembliesModal
                    closeModal={() => {
                        setStep(step + 1);
                    }}
                    onModalSubmit={(subAssemblyInformation) => {
                        setSubAssemblies(subAssemblyInformation);
                    }}
                    defaultValue={subAssemblies}
                    backFunction={(subAssemblyInformation) => {
                        setSubAssemblies(subAssemblyInformation);
                        setStep(step - 1);
                    }}
                    subAssemblies={assemblies}
                    isPendingSubAssemblies={isPendingSubAssemblies}
                    errorSubAssemblies={errorSubAssemblies}
                    cancelFunction={() => {
                        setCancelConfirmationModalOpen(true);
                    }}
                />
            }
            {step === 8 &&
                <NewAssemblySummaryAndFinalize
                    closeModal={() => {
                    }}
                    onModalSubmit={() => {
                        handleFinalize();
                    }}
                    defaultValue={
                        {
                            ...assemblyInformation,
                            ["customTags"]: customTags,
                            ["labor"]: labor,
                            ["engineering"]: engineering,
                            ["machining"]: machining,
                            ["parts"]: parts,
                            ["subAssemblies"]: subAssemblies
                        }
                    }
                    modalSubmitLoading={finalizeLoading}
                    backFunction={() => {
                        setStep(step - 1);
                    }}
                    cancelFunction={() => {
                        setCancelConfirmationModalOpen(true);
                    }}
                />
            }
            {cancelConfirmationModalOpen &&
                <ConfirmationModal
                    closeModal={() => {
                        setCancelConfirmationModalOpen(false);
                    }}
                    onModalSubmit={handleCancel}
                    displayText="Are you sure you want to cancel? You will lose all progress!"
                />
            }
        </>
    );
}

export default NewAssembly;